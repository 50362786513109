// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { useIntl } from "react-intl";

const useIssueStatusTranslation = (): { [key in IssueStatuses]: string } => {
  const intl = useIntl();

  return {
    [IssueStatuses.InProgress]: intl.formatMessage({
      id: "issue.status.InProgress",
      defaultMessage: "In Progress"
    }),
    [IssueStatuses.Monitor]: intl.formatMessage({
      id: "issue.status.Monitor",
      defaultMessage: "Monitor"
    }),
    [IssueStatuses.Closed]: intl.formatMessage({
      id: "issue.status.Closed",
      defaultMessage: "Closed"
    }),
    [IssueStatuses.New]: intl.formatMessage({
      id: "issue.status.New",
      defaultMessage: "New"
    })
  };
};

export default useIssueStatusTranslation;
