// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";
import ScrollArea from "react-scrollbar";
import { Button } from "antd";

import FormContainer from "common/form/containers/FormContainer";
import FormModel from "common/form/models/Form";
import NoDataMessage from "common/messages/NoDataMessage";
import IWorkOrder from "features/detailpage/models/IWorkOrder";
import IWorkOrderIdentifier from "features/detailpage/models/IWorkOrderIdentifier";
import IWorkRequest from "features/detailpage/models/IWorkRequest";
import IWorkRequestForm from "features/detailpage/models/IWorkRequestForm";
import ActionsModalWorkOrder, {
  getWorkOrderInputName
} from "./ActionsModalWorkOrder";
import ActionsModalWorkRequest from "./ActionsModalWorkRequest";

import "./ActionsModal.less";

export const actionsModalFormName = "DetailPage_Issues_ActionsModal";

export interface IActionsModalActions {}

export interface IActionsModalData {
  availableWorkOrders: IWorkOrder[];
}

export interface IActionsOwnProps {
  title: string;
  condition: string;
  onCancel?: () => void;
  onSubmit?: (
    workRequest: IWorkRequest | IWorkRequestForm,
    workOrders: IWorkOrder[]
  ) => void;
  recommendation: string;
  workRequest: IWorkRequest | IWorkRequestForm;
  workOrders: IWorkOrder[];
}

export interface IActionsModalProps
  extends IActionsModalActions,
    IActionsModalData,
    IActionsOwnProps {}

export interface IActionModalState {
  workRequestForm: IWorkRequestForm;
}

class ActionsModal extends React.Component<
  IActionsModalProps,
  IActionModalState
> {
  constructor(props: IActionsModalProps) {
    super(props);

    this.state = {
      workRequestForm: null
    };
  }

  render() {
    const {
      condition,
      onCancel,
      onSubmit,
      recommendation,
      workRequest,
      workOrders,
      title,
      availableWorkOrders
    } = this.props;

    const { workRequestForm } = this.state;

    const handleNewRequestClick = (e: React.MouseEvent<HTMLButtonElement> &
        React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const workRequestForm: IWorkRequestForm = {
        Title: title,
        Condition: condition,
        Recommendation: recommendation
      };

      this.setState({
        workRequestForm
      });
    };

    const handleSubmit = (form: FormModel) => {
      if (onSubmit) {
        const selectedWorkRequest =
          form.inputs.has("selected-wr") && form.inputs.get("selected-wr").value
            ? workRequest || workRequestForm
            : undefined;

        const selectedWorkOrders =
          availableWorkOrders &&
          availableWorkOrders.filter((o) => {
            const inputName = getWorkOrderInputName(o);
            return form.inputs.get(inputName).value;
          });

        onSubmit(selectedWorkRequest, selectedWorkOrders);
      }
    };

    const handleCancel = () => {
      if (onCancel) onCancel();
    };

    const isSelected = (workOrderId: IWorkOrderIdentifier): boolean => {
      const index = workOrders
        ? workOrders.findIndex(
            (o) =>
              o.WorkOrderIdentifier.WorkOrderDistrictCode ===
                workOrderId.WorkOrderDistrictCode &&
              o.WorkOrderIdentifier.WorkOrderNumber ===
                workOrderId.WorkOrderNumber
          )
        : -1;

      return index >= 0;
    };

    return (
      <div className="detail-page-issues-actions-modal">
        <FormContainer
          name={actionsModalFormName}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          submitButtonDataQa="submit-actions-button"
        >
          <div className="actions">
            <Button
              type="primary"
              data-qa="new-request-button"
              disabled={!!workRequest || !!workRequestForm}
              onClick={handleNewRequestClick}
            >
              <FormattedMessage
                id="detail_page.issues.actions.modal.new_request"
                defaultMessage="New request"
              />
            </Button>
          </div>
          <ScrollArea horizontal={false} smoothScrolling={true}>
            {(workRequest || workRequestForm || availableWorkOrders) && (
              <div>
                {workRequest && (
                  <ActionsModalWorkRequest
                    defaultSelected={true}
                    workRequest={workRequest}
                  />
                )}
                {!workRequest && workRequestForm && (
                  <ActionsModalWorkRequest
                    defaultSelected={true}
                    workRequest={workRequestForm}
                  />
                )}
                <div className="work-orders">
                  {availableWorkOrders.map((o) => (
                    <ActionsModalWorkOrder
                      defaultSelected={isSelected(o.WorkOrderIdentifier)}
                      key={`${o.WorkOrderIdentifier.WorkOrderDistrictCode}-${o.WorkOrderIdentifier.WorkOrderNumber}`}
                      workOrder={o}
                    />
                  ))}
                </div>
              </div>
            )}
            {!(workRequest || workRequestForm || availableWorkOrders) && (
              <div className="no-actions-available">
                <NoDataMessage
                  message={{
                    defaultMessage: "No Actions available",
                    id: "detail_page.issues.actions.modal.no_actions_assigned"
                  }}
                  withBorder
                />
              </div>
            )}
          </ScrollArea>
        </FormContainer>
      </div>
    );
  }
}

export default ActionsModal;
