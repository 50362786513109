// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  ISubscore as ConditionTrendSubscore,
  IConditionTrendSeries,
  IConditionTrendTranslations
} from "@apm/widgets/build/widgets/ConditionTrend";
import {
  ISubscore as ISubscoreList,
  ITranslations
} from "@apm/widgets/build/widgets/SubscoresListModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { formatTwoDigitDateTime } from "common/DateTime/utils/dateFormatters";
import { NumericalValueService } from "common/formatters/NumericalValue";
import { SubscoreService } from "common/formatters/SubscoreName";
import IDegradationScore from "../../../models/IDegradationScore";
import IScoreTrend from "../../../models/IScoreTrend";
import ISubscoreData from "../../../ranges/models/ISubscoreData";
import subscoresRanges from "../../../ranges/subscore_ranges.json";
import ConditionTrendSeriesCreator from "../services/ConditionTrendSeriesCreator";

interface IConditionTrendWidgetState {
  isSeriesReady: boolean;
  series?: IConditionTrendSeries[];
  subscores?: ConditionTrendSubscore[];
}

interface ISubscoreListWidgetState {
  visible?: boolean;
  title?: string;
  subscores?: ISubscoreList[];
}

interface IUseConditionTrendWidgetOptions {
  series?: IScoreTrend[];
  latestDegradationScores: IDegradationScore;
  modelId: string;
  implementationId: string;
  isFailed: boolean;
}

const useConditionTrendWidget = ({
  series,
  latestDegradationScores,
  modelId,
  implementationId,
  isFailed
}: IUseConditionTrendWidgetOptions) => {
  const intl = useIntl();

  const [conditionTrendState, setConditionTrendState] =
    useState<IConditionTrendWidgetState>({
      isSeriesReady: false
    });

  const [modalState, setModalState] = useState<ISubscoreListWidgetState>({
    visible: false
  });

  const handleListItemClick = useCallback(
    (id: string) => {
      const subscore = latestDegradationScores.SubScores.find(
        (x) => x.NameId === id
      );

      const subscoreRanges = (subscoresRanges as ISubscoreData)?.[
        implementationId
      ]?.[id];

      const isSubscoreRangesVisible = subscoreRanges && !isFailed;

      const subscores = subscore.SubScores?.map(
        ({ NameId, Value }): ISubscoreList => {
          const rangeValues = subscoreRanges?.[NameId];
          const min = Number(rangeValues?.Min) || 0;
          const max = Number(rangeValues?.Max) || 0;

          return {
            name: SubscoreService.format(
              NameId,
              modelId,
              implementationId,
              intl
            ),
            value: Value,
            range: isSubscoreRangesVisible ? { min, max } : null
          };
        }
      ).sort((s1, s2) => {
        if (s1.value === s2.value) {
          return s1.name.localeCompare(s2.name);
        }

        return s1.value < s2.value ? 1 : -1;
      });

      setModalState({
        subscores,
        title: SubscoreService.format(
          subscore?.NameId,
          modelId,
          implementationId,
          intl
        ),
        visible: true
      });
    },
    [isFailed, implementationId, intl, latestDegradationScores, modelId]
  );

  const handleCancel = useCallback(() => {
    setModalState({
      visible: false
    });
  }, []);

  const formatValue = useCallback(
    (value: number) => {
      return NumericalValueService.formatWithoutRound(value, intl);
    },
    [intl]
  );

  const formatXTick = useCallback(
    (date: Date) => intl.formatDate(date),
    [intl]
  );

  const conditionTrendTranslations: IConditionTrendTranslations =
    useMemo(() => {
      return {
        noSubscores: ({ name }: ConditionTrendSubscore) =>
          intl.formatMessage(
            {
              id: "detail_page.widgets.health_score_widget.subscore_tooltip_nosubscores",
              defaultMessage: "There are no Subfactors for {subscore}"
            },
            { subscore: name }
          ),
        noData: intl.formatMessage({
          id: "global.chart.no_data_available",
          defaultMessage: "No data available"
        }),
        expandButtonTooltip: intl.formatMessage({
          id: "global.chart.expand",
          defaultMessage: "Expand"
        })
      };
    }, [intl]);

  const subscoresListModalTranslations: ITranslations = useMemo(() => {
    return {
      subfactors: intl.formatMessage({
        id: "detail_page.widgets.health_score_widget.subscore_modal_footer",
        defaultMessage: "Subfactors"
      })
    };
  }, [intl]);

  const formatTooltipTitle = useCallback(
    (date: Date) => formatTwoDigitDateTime(intl, date),
    [intl]
  );

  const OverlayComponent = useCallback(() => {
    return (
      <FormattedMessage
        id="detail_page.widgets.health_score_widget.empty_degradation_score"
        defaultMessage="The asset condition is necessary to show the condition trend."
      />
    );
  }, []);

  useEffect(() => {
    const createConditionTrendData = async () => {
      const { subscores, trendSeries } = await new ConditionTrendSeriesCreator(
        intl,
        modelId,
        implementationId
      ).create(series, latestDegradationScores, isFailed);
      setConditionTrendState({
        isSeriesReady: true,
        series: trendSeries,
        subscores
      });
    };

    createConditionTrendData();
  }, [
    implementationId,
    intl,
    isFailed,
    latestDegradationScores,
    modelId,
    series
  ]);

  return {
    conditionTrendState,
    modalState,
    handleListItemClick,
    handleCancel,
    formatValue,
    formatXTick,
    conditionTrendTranslations,
    subscoresListModalTranslations,
    formatTooltipTitle,
    OverlayComponent
  };
};

export default useConditionTrendWidget;
