// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import {
  WidgetNoDataMessage,
  WidgetErrorMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import Processing from "components/common/Processing";
import ParameterHistogramSucceeded from "./ParameterHistogramSucceeded";
import IParameterHistogram from "../models/IParameterHistogram";
import "./ParameterHistogram.less";
import styled from "styled-components";

interface IParameterHistogramProps {
  title: string;
  unit: string;
  histogramData: Data<IParameterHistogram[]>;
}

const ParameterHistogram = ({
  title,
  unit,
  histogramData
}: IParameterHistogramProps) => {
  return (
    <div className="parameters-histogram container">
      {histogramData && histogramData.status === Statuses.Succeeded && (
        <ParameterHistogramSucceeded
          parameterHistogramData={histogramData.data}
          title={title}
          unit={unit}
        />
      )}
      {histogramData && histogramData.status === Statuses.Failed && (
        <WidgetErrorMessage />
      )}
      {histogramData && histogramData.status === Statuses.NotFound && (
        <WidgetNoDataMessage />
      )}
      {histogramData && histogramData.status === Statuses.Loading && (
        <ProcessingWrapper>
          <Processing />
        </ProcessingWrapper>
      )}
    </div>
  );
};

const ProcessingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ParameterHistogram;
