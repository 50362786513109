// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { select } from "d3";
import tiers from "../../../utils/tiersConfiguration";

const useAssetRiskDotsResize = () => {
  const resizeCurrentAssetDot = (container: HTMLDivElement) => {
    select(container)
      .select(".bb-circles-current-asset")
      .select(".bb-circle")
      .attr("r", 8.5);
  };

  const resizeClusterDots = (container: HTMLDivElement) => {
    const selection = select(container);

    tiers.forEach(({ id, r }) => {
      selection
        .selectAll(`.bb-circles-cluster-${id}`)
        .select(".bb-circle")
        .attr("r", r);
    });
  };

  const resizePrognosticDots = (container: HTMLDivElement) => {
    select(container)
      .selectAll(".bb-circles-prognostic")
      .select(".bb-circle")
      .attr("r", 8.5);
  };

  const resizeDots = (container: HTMLDivElement) => {
    resizeCurrentAssetDot(container);
    resizeClusterDots(container);
    resizePrognosticDots(container);
  };

  return { resizeDots };
};

export default useAssetRiskDotsResize;
