// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ILegendItem } from "@apm/widgets/build/widgets/RogersRatio2D";
import {
  colorBlue20,
  colorBlue30,
  colorBlue40,
  colorBlue50,
  colorBlue60,
  colorBlue70,
  colorBlue80
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";

const getLegend = (intl: IntlShape): ILegendItem[] => [
  {
    name: "T1",
    displayName: intl.formatMessage({
      defaultMessage: "Overheating T<300C (T1)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.overheatingT1"
    }),
    color: colorBlue30,
    visible: true
  },
  {
    name: "T2",
    displayName: intl.formatMessage({
      defaultMessage: "Overheating 300-700C (T2)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.overheatingT2"
    }),
    color: colorBlue80,
    visible: true
  },
  {
    name: "T3",
    displayName: intl.formatMessage({
      defaultMessage: "Overheating T>700C (T3)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.overheatingT3"
    }),
    color: colorBlue40,
    visible: true
  },
  {
    name: "PD",
    displayName: intl.formatMessage({
      defaultMessage: "Partial Discharge (PD)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.dischargePD"
    }),
    color: colorBlue50,
    visible: true
  },
  {
    name: "DT",
    displayName: intl.formatMessage({
      defaultMessage: "Overheating T>700C (T3) or Overheating + discharge (DT)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.dischargeDT"
    }),
    color: colorBlue60,
    visible: true
  },
  {
    name: "D1",
    displayName: intl.formatMessage({
      defaultMessage: "Discharge, medium energy (D1)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.dischargeD1"
    }),
    color: colorBlue20,
    visible: true
  },
  {
    name: "D2",
    displayName: intl.formatMessage({
      defaultMessage: "Discharge (D1/D2)",
      id: "detail_page.widgets.analytics.transformers.rogers_ratio.dischargeD2"
    }),
    color: colorBlue70,
    visible: true
  }
];

export default getLegend;
