// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import DataGrid, {
  AutoCompleteCellEditor,
  DateCellEditor,
  IDataGridCellProps,
  IDataGridProps,
  IDataGridRowProps,
  IOnCellChangedConfig,
  ISelectOption,
  IValidationResult,
  NumberCellEditor,
  SelectCellEditor,
  TextCellEditor
} from "@apm/widgets/build/widgets/DataGrid";
import {
  CellEditorSelectorResult,
  CellValueChangedEvent,
  ICellEditorParams,
  SuppressKeyboardEventParams
} from "ag-grid-community";
import { boolValues, IField, isSelectOption } from "features/ConfigurationTool";
import { isNil } from "lodash";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import componentsWhichRequiresSupressingEnterKeyWhenEditing from "../constants/componentsWhichRequiresSupressingEnterKeyWhenEditing";
import useParametersDataValidation from "../hooks/useParametersDataValidation";
import IInspectionParameter from "../models/IInspectionParameter";
import ValueType from "../models/ValueType";
import { dayjsDefaultFormat } from "common/DateTime/utils/dateFormatters";

interface IModalContentProps {
  parametersData: IDataGridRowProps<IInspectionParameter>[];
  initialData: IDataGridRowProps<IInspectionParameter>[];
  configuredFields: IField[];
  configuredParameterNames: string[];
  updateParametersData: (
    newData: IDataGridRowProps<IInspectionParameter>[]
  ) => void;
  className?: string;
}

const ParametersGrid: FC<IModalContentProps> = ({
  parametersData,
  initialData,
  configuredFields,
  configuredParameterNames,
  updateParametersData,
  className
}) => {
  const intl = useIntl();

  const {
    defaultMessages,
    validateAssetId,
    validateParameterName,
    validateDate,
    validateValueType,
    validateValue
  } = useParametersDataValidation(configuredParameterNames);

  const mapListValuesToSelectOptions = useCallback(
    (listValues: string[] | boolean[] | number[] | ISelectOption[]) => {
      if (isSelectOption(listValues)) return listValues;
      return listValues.map((item) => {
        return { label: item.toString(), value: item.toString() };
      });
    },
    []
  );

  const dataTypeSelectOptions: ISelectOption[] = useMemo(
    () => [
      { label: "Decimal", value: "Decimal" },
      { label: "String", value: "String" },
      { label: "DateTime", value: "DateTime" },
      { label: "Bool", value: "Bool" }
    ],
    []
  );

  const customAssetIdValidation = useCallback(
    (e: CellValueChangedEvent<IInspectionParameter>): IValidationResult => {
      return validateAssetId(e.value?.cellValue);
    },
    [validateAssetId]
  );

  const getFieldConfiguration = useCallback(
    (parameterName: string) => {
      return configuredFields.find((field) => field.fieldKey === parameterName);
    },
    [configuredFields]
  );

  const getCellValue = useCallback((value: ValueType) => {
    return (value as unknown as IDataGridCellProps<IInspectionParameter>)
      .cellValue;
  }, []);

  const customParameterNameValidation = useCallback(
    (e: CellValueChangedEvent<IInspectionParameter>): IValidationResult => {
      const inputName = getCellValue(e.data.inputName).toString();
      const fieldConfig = getFieldConfiguration(inputName);

      if (fieldConfig) {
        const value = getCellValue(e.data.value);
        e.node.setDataValue("value", value);
        const valueType = getCellValue(e.data.valueType).toString();
        e.node.setDataValue("valueType", valueType);
      }
      return validateParameterName(e.value?.cellValue);
    },
    [getCellValue, getFieldConfiguration, validateParameterName]
  );

  const customTimeStampValidation = useCallback(
    (e: CellValueChangedEvent<IInspectionParameter>): IValidationResult => {
      return validateDate(e.value?.cellValue);
    },
    [validateDate]
  );

  const customValueTypeValidation = useCallback(
    (e: CellValueChangedEvent<IInspectionParameter>): IValidationResult => {
      const inputName = getCellValue(e.data.inputName).toString();
      const fieldConfig = getFieldConfiguration(inputName);
      const value = getCellValue(e.data.value);
      const assetType = getCellValue(e.data.assetType).toString();
      e.node.setDataValue("value", value);

      return validateValueType(e.value?.cellValue, fieldConfig, assetType);
    },
    [getCellValue, getFieldConfiguration, validateValueType]
  );

  const customValueValidation = useCallback(
    (e: CellValueChangedEvent<IInspectionParameter>): IValidationResult => {
      if (isNil(e.value) || isNil(e.value.cellValue) || !e.value?.cellValue)
        return { valid: false, validationMessage: defaultMessages.required };

      const inputName = getCellValue(e.data.inputName).toString();
      const valueType = getCellValue(e.data.valueType).toString();
      const assetType = getCellValue(e.data.assetType).toString();

      const fieldConfig = getFieldConfiguration(inputName);
      return validateValue(
        e.value?.cellValue,
        valueType,
        fieldConfig,
        assetType
      );
    },
    [
      defaultMessages.required,
      getCellValue,
      getFieldConfiguration,
      validateValue
    ]
  );

  const valueEditorSelector = useCallback(
    (params: ICellEditorParams): CellEditorSelectorResult => {
      const assetType = getCellValue(params.data.assetType)?.toString();

      const fieldConfig = configuredFields.find(
        (field) => field.fieldKey === params.data.inputName.cellValue
      );

      if (fieldConfig && assetType === "Transformer") {
        switch (fieldConfig.inputType) {
          case "String":
            return {
              component: TextCellEditor
            };
          case "Decimal":
            return {
              component: NumberCellEditor
            };
          case "list":
            return {
              component: SelectCellEditor,
              params: {
                options: mapListValuesToSelectOptions(fieldConfig.listValues)
              }
            };
          case "autoComplete":
            return {
              component: AutoCompleteCellEditor,
              params: {
                options: mapListValuesToSelectOptions(fieldConfig.listValues)
              }
            };
          case "DateTime":
            return {
              component: DateCellEditor,
              params: {
                disableFutureDates: fieldConfig.disableFutureDates
              }
            };
          case "Bool":
            return {
              component: SelectCellEditor,
              params: {
                options: mapListValuesToSelectOptions(fieldConfig.listValues)
              }
            };

          default:
            return {
              component: TextCellEditor
            };
        }
      } else {
        switch (params.data.valueType.cellValue) {
          case "Bool": {
            return {
              component: SelectCellEditor,
              params: {
                options: mapListValuesToSelectOptions(boolValues)
              }
            };
          }
          case "Decimal":
            return {
              component: NumberCellEditor
            };
          case "String":
            return {
              component: TextCellEditor
            };
          case "DateTime":
            return {
              component: DateCellEditor,
              params: {
                disableFutureDates: true
              }
            };
          default:
            return {
              component: TextCellEditor
            };
        }
      }
    },
    [configuredFields, getCellValue, mapListValuesToSelectOptions]
  );

  const suppressKeyboardEvent = useCallback(
    (params: SuppressKeyboardEventParams<IInspectionParameter>): boolean => {
      if (params.editing && params.event.code === "Enter") {
        const inputName = getCellValue(params.data.inputName)?.toString();
        const assetType = getCellValue(params.data.assetType)?.toString();

        const fieldConfig = configuredFields.find(
          (field) => field.fieldKey === inputName
        );

        if (fieldConfig && assetType === "Transformer") {
          return componentsWhichRequiresSupressingEnterKeyWhenEditing.includes(
            fieldConfig.inputType
          );
        } else {
          const valueType = getCellValue(params.data.valueType)?.toString();
          return componentsWhichRequiresSupressingEnterKeyWhenEditing.includes(
            valueType
          );
        }
      }
      return false;
    },
    [configuredFields, getCellValue]
  );

  const columns: IDataGridProps<IInspectionParameter>["columns"] = useMemo(
    () => [
      {
        field: "assetId",
        headerName: intl.formatMessage({
          id: "bulk_inspection.grid_header.asset_id",
          defaultMessage: "Asset ID"
        }),
        flex: 2,
        editable: true,
        resizable: true,
        editType: "Text",
        customValidation: customAssetIdValidation
      },
      {
        field: "timestamp",
        headerName: intl.formatMessage({
          id: "bulk_inspection.grid_header.timestamp",
          defaultMessage: "Collecting Date"
        }),
        flex: 1,
        editable: true,
        resizable: true,
        editType: "Date",
        disableFutureDates: true,
        customValidation: customTimeStampValidation
      },
      {
        field: "inputName",
        headerName: intl.formatMessage({
          id: "bulk_inspection.grid_header.input_name",
          defaultMessage: "Input"
        }),
        flex: 3,
        editable: true,
        resizable: true,
        editType: "Text",
        customValidation: customParameterNameValidation
      },
      {
        field: "value",
        headerName: intl.formatMessage({
          id: "bulk_inspection.grid_header.value",
          defaultMessage: "Value"
        }),
        flex: 2,
        editable: true,
        resizable: true,
        cellEditorSelector: valueEditorSelector,
        customValidation: customValueValidation,
        suppressKeyboardEvent
      },
      {
        field: "valueType",
        headerName: intl.formatMessage({
          id: "bulk_inspection.grid_header.value_type",
          defaultMessage: "Value Type"
        }),
        flex: 1,
        editable: true,
        resizable: true,
        editType: "Select",
        options: dataTypeSelectOptions,
        customValidation: customValueTypeValidation
      }
    ],
    [
      customAssetIdValidation,
      customParameterNameValidation,
      customTimeStampValidation,
      customValueTypeValidation,
      customValueValidation,
      dataTypeSelectOptions,
      intl,
      suppressKeyboardEvent,
      valueEditorSelector
    ]
  );

  const onCellChanged = useCallback(
    ({
      cellName,
      newValue,
      rowId,
      valid,
      modified,
      validationMessage,
      warning
    }: IOnCellChangedConfig) => {
      updateParametersData(
        parametersData.map((param) => {
          return param.id === rowId
            ? {
                ...param,
                cells: param.cells.map((cell) => {
                  if (cell.cellName === cellName) {
                    return {
                      ...cell,
                      cellValue: newValue,
                      valid,
                      modified,
                      warning,
                      validationMessage: validationMessage
                    };
                  }

                  return cell;
                })
              }
            : param;
        })
      );
    },
    [parametersData, updateParametersData]
  );

  return (
    <div className={className} data-qa="bulk-inspection-grid">
      {initialData && (
        <DataGrid<IInspectionParameter>
          data={initialData}
          columns={columns}
          onCellChanged={onCellChanged}
          gridHeight="100%"
          noRowsMessage={intl.formatMessage({
            id: "bulk_inspection.no_rows_to_show",
            defaultMessage: "No rows to show"
          })}
          rowHeight={32}
          sizeColumnsToFit={false}
          dateFormat={dayjsDefaultFormat.short}
        />
      )}
    </div>
  );
};

const StyledParametersGrid = styled(ParametersGrid)`
  position: relative;
  flex-grow: 1;
  & > div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export default StyledParametersGrid;
