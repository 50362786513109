// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { TooltipPlacement } from "antd/lib/tooltip";
import Popover, { popoverClick } from "common/popover/Popover";
import { loadDegradationScoreFamilyInfoAction } from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import { useCallback } from "react";
import { useAppDispatch } from "store";

interface IFamilyInfoDegradationScoreIconProps {
  assetId: string;
  placement?: TooltipPlacement;
}

const DegradationScoreFamilyIcon = ({
  assetId,
  placement
}: IFamilyInfoDegradationScoreIconProps) => {
  const dispatch = useAppDispatch();

  const handleShow = useCallback(() => {
    dispatch(loadDegradationScoreFamilyInfoAction(assetId));
  }, [assetId, dispatch]);

  return (
    <Popover
      content={
        <FamilyInfoContainer
          assetId={assetId}
          familyAnalyticsDataType="DegradationScore"
          familyAnalyticsSeriesName="DegradationScore"
        />
      }
      onShow={handleShow}
      overlayClassName="family-info-degradation-popover family-info-popover"
      placement={placement}
      destroyTooltipOnHide
    >
      <span>
        <Icon
          className={`family-info-popover-icon ${popoverClick}`}
          dataQa="header-condition-family-icon"
          name="speed"
          size="sm"
        />
      </span>
    </Popover>
  );
};

export default DegradationScoreFamilyIcon;
