// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { hexToRgb } from "@pg/common";
import { Typography } from "antd";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  colorGray100,
  colorStatusOrange,
  colorStatusYellow
} from "styles/ColorVariables";
import {
  fontSizeMedium,
  fontSizeXLarge,
  spacingLarge,
  spacingSmall,
  spacingXLarge,
  spacingXSmall,
  spacingXXLarge
} from "styles/StyleVariables";

const { Text } = Typography;

interface ILTCPercentageUsedInfo {
  value: number;
  warningThreshold: number;
  alarmThreshold: number;
  className?: string;
}

const LTCPercentageUsedInfo = ({
  value,
  warningThreshold,
  alarmThreshold,
  className
}: ILTCPercentageUsedInfo) => {
  const bgColorClass = useMemo(() => {
    if (value >= warningThreshold * 100 && value < alarmThreshold * 100) {
      return "ltc-bg-warning";
    } else if (value >= alarmThreshold * 100) {
      return "ltc-bg-alarm";
    }

    return "bg-white";
  }, [value, warningThreshold, alarmThreshold]);

  return (
    <div className={className}>
      <Text strong className="ltc-percentage-title">
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.ltc.percentage_used"
          defaultMessage="Percentage Used"
        />
      </Text>
      <Text strong className={`ltc-percentage-value ${bgColorClass}`}>
        <FormattedMessage
          defaultMessage="{value}%"
          id="detail_page.widgets.analytics.transformers.ltc.percentage_used_value"
          values={{
            value
          }}
        />
      </Text>
    </div>
  );
};

export default styled(LTCPercentageUsedInfo)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .ltc-percentage-title {
    margin-bottom: ${spacingLarge};
    font-size: ${fontSizeMedium};
  }

  .ltc-percentage-value {
    display: block;
    font-size: ${fontSizeXLarge};
    padding: ${spacingXLarge} ${spacingXXLarge};
    box-shadow: 0 ${spacingXSmall} ${spacingSmall}
      ${hexToRgb(colorGray100, 0.075)};
    border-radius: ${spacingSmall};
  }

  .ltc-bg-warning {
    background-color: ${colorStatusYellow};
  }

  .ltc-bg-alarm {
    background-color: ${colorStatusOrange};
  }
`;
