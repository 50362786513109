// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { bar, ChartOptions } from "billboard.js";
import { colorStatusOrange, colorStatusYellow } from "styles/ColorVariables";

export const AgingOperationsId = "aging-operations";
export const AgingWarningLegendId = "aging-warning";
export const AgingAlarmLegendId = "aging-alarm";

export const colors = {
  warning: colorStatusYellow,
  alarm: colorStatusOrange
};

function getDefaultConfiguration(
  agingOperations: number,
  warningThreshold: number,
  alarmThreshold: number
): ChartOptions {
  return {
    size: {
      height: 120
    },
    padding: { left: 10, right: 15 },
    tooltip: {
      show: false
    },
    data: {
      type: bar()
    },
    axis: {
      rotated: true,
      y: {
        padding: { top: 0, bottom: 0 },
        tick: { values: [0, agingOperations, warningThreshold, alarmThreshold] }
      },
      x: {
        show: false
      }
    },
    grid: {
      y: {
        show: true,
        lines: [
          { value: 0, class: "start-line" },
          { value: agingOperations, class: "end-line" }
        ]
      }
    },
    regions: [],
    legend: {
      hide: true
    },
    bar: {
      width: {
        ratio: 0.3
      }
    }
  };
}

export default (
  agingOperations: number,
  warningThreshold: number,
  alarmThreshold: number
) => {
  const configuration = getDefaultConfiguration(
    agingOperations,
    warningThreshold,
    alarmThreshold
  );
  configuration.data.columns = [[AgingOperationsId, agingOperations]];

  const agingOperationsMax = agingOperations + agingOperations / 10;
  const alarmThresholdMax = alarmThreshold + alarmThreshold / 10;

  if (alarmThreshold > agingOperations) {
    configuration.axis.y.max = alarmThresholdMax;
  } else {
    configuration.axis.y.max = agingOperationsMax;
  }

  if (warningThreshold) {
    configuration.grid.y.lines.push({
      value: warningThreshold,
      class: AgingWarningLegendId
    });
    configuration.regions.push({
      axis: "y",
      start: warningThreshold,
      end: alarmThreshold,
      class: AgingWarningLegendId
    });
  }

  if (alarmThreshold) {
    configuration.grid.y.lines.push({
      value: alarmThreshold,
      class: AgingAlarmLegendId
    });
    configuration.regions.push({
      axis: "y",
      start: alarmThreshold,
      class: AgingAlarmLegendId
    });
  }

  return configuration;
};
