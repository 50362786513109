import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";

const useNavToAsset = () => {
  const navigateTo = (assetId: string) => {
    const searchParams = new SearchParams({ assetId: assetId });
    const nav = {
      pathname: routes.detailPage.pathname,
      search: searchParams.toString()
    };

    return nav;
  };

  return { navigateTo };
};

export default useNavToAsset;
