// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";
import { Legend } from "@apm/widgets/build/components/BBChart";
import {
  colorBlue60,
  colorGray100,
  colorStatusOrange
} from "styles/ColorVariables";

function getLegend(intl: IntlShape): Legend {
  return [
    {
      name: intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.PowerFactor`,
        defaultMessage: "PowerFactor"
      }),
      displayName: `${intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.PowerFactor`,
        defaultMessage: "PowerFactor"
      })} (%)`,
      isDataSeries: true,
      type: "square",
      color: colorBlue60
    },
    {
      name: intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.Capacitance`,
        defaultMessage: "Capacitance"
      }),
      displayName: `${intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.Capacitance`,
        defaultMessage: "Capacitance"
      })} (pF)`,
      isDataSeries: true,
      type: "square",
      color: colorStatusOrange
    },
    {
      name: "ReferencePowerFactor",
      displayName: intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.ReferencePowerFactor`,
        defaultMessage: "ReferencePowerFactor"
      }),
      isDataSeries: false,
      type: "region",
      color: colorBlue60
    },
    {
      name: "ReferenceCapacitance",
      displayName: intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.ReferenceCapacitance`,
        defaultMessage: "ReferenceCapacitance"
      }),
      isDataSeries: false,
      type: "region",
      color: colorStatusOrange
    },
    {
      name: "InstallDate",
      displayName: intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.ReferenceInstallDate`,
        defaultMessage: "InstallDate"
      }),
      isDataSeries: false,
      type: "region",
      color: colorGray100
    }
  ];
}

export default getLegend;
