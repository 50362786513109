// Copyright 2016-2023 Hitachi Energy. All rights reserved.
import { FormattedMessage } from "react-intl";

import "./Asset.less";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import { Link } from "react-router-dom";

interface IAssetProps {
  assetId: string;
  assetName: string;
  assetType: string | undefined;
  location: string | undefined;
  navigateTo: { pathname: string; search: string };
}

const Asset = (props: IAssetProps) => {
  const { assetId, assetName, assetType, location, navigateTo } = props;
  const showLongMessage = assetType !== undefined && location !== undefined;
  const showShortMessage = !showLongMessage;

  const Text = <>{assetName ? `${assetId} - ${assetName}` : assetId}</>;

  return (
    <div className="column asset">
      <TooltipWrapper Tooltip={() => Text} theme={Themes.Dark}>
        <Link data-qa="link" className="id link-label" to={navigateTo}>
          {Text}
        </Link>
      </TooltipWrapper>
      <div className="details">
        {showLongMessage && (
          <FormattedMessage
            defaultMessage="{location} / {assetType}"
            id="asset_query.grid.columns.asset.location_asset_type"
            values={{
              assetType: assetType,
              location: location
            }}
          />
        )}
        {showShortMessage && (
          <FormattedMessage
            defaultMessage="{value}"
            id="asset_query.grid.columns.asset.location_asset_type_short"
            values={{
              value: assetType || location
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Asset;
