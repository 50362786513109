// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IColumnConfig } from "common/datagrid/DataGrid";
import AssetWithLocationColumn from "../components/AssetWithLocationColumn";
import RiskColumnHeader from "../components/RiskColumnHeader";
import SortOrders from "common/datagrid/models/SortOrders";

const gridColumnsConfig: IColumnConfig[] = [
  {
    component: (_, row) => (
      <AssetWithLocationColumn
        assetId={row.data["AssetId"]}
        location={row.data["AssetLocation"]}
      />
    ),
    id: "AssetId",
    message: {
      defaultMessage: "Asset",
      id: "component_risk_summary_page.grid.columns.asset"
    },
    weight: 5
  },
  {
    id: "AssetType",
    message: {
      defaultMessage: "Type",
      id: "component_risk_summary_page.grid.columns.type"
    },
    weight: 3
  },
  {
    id: "ComponentsCount",
    message: {
      defaultMessage: "Number of Components",
      id: "component_risk_summary_page.grid.columns.number_of_components"
    },
    weight: 3
  },
  {
    headerComponent: (
      <RiskColumnHeader
        message={{
          defaultMessage: "High",
          id: "component_risk_summary_page.grid.columns.risk_high"
        }}
        risk="high"
      />
    ),
    id: "HighRiskCount",
    weight: 2,
    defaultSortOrder: SortOrders.Desc
  },
  {
    headerComponent: (
      <RiskColumnHeader
        message={{
          defaultMessage: "Medium",
          id: "component_risk_summary_page.grid.columns.risk_medium"
        }}
        risk="medium"
      />
    ),
    id: "MediumRiskCount",
    weight: 2
  },
  {
    headerComponent: (
      <RiskColumnHeader
        message={{
          defaultMessage: "Low",
          id: "component_risk_summary_page.grid.columns.risk_low"
        }}
        risk="low"
      />
    ),
    id: "LowRiskCount",
    weight: 2
  },
  {
    headerComponent: (
      <RiskColumnHeader
        message={{
          defaultMessage: "Unknown",
          id: "component_risk_summary_page.grid.columns.risk_unknown"
        }}
        risk="unknown"
      />
    ),
    id: "UnknownRiskCount",
    weight: 2
  }
];

export default gridColumnsConfig;
