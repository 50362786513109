// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isObject } from "lodash";
import { IntlShape } from "react-intl";
import styled from "styled-components";
import messageTranslationKeys from "../constants/messageTranslationKeys";
import IUpdateIssueStatusResponse, {
  Error,
  IUpdateIssueStatusObjectResponse,
  IUpdateIssueStatusWarningResponse
} from "../models/IUpdateIssueStatusResponse";

const error = (
  response: IUpdateIssueStatusObjectResponse,
  error: Error,
  intl: IntlShape
) => (
  <Text>
    {intl.formatMessage(messageTranslationKeys[error], {
      num: response.Errors[error]?.length
    })}
  </Text>
);

const errors = (
  response: IUpdateIssueStatusObjectResponse,
  intl: IntlShape
): JSX.Element => (
  <>
    {response.Errors[Error.ISSUE_NOT_FOUND] &&
      error(response, Error.ISSUE_NOT_FOUND, intl)}
    {response.Errors[Error.ASSET_STATUS_IS_REMOVED] &&
      error(response, Error.ASSET_STATUS_IS_REMOVED, intl)}
    {response.Errors[Error.ISSUE_STATUS_IS_CLOSED] &&
      error(response, Error.ISSUE_STATUS_IS_CLOSED, intl)}
    {response.Errors[Error.ISSUE_HAS_THE_SAME_STATUS] &&
      error(response, Error.ISSUE_HAS_THE_SAME_STATUS, intl)}
    {response.Errors[Error.ISSUE_MODIFIED] &&
      error(response, Error.ISSUE_MODIFIED, intl)}
  </>
);

const isErrorObject = (
  response: IUpdateIssueStatusResponse
): response is IUpdateIssueStatusObjectResponse =>
  isObject(response) && !!response.Errors;

export const getErrorMessage = (
  response: IUpdateIssueStatusResponse,
  totalIssueCount: number,
  intl: IntlShape
): JSX.Element => (
  <>
    {isErrorObject(response) ? (
      errors(response, intl)
    ) : (
      <Text>
        {intl.formatMessage(
          messageTranslationKeys[
            response === Error.COMMENT_IS_MANDATORY
              ? Error.COMMENT_IS_MANDATORY
              : "GeneralError"
          ],
          { totalIssueCount }
        )}
      </Text>
    )}
    <Text>
      {intl.formatMessage(messageTranslationKeys["RefreshPageMessage"])}
    </Text>
  </>
);

export const getSuccessMessage = (
  issueCount: number,
  status: string,
  intl: IntlShape
): JSX.Element => (
  <Text>
    {intl.formatMessage(messageTranslationKeys["Success"], {
      num: issueCount,
      status: <strong>{status}</strong>
    })}
  </Text>
);

export const getWarningMessage = (
  response: IUpdateIssueStatusWarningResponse,
  status: string,
  intl: IntlShape
): JSX.Element => (
  <>
    {getSuccessMessage(response.SuccessCount, status, intl)}
    {errors(response, intl)}
  </>
);

const Text = styled.p`
  margin-bottom: 0;
`;
