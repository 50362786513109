// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartOptions, line } from "billboard.js";
import IHistorySwitchingOperations from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import { isEmpty } from "lodash";
import { IntlShape } from "react-intl";
import { colorBlue50 } from "styles/ColorVariables";

const colors = {
  historySwitchingOperations: colorBlue50
};

const historySwitchingDatesId = "history-switching-dates";
const historySwitchingOperationsDataId = "history-switching-operations";

const getDefaultConfiguration = (intl: IntlShape): ChartOptions => ({
  padding: { left: 25, right: 25 },
  data: {
    x: historySwitchingDatesId,
    type: line(),
    colors: {
      [historySwitchingOperationsDataId]: colors.historySwitchingOperations
    },
    empty: {
      label: {
        text: `${intl.formatMessage({
          id: "detail_page.widgets.analytics.linechart.nodata",
          defaultMessage: "NO INPUT DATA AVAILABLE"
        })}`
      }
    },
    names: {
      [historySwitchingOperationsDataId]: intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.ltc.history_operations_name`,
        defaultMessage: "History of Switching Operations"
      })
    }
  },
  axis: {
    x: {
      type: "timeseries",
      tick: {
        count: 5,
        format: (x: Date) => intl.formatDate(x)
      }
    },
    y: {
      tick: {
        format: (val: number & string) => (val <= 0 ? val : `+${val}`)
      }
    }
  },
  grid: {
    y: {
      show: true
    }
  },
  legend: {
    hide: true
  },
  point: {
    r: 5
  },
  tooltip: {
    format: {
      name: (name: string) =>
        intl.formatMessage({
          id: `detail_page.widgets.analytics.transformers.ltc.history_operations_name`,
          defaultMessage: name
        })
    }
  }
});

export default (
  historySwitching: IHistorySwitchingOperations,
  intl: IntlShape
) => {
  const { historyDates, historyData } = historySwitching;

  const configuration = getDefaultConfiguration(intl);

  if (!isEmpty(historyDates) && !isEmpty(historyData)) {
    configuration.data.columns = [
      [historySwitchingDatesId, ...historyDates],
      [historySwitchingOperationsDataId, ...historyData]
    ];
  } else {
    configuration.data.columns = [];
  }

  return configuration;
};
