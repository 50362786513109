// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { resetForm } from "common/form/actions/FormActions";
import { IIssueStatusChangeConfirmationModalActions } from "features/detailpage/features/issues/components/IssueStatusChangeConfirmationModal";

import { connect } from "react-redux";
import { AppDispatch } from "store";
import IssueStatusChangeConfirmationModal from "../components/IssueStatusChangeConfirmationModal";

export function mapDispatchToProps(
  dispatch: AppDispatch
): IIssueStatusChangeConfirmationModalActions {
  return {
    resetForm: (formName: string) => {
      resetForm(dispatch, formName);
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(IssueStatusChangeConfirmationModal);
