// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";
import { spacingMedium, spacingXLarge } from "styles/StyleVariables";

interface IRiskColumnHeaderProps {
  message: MessageDescriptor;
  risk: string;
  className?: string;
}

const RiskColumnHeader = ({
  message,
  risk,
  className
}: IRiskColumnHeaderProps) => {
  return (
    <div className={className}>
      <div className={`asset-risk-bg ${risk.toLowerCase()}`} />
      <FormattedMessage {...message} />
    </div>
  );
};

export default styled(RiskColumnHeader)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .asset-risk-bg {
    display: inline-block;
    min-width: ${spacingXLarge};
    width: ${spacingXLarge};
    height: ${spacingXLarge};
    border-radius: 50%;
    margin-right: ${spacingMedium};
  }
`;
