// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Popover } from "antd";
import { IColumn } from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import { isNil } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface IHeaderCellProps {
  column: IColumn;
  columns: IColumn[];
  onColumnClick?: (columnId: string, modifierIsPressed: boolean) => void;
}

export interface IHeaderCellState {
  pressPosition: {
    clientX: number | null;
    clientY: number | null;
  };
}

export default class HeaderCell extends React.Component<
  IHeaderCellProps,
  IHeaderCellState
> {
  constructor(props: IHeaderCellProps) {
    super(props);

    this.state = {
      pressPosition: {
        clientX: 0,
        clientY: 0
      }
    };
  }

  render() {
    const { column, columns } = this.props;
    const styles = HeaderCell.getCellStyles(column, columns);
    let gridCellContent = this.getGridCellContent(column, columns);

    if (column.config.HeaderTooltip) {
      gridCellContent = (
        <Popover content={<column.config.HeaderTooltip />}>
          {gridCellContent}
        </Popover>
      );
    }

    return (
      <div
        className={`
          grid-cell default-grey-label
          ${column.config.sortable !== false && "sortable"}
        `}
        key={column.config.id}
        style={styles}
        onMouseDown={(e) => {
          this.handleColumnMouseDown(e, column.config.id);
        }}
        onMouseUp={(e) => {
          this.handleColumnMouseUp(e, column.config.id);
        }}
      >
        {gridCellContent}
      </div>
    );
  }

  private getGridCellContent(column: IColumn, columns: IColumn[]): JSX.Element {
    const hasCustomComponent = !isNil(column.config.headerComponent);

    return (
      <div className="grid-cell-content">
        <div className={`grid-cell-name-indicator`}>
          <div className={`name`}>
            {hasCustomComponent
              ? column.config.headerComponent
              : column.config.message && (
                  <FormattedMessage {...column.config.message} />
                )}
          </div>
          <div className="indicator">
            <SortArrow column={column} />
            <GroupOrder column={column} columns={columns} />
          </div>
        </div>
      </div>
    );
  }

  private static getCellStyles = (column: IColumn, columns: IColumn[]) => {
    const cellStyles = {
      width: column.state.calculatedWidth,
      minWidth: column.state.calculatedWidth,
      maxWidth: column.state.calculatedWidth
    };

    return cellStyles;
  };

  private handleColumnMouseDown = (
    e: React.MouseEvent<HTMLDivElement>,
    columnId: string
  ): void => {
    e.persist();

    this.setState((prevState, props) =>
      Object.assign({}, prevState, {
        pressPosition: {
          clientX: e.clientX,
          clientY: e.clientY
        }
      })
    );
  };

  private handleColumnMouseUp = (
    e: React.MouseEvent<HTMLDivElement>,
    columnId: string
  ): void => {
    e.persist();

    const { onColumnClick } = this.props;
    const { pressPosition } = this.state;

    if (
      Math.abs(pressPosition.clientX - e.clientX) < 2 &&
      Math.abs(pressPosition.clientY - e.clientY) < 2
    ) {
      if (onColumnClick) onColumnClick(columnId, e.ctrlKey || e.metaKey);
    }
  };
}

interface ISortArrowProps {
  column: IColumn;
}

const SortArrow = (props: ISortArrowProps) => {
  const { column } = props;

  const sortOrderClass = (sortOrder: SortOrders): string => {
    switch (sortOrder) {
      case SortOrders.Asc:
        return "asc";
      case SortOrders.Desc:
        return "desc";
      default:
        return "none";
    }
  };

  const style = {
    display: column.state.sortOrder ? undefined : "none"
  };

  return (
    <Icon
      className={sortOrderClass(column.state.sortOrder)}
      name="arrow_right"
      style={style}
    />
  );
};

interface IGroupOrderProps {
  column: IColumn;
  columns: IColumn[];
}

const GroupOrder = (props: IGroupOrderProps) => {
  const { column, columns } = props;

  const groupOrder = (): number =>
    groupOrders().indexOf(column.state.groupOrder || 0) + 1;

  const groupOrders = (): number[] =>
    columns
      .filter((c) => c.state.groupOrder !== undefined)
      .map((c) => c.state.groupOrder || 0)
      .sort((a: number, b: number) => a - b);

  const showGroupOrders = (): boolean => {
    const orders = groupOrders();
    return column.state.sortOrder && orders && orders.length > 1;
  };

  const style = {
    display: showGroupOrders() ? undefined : "none"
  };

  return <span style={style}>{groupOrder()}</span>;
};
