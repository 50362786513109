// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { Checkbox as AntdCheckbox, Button } from "antd";
import Modal from "common/Modal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import {
  iconFontSizeSmall,
  spacingBase,
  spacingLarge,
  spacingXLarge,
  spacingXXXLarge
} from "styles/StyleVariables";

export interface IIssuesLimitConfirmationModalOwnProps {
  limit: number;
  visible: boolean;
  numberOfIssues?: number;
  title?: string;
  className?: string;
  hideModal: () => void;
  onConfirmAction: () => void;
}

export const PREVENT_ISSUES_LIMIT_CONFIRMATION_MODAL =
  "prevent_issues_limit_confirmation_modal";

export const isUserAgreedSkipLimitConfirmationModal = () =>
  sessionStorage.getItem(PREVENT_ISSUES_LIMIT_CONFIRMATION_MODAL) === "true";

const IssuesLimitConfirmationModal = ({
  limit,
  visible,
  hideModal,
  onConfirmAction
}: IIssuesLimitConfirmationModalOwnProps) => {
  const [isDontAskAgain, setIsDontAskAgain] = useState<boolean>(false);

  const handleConfirmAction = () => {
    if (isDontAskAgain) {
      sessionStorage.setItem(PREVENT_ISSUES_LIMIT_CONFIRMATION_MODAL, "true");
    }

    onConfirmAction();
  };

  return (
    <Modal
      open={visible}
      centered={true}
      width="600px"
      footer={null}
      closable={false}
      data-qa="issues-limit-confirmation-modal"
      destroyOnClose={true}
    >
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <HelpIcon name="help_outline" className="help-icon" />
        <div>
          <Text>
            <FormattedMessage
              id={"issues_page.issue.limit_modal.text_1"}
              defaultMessage="Your selection contains more than {limit} issues. It is only possible to select a maximum of {limit} issues at the same time."
              values={{
                limit
              }}
            />
          </Text>
          <Text>
            <FormattedMessage
              id={"issues_page.issue.limit_modal.text_2"}
              defaultMessage="Do you want to select the first {limit} issues?"
              values={{
                limit
              }}
            />
          </Text>
          <ActionsArea>
            <CheckboxArea>
              <Checkbox
                checked={isDontAskAgain}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setIsDontAskAgain(e.target.checked)}
              >
                <FormattedMessage
                  id={"issues_page.issue.limit_modal.checkbox.label"}
                  defaultMessage="Do not ask me again"
                />
              </Checkbox>
            </CheckboxArea>
            <Button
              data-qa="issues_limit_modal_confirm"
              onClick={() => {
                setIsDontAskAgain(false);
                hideModal();
              }}
            >
              <FormattedMessage
                id={"issues_page.issue.limit_modal.button.cancel"}
                defaultMessage="Cancel"
              />
            </Button>
            <ConfirmButton
              data-qa="issues_limit_modal_cancel"
              htmlType="submit"
              type="primary"
              onClick={handleConfirmAction}
            >
              <FormattedMessage
                id={"issues_page.issue.limit_modal.button.confirm"}
                defaultMessage="OK"
              />
            </ConfirmButton>
          </ActionsArea>
        </div>
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  padding-right: ${spacingXLarge};
  display: flex;
`;

const ActionsArea = styled.div`
  display: flex;
`;

const CheckboxArea = styled.div`
  flex-grow: 1;
`;

const HelpIcon = styled(Icon)`
  color: ${colorPrimary};
  padding-right: ${spacingBase};
`;

const Text = styled.span`
  display: block;
  font-size: 15px;
  margin-bottom: ${spacingXLarge};

  &:last-of-type {
    margin-bottom: ${spacingXXXLarge};
  }
`;

const Checkbox = styled(AntdCheckbox)`
  font-size: ${iconFontSizeSmall};
  line-height: 1.75;
`;

const ConfirmButton = styled(Button)`
  margin-left: ${spacingLarge};
`;

export default IssuesLimitConfirmationModal;
