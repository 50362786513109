// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";
import EGasStandard from "../components/analytics/DuvalTriangle/models/EGasStandard";

const getIsTransformerStandardModelMinerals = (
  implementationId: string,
  gasStandard: EGasStandard
) =>
  implementationId?.toLowerCase() ===
    AssetImplementationId.AbbTransformerStandardModel &&
  [EGasStandard.IEEE, EGasStandard.IEC, EGasStandard.ETRA_MINERAL].includes(
    gasStandard
  );

export default getIsTransformerStandardModelMinerals;
