// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Chart } from "billboard.js";
import IAssetPoint from "common/AssetRiskMatrix/models/IAssetPoint";
import IAssetRiskConfigurationValue from "common/AssetRiskMatrix/models/IAssetRiskConfigurationValue";
import IChartSize from "common/AssetRiskMatrix/models/IChartSize";
import ICluster from "common/AssetRiskMatrix/models/ICluster";
import IPrognosticPoint from "common/AssetRiskMatrix/models/IPrognosticPoint";
import ITierCluster from "common/AssetRiskMatrix/models/ITierCluster";
import { useCallback } from "react";
import useAssetRiskMatrixChartDataClickHandle from "./useAssetRiskMatrixChartDataClickHandle";
import useAssetRiskMatrixConfiguration from "./useAssetRiskMatrixConfiguration";
import useAssetRiskMatrixDotsResize from "./useAssetRiskMatrixDotsResize";
import useAssetRiskMatrixResizeObserver from "./useAssetRiskMatrixResizeObserver";
import useAssetRiskMatrixScatterData from "./useAssetRiskMatrixScatterData";
import useAssetRiskMatrixTooltips from "./useAssetRiskMatrixTooltips";

interface IUseAssetRiskMatrixProps {
  currentAsset?: IAssetPoint;
  otherAssets?: IAssetPoint[];
  clusters?: ICluster[];
  prognosticPoints?: IPrognosticPoint[];
  thresholds: IAssetRiskConfigurationValue;
  showLines?: boolean;
  grayscale?: boolean;
  chartSize?: IChartSize;
  onAssetClick?: (assetId: string) => void;
  onClusterClick?: (cluster: ICluster) => void;
}

const useAssetRiskMatrix = ({
  currentAsset,
  otherAssets,
  clusters,
  prognosticPoints,
  thresholds,
  showLines,
  grayscale,
  chartSize,
  onAssetClick,
  onClusterClick
}: IUseAssetRiskMatrixProps) => {
  const { createResizeObserver } = useAssetRiskMatrixResizeObserver();
  const { resizeDots } = useAssetRiskMatrixDotsResize();
  const {
    groupedClusters,
    getClusterData,
    getAssetData,
    getPrognosticPointData
  } = useAssetRiskMatrixScatterData({
    currentAsset: currentAsset as IAssetPoint,
    clusters,
    otherAssets,
    prognosticPoints
  });
  const { getTooltipContent } = useAssetRiskMatrixTooltips({
    grayscale: grayscale as boolean,
    getClusterData: getClusterData as (
      seriesName: string,
      idx: number
    ) => ICluster,
    getAssetData: getAssetData as (
      seriesName: string,
      idx: number
    ) => IAssetPoint,
    getPrognosticPointData: getPrognosticPointData as (
      seriesName: string,
      idx: number
    ) => IPrognosticPoint
  });

  const { handleChartDataClick } = useAssetRiskMatrixChartDataClickHandle({
    getAssetData: getAssetData as (
      seriesName: string,
      idx: number
    ) => IAssetPoint,
    getClusterData: getClusterData as (
      seriesName: string,
      idx: number
    ) => ICluster,
    onAssetClick,
    onClusterClick
  });

  const handleChartRendered = useCallback(
    (chart: Chart, container: HTMLDivElement) => {
      createResizeObserver(chart, container);
      resizeDots(container);
    },
    [createResizeObserver, resizeDots]
  );

  return {
    ...useAssetRiskMatrixConfiguration({
      getTooltipContent,
      thresholds,
      currentAsset,
      onChartDataClick: handleChartDataClick,
      otherAssets,
      showLines,
      chartSize,
      clusters,
      groupedClusters: groupedClusters as ITierCluster,
      prognosticPoints,
      grayscale
    }),
    handleChartRendered
  };
};

export default useAssetRiskMatrix;
