// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import {
  colorGray0,
  colorWidgetBorder
} from "@pg/common/build/styles/ColorVariables";
import { Layout, Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { spacingLarge } from "styles/StyleVariables";
import useBulkInspection from "../hooks/useBulkInspection";
import IAssetParameter from "../models/IAssetParameter";
import GridsFooter from "./GridsFooter";
import GridsHeader from "./GridsHeader";
import ParametersGrid from "./ParametersGrid";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

interface IModalContentProps {
  assetParameters: IAssetParameter[];
  onCancel: () => void;
  className?: string;
}

const ModalContent = ({
  assetParameters,
  onCancel,
  className
}: IModalContentProps) => {
  const [isSubmittingInProgress, setIsSubmittingInProgress] = useState(false);

  const {
    configuredFields,
    configuredParameterNames,
    initialData,
    parametersData,
    updateParametersData,
    showOnlyInvalid,
    handleShowOnlyInvalid,
    handleVerifyData,
    handleSubmit
  } = useBulkInspection(assetParameters);

  const intl = useIntl();

  const errorsCount = useMemo(() => {
    return parametersData
      ? parametersData
          .map((param) => param.cells.filter((cell) => !cell.valid).length)
          .reduce((total, curr) => total + curr, 0)
      : 0;
  }, [parametersData]);

  const warningsCount = useMemo(() => {
    return parametersData
      ? parametersData
          .map((param) => param.cells.filter((cell) => cell.warning).length)
          .reduce((total, curr) => total + curr, 0)
      : 0;
  }, [parametersData]);

  const displayErrorMessage = useCallback(
    (error: string) => {
      if (error) {
        notifications.error({
          message: intl.formatMessage({
            id: `bulk_inspection.messages.${error}`,
            defaultMessage: error
          })
        });
      } else {
        notifications.error({
          message: intl.formatMessage({
            id: `bulk_inspection.messages.REQUEST_CANT_BE_PROCESSED`,
            defaultMessage: "The request cannot be processed"
          })
        });
      }
    },
    [intl]
  );

  const handleGridFooterSubmit = useCallback(async () => {
    setIsSubmittingInProgress(true);

    handleSubmit().then(
      (result) => {
        notifications.success({
          message: intl.formatMessage({
            id: "bulk_inspection.bulk_inspection_data_imported",
            defaultMessage: "New bulk inspection data has been imported."
          })
        });

        setIsSubmittingInProgress(false);
        onCancel();
      },
      (error) => {
        displayErrorMessage(error);
        setIsSubmittingInProgress(false);
      }
    );
  }, [handleSubmit, intl, onCancel, displayErrorMessage]);

  return (
    configuredFields && (
      <div className={className}>
        <Header>
          <Title level={5}>
            <FormattedMessage
              defaultMessage="Bulk Inspection Import"
              id="bulk_inspection.bulk_inspection_import"
            />
          </Title>
        </Header>
        <Content>
          <GridsHeader
            parametersCount={assetParameters?.length || 0}
            errorsCount={errorsCount}
            warningsCount={warningsCount}
            showOnlyInvalid={showOnlyInvalid}
            onShowOnlyInvalid={handleShowOnlyInvalid}
            onVerifyData={handleVerifyData}
          ></GridsHeader>
          <ParametersGrid
            parametersData={parametersData}
            initialData={initialData}
            configuredFields={configuredFields}
            configuredParameterNames={configuredParameterNames}
            updateParametersData={updateParametersData}
          ></ParametersGrid>
        </Content>
        <Footer>
          <GridsFooter
            errorsCount={errorsCount}
            warningsCount={warningsCount}
            onCancel={onCancel}
            onSubmit={handleGridFooterSubmit}
            submittingInProgress={isSubmittingInProgress}
          ></GridsFooter>
        </Footer>
      </div>
    )
  );
};

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .ant-layout-header,
  .ant-layout-content,
  .ant-layout-footer {
    background-color: ${colorGray0};
  }

  .ant-layout-header {
    padding: 0px 0px;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex-grow: 1;
  }

  .ant-layout-footer {
    margin-top: ${spacingLarge};
    width: 100%;
    border-top: 1px solid;
    border-color: ${colorWidgetBorder};
    align-self: flex-end;
    padding: ${spacingLarge} 0px 0px 0px;
  }
`;

export default StyledModalContent;
