// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import Popover, { popoverClick } from "common/popover/Popover";
import { loadReplacementScoreFamilyInfoAction } from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import { connect } from "react-redux";
import { AppDispatch } from "store";

export interface IFamilyInfoReplacementScoreIconActions {
  loadReplacementScoreFamilyInfoAction: () => void;
}

export interface IFamilyInfoReplacementScoreIconOwnProps {
  assetId: string;
}

interface IFamilyInfoReplacementScoreIconProps
  extends IFamilyInfoReplacementScoreIconActions,
    IFamilyInfoReplacementScoreIconOwnProps {}

const ReplacementScoreFamilyIcon = ({
  assetId,
  loadReplacementScoreFamilyInfoAction
}: IFamilyInfoReplacementScoreIconProps) => {
  return (
    <Popover
      placement="bottomRight"
      content={
        <FamilyInfoContainer
          assetId={assetId}
          familyAnalyticsDataType={"ReplacementScore"}
          familyAnalyticsSeriesName={"ReplacementScore"}
        />
      }
      onShow={loadReplacementScoreFamilyInfoAction}
      overlayClassName="family-info-replacement-popover family-info-popover"
      destroyTooltipOnHide
    >
      <span>
        <Icon
          className={`family-info-popover-icon ${popoverClick}`}
          dataQa="header-replacement-family-icon"
          name="speed"
          size="sm"
        />
      </span>
    </Popover>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: IFamilyInfoReplacementScoreIconOwnProps
): IFamilyInfoReplacementScoreIconActions => {
  return {
    loadReplacementScoreFamilyInfoAction: () =>
      dispatch(loadReplacementScoreFamilyInfoAction(ownProps.assetId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementScoreFamilyIcon);
