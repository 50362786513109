// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/ja";
import "dayjs/locale/pl";
import "dayjs/locale/pt-br";
import "dayjs/locale/zh-cn";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import updateLocale from "dayjs/plugin/updateLocale";
import { dayjsDefaultFormat } from "common/DateTime/utils/dateFormatters";

const setupDayjs = (locale: string) => {
  switch (locale) {
    case "zh":
      dayjs.locale("zh-cn");
      break;
    case "pt":
      dayjs.locale("pt-br");
      break;
    default:
      dayjs.locale(locale);
  }

  dayjs.extend(utc);
  dayjs.extend(localeData);
  dayjs.extend(minMax);
  dayjs.extend(updateLocale);

  //customise selected languages locale object
  dayjs.updateLocale("en", {
    formats: { l: "M/D/YYYY" }
  });

  dayjs.updateLocale("ja", {
    formats: { l: "YYYY/M/D" }
  });

  dayjs.updateLocale("pl", {
    formats: { l: "D.MM.YYYY" }
  });

  dayjs.updateLocale("pt-br", {
    formats: { l: "DD/MM/YYYY" }
  });

  dayjsDefaultFormat.short = dayjs.localeData().longDateFormat("l");
};

export default setupDayjs;
