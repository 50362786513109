// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IOilSettings, { OilTest } from "../models/IOilSettings";

const chartSettings: { [key in OilTest]: IOilSettings } = {
  Moisture: { unit: "ppm" },
  AcidNumber: { unit: "mg KOH/g" },
  Color: { unit: "" },
  DielectricStrength: { unit: "kV" },
  DissipationFactor: { unit: "%" },
  InterfacialTension: { unit: "mN/m" },
  PowerFactor: { unit: "%" },
  PowerFactor100C: { unit: "%1" },
  PowerFactor25C: { unit: "%2" },
  PowerFactor90C: { unit: "" },
  InhibitorContent: { unit: "%" },
  WaterContent: { unit: "mg/kg" }
};

export default chartSettings;
