// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import {
  Actions,
  loadRogersRatioChartData,
  RogersRatioActions
} from "features/detailpage/features/rogersratio/actions/RogersRatioActions";
import { useCallback } from "react";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux";
import { useAppDispatch, useAppSelector } from "store";
import { RogersRatioData } from "../models/RogersRatioData";

export interface IState {
  rogersRatioData: Data<RogersRatioData>;
}

export const initialState: IState = {
  rogersRatioData: new Data()
};

export type RogersRatioReducer = Reducer<IState, RogersRatioActions>;

const reducer: RogersRatioReducer = (
  state = initialState,
  action: RogersRatioActions
) => {
  switch (action.type) {
    case Actions.RogersRatio_SetRogersRatioData: {
      return {
        ...state,
        rogersRatioData: new Data(
          (action as IAjaxAction<RogersRatioData>).request,
          (action as IAjaxAction<RogersRatioData>).data
        )
      };
    }
    default:
      return state;
  }
};

export default reducer;

type UseRogersRatioReducer = {
  rogersRatioData: Data<RogersRatioData>;
  loadRogersRatioData: (intl: IntlShape) => void;
};

export const useRogersRatioReducer = (
  assetId: string
): UseRogersRatioReducer => {
  const dispatch = useAppDispatch();
  const rogersRatioData = useAppSelector(
    (state) => state.rogersRatio.rogersRatioData
  );

  const loadRogersRatioData = useCallback(
    (intl: IntlShape) => loadRogersRatioChartData(dispatch, intl, assetId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetId]
  );

  return {
    rogersRatioData,
    loadRogersRatioData
  };
};
