// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import NoDataMessage from "common/messages/NoDataMessage";
import ModalWindow from "components/common/modal/ModalWindow";
import ProcessingModal from "components/common/ProcessingModal";
import Data, { Statuses } from "core/data/models/Data";
import ActionsModalContainer from "features/detailpage/features/actions/containers/ActionsModalContainer";
import IWorkOrder from "features/detailpage/models/IWorkOrder";
import IWorkRequest from "features/detailpage/models/IWorkRequest";
import IWorkRequestForm from "features/detailpage/models/IWorkRequestForm";
import dayjs from "dayjs";
import * as React from "react";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor
} from "react-intl";
import Action, { IAction, WorkOrderType } from "./Action";
import "./Actions.less";

export interface IActionsActions {
  closeModal: (modalId: string) => void;
  loadWorkOrders: () => Promise<{}>;
  openModal: (modalId: string) => void;
  showErrorMessage: (intl: IntlShape, message: MessageDescriptor) => void;
  switchIssueModeToEdit: (issueId: string) => void;
}

export interface IActionsData {
  availableWorkOrders: Data<IWorkOrder[]>;
}

export interface IActionsOwnProps {
  assetId: string;
  issueId: string;
  issueCondition: string;
  issueRecommendation: string;
  issueTitle: string;
  onChanged?: (
    workRequest: IWorkRequest | IWorkRequestForm,
    workOrders: IWorkOrder[]
  ) => void;
  readonly: boolean;
  workRequest: IWorkRequest | IWorkRequestForm;
  workOrders: IWorkOrder[];
}

interface IActionsProps
  extends IActionsActions,
    IActionsData,
    IActionsOwnProps {
  intl: IntlShape;
}

class Actions extends React.Component<IActionsProps> {
  render() {
    const {
      availableWorkOrders,
      closeModal,
      intl,
      issueId,
      issueCondition,
      issueRecommendation,
      issueTitle,
      loadWorkOrders,
      onChanged,
      openModal,
      readonly,
      showErrorMessage,
      switchIssueModeToEdit,
      workRequest,
      workOrders
    } = this.props;

    const handleAddActionClick = (
      e: React.MouseEvent<HTMLButtonElement> &
        React.MouseEvent<HTMLAnchorElement>
    ) => {
      e.preventDefault();
      e.stopPropagation();

      switchIssueModeToEdit(issueId);
      loadWorkOrders()
        .then(() => {
          openModal(this.modalId);
        })
        .catch((reason: JQuery.jqXHR) => {
          if (reason.status === 400) {
            showErrorMessage(intl, {
              defaultMessage: `Cannot load list of work orders. Response text: ${reason.responseJSON}.`,
              id: `detail_page.issues.actions.load.failed.${reason.responseJSON}`
            });
          } else {
            showErrorMessage(intl, {
              defaultMessage: "Cannot load list of work orders.",
              id: "detail_page.issues.actions.load.failed"
            });
          }
        });
    };

    const handleSubmit = (
      workRequest: IWorkRequest | IWorkRequestForm,
      workOrders: IWorkOrder[]
    ) => {
      if (onChanged) onChanged(workRequest, workOrders);
      closeModal(this.modalId);
    };

    const actions = this.mapToActions(workRequest, workOrders);
    const hasActions = actions.length > 0;

    return (
      <div className="actions">
        <div className="default-grey-label">
          <FormattedMessage
            defaultMessage="Actions"
            id="detail_page.issues.actions.label"
          />
        </div>
        <div className="action-list">
          {hasActions && (
            <div className="action-list-header">
              <div className="action-list-row">
                <div className="id">
                  <FormattedMessage
                    defaultMessage="Id"
                    id="detail_page.issues.actions.list.id"
                  />
                </div>
                <div className="description">
                  <FormattedMessage
                    defaultMessage="Description"
                    id="detail_page.issues.actions.list.description"
                  />
                </div>
                <div className="scheduled">
                  <FormattedMessage
                    defaultMessage="Scheduled"
                    id="detail_page.issues.actions.list.scheduled"
                  />
                </div>
                <div className="status">
                  <FormattedMessage
                    defaultMessage="Status"
                    id="detail_page.issues.actions.list.status"
                  />
                </div>
              </div>
            </div>
          )}
          {hasActions && (
            <div className="action-list-body">
              {actions.map((a) => (
                <Action action={a} key={a.id} />
              ))}
            </div>
          )}
          {!hasActions && (
            <div className="action-list-body">
              <NoDataMessage
                className="no-actions-assigned"
                message={{
                  defaultMessage: "No Actions assigned",
                  id: "detail_page.issues.no_actions_assigned"
                }}
                withBorder
              />
            </div>
          )}
        </div>
        {readonly ? null : (
          <div className="action-buttons">
            <Button
              className="add-action"
              type="link"
              size="small"
              onClick={handleAddActionClick}
            >
              <Icon name="add" />
              <FormattedMessage
                defaultMessage="Add Action"
                id="detail_page.issues.actions.add"
              />
            </Button>
          </div>
        )}
        {availableWorkOrders.status === Statuses.Loading && (
          <ProcessingModal className="actions-processing" />
        )}
        <ModalWindow
          className="modal-window-mixed"
          modalId={this.modalId}
          modalTitle={intl.formatMessage({
            defaultMessage: "Request Work",
            id: "detail_page.issues.actions.title"
          })}
        >
          <ActionsModalContainer
            condition={issueCondition}
            onCancel={() => {
              closeModal(this.modalId);
            }}
            onSubmit={handleSubmit}
            recommendation={issueRecommendation}
            title={issueTitle}
            workRequest={workRequest}
            workOrders={workOrders}
          />
        </ModalWindow>
      </div>
    );
  }

  private modalId = "DetailPage_Actions";

  private pushWorkRequest = (
    actions: IAction[],
    workRequest: IWorkRequest | IWorkRequestForm
  ) => {
    if (!workRequest) return;

    const isForm = !workRequest.hasOwnProperty("Summary");
    if (isForm) {
      workRequest = workRequest as IWorkRequestForm;

      const condition = workRequest.Condition || "";
      const recommendation = workRequest.Recommendation || "";
      const description = `${condition} ${recommendation}`.trim();

      actions.push({
        id: null,
        description,
        externalLink: null,
        scheduled: null,
        status: null,
        type: WorkOrderType.Request
      });
    } else {
      workRequest = workRequest as IWorkRequest;
      actions.push({
        id: workRequest.WorkRequestId,
        description: workRequest.Summary,
        externalLink: workRequest.ExternalLink,
        scheduled: workRequest.DueDate,
        status: workRequest.Status,
        type: WorkOrderType.Request
      });
    }
  };

  private pushWorkOrders = (actions: IAction[], workOrders: IWorkOrder[]) => {
    if (!workOrders) return;
    workOrders.forEach((o) => {
      const districtCode = o.WorkOrderIdentifier.WorkOrderDistrictCode;
      const workOrderNumber = o.WorkOrderIdentifier.WorkOrderNumber;
      const id = `${districtCode}-${workOrderNumber}`;
      actions.push({
        id,
        description: o.Summary,
        externalLink: o.ExternalLink,
        scheduled: o.DueDate,
        status: o.Status,
        type: WorkOrderType.Order
      });
    });
  };

  private mapToActions = (
    workRequest: IWorkRequest | IWorkRequestForm,
    workOrders: IWorkOrder[]
  ): IAction[] => {
    const actions: IAction[] = [];
    this.pushWorkRequest(actions, workRequest);
    this.pushWorkOrders(actions, workOrders);
    return actions.sort((a, b) => dayjs(a.scheduled).diff(dayjs(b.scheduled)));
  };
}

export default injectIntl(Actions);
