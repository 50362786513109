// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ILine from "@apm/widgets/build/models/ILine";
import IRegion from "@apm/widgets/build/models/IRegion";
import ISeries from "@apm/widgets/build/models/ISeries";
import { formatDateTime } from "common/DateTime/utils/dateFormatters";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import IAssetParameter from "../models/IAssetParameter";
import ISelectedParameter from "../models/ISelectedParameter";
import getParameterUrl from "../utils/getParameterUrl";

const useParametersChart = (
  assetId: string,
  selectedParameters: ISelectedParameter[] | null | undefined
) => {
  const intl = useIntl();

  const [parameters, setParameters] = useState<
    {
      name: string;
      data: Data<IAssetParameter>;
    }[]
  >([]);

  const formatTooltipTitle = useCallback(
    (date: Date) => formatDateTime(intl, date),
    [intl]
  );

  const formatXTick = useCallback(
    (date: Date) => intl.formatDate(date),
    [intl]
  );

  const formatValue = useCallback(
    (value: number, unit: string) => {
      return !!unit
        ? intl.formatMessage(
            {
              defaultMessage: "{value} {unit}",
              id: "detailpage.parameter_info.value"
            },
            {
              value: intl.formatNumber(value),
              unit
            }
          )
        : intl.formatNumber(value);
    },
    [intl]
  );

  const emptyTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "global.chart.no_data_selected",
        defaultMessage: "No data selected"
      }),
    [intl]
  );

  const titleTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.parameters.parameters_chart_modal.header",
        defaultMessage: "Parameter Trending"
      }),
    [intl]
  );

  useEffect(() => {
    selectedParameters?.forEach((s) => {
      const i = parameters.findIndex((p) => p.name === s.name);
      if (i < 0) {
        const url = getParameterUrl(assetId, s.name, s.parameterSource);
        const request = EndpointService.getJson<IAssetParameter>(
          url,
          (request, data) => {
            setParameters((parameters) => {
              const i = parameters.findIndex((p) => p.name === s.name);
              return update(parameters, {
                [i]: { data: { $set: new Data(request, data) } }
              });
            });
          },
          (request) => {
            setParameters((parameters) => {
              const i = parameters.findIndex((p) => p.name === s.name);
              return update(parameters, {
                [i]: { data: { $set: new Data(request) } }
              });
            });
          }
        );

        setParameters((parameters) =>
          update(parameters, {
            $push: [
              {
                name: s.name,
                data: new Data(request)
              }
            ]
          })
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameters]);

  const status = useMemo(() => {
    const statuses = parameters.map((p) => p.data.status);
    const isLoading = statuses.some((s) => s === Statuses.Loading);
    const isFailed = statuses.some((s) => s === Statuses.Failed);
    const isSucceeded = statuses.every((s) => s === Statuses.Succeeded);
    const noSelected = !selectedParameters?.length;
    const noData = selectedParameters?.some((s) =>
      parameters.some((p) => p.name === s.name)
    );

    if (noSelected) return null;
    if (!noData) return Statuses.Loading;

    return isLoading
      ? Statuses.Loading
      : isSucceeded
      ? Statuses.Succeeded
      : isFailed
      ? Statuses.Failed
      : null;
  }, [parameters, selectedParameters]);

  const series = useMemo(() => {
    return parameters
      ?.filter((p) => selectedParameters.some((s) => s.name === p.name))
      ?.map<ISeries>((p) => {
        const selectedParameter = selectedParameters.find(
          (s) => s.name === p.name
        );

        return {
          name: selectedParameter.name,
          displayName: selectedParameter.displayName,
          values: p.data.data?.Trend,
          unit: selectedParameter.unit
        };
      });
  }, [parameters, selectedParameters]);

  const lines = useMemo(() => {
    if (selectedParameters?.length !== 1) return;

    const lines = parameters
      ?.filter((p) => selectedParameters.some((s) => s.name === p.name))
      ?.map((p) => {
        return p.data.data?.Lines?.map<ILine>((l) => ({
          name: l.Name,
          displayName: l.Name,
          seriesName: p.name,
          color: l.Color,
          value: l.Value
        }));
      })
      .flat();

    return lines;
  }, [parameters, selectedParameters]);

  const regions = useMemo(() => {
    if (selectedParameters?.length !== 1) return;

    const regions = parameters
      ?.filter((p) => selectedParameters.some((s) => s.name === p.name))
      ?.map((p) => {
        return p.data.data?.Regions?.map<IRegion>((r) => ({
          name: r.Name,
          displayName: r.Name,
          seriesName: p.name,
          color: r.Color,
          start: r.Start,
          end: r.End
        }));
      })
      .flat();

    return regions;
  }, [parameters, selectedParameters]);

  return {
    formatXTick,
    formatValue,
    formatTooltipTitle,
    lines,
    regions,
    series,
    status,
    translations: {
      noData: emptyTranslation,
      title: titleTranslation
    }
  };
};

export default useParametersChart;
