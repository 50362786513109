// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Map } from "immutable";

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux/index";
import {
  Actions,
  IAddActiveIssueCommentAction,
  IChangeActiveIssueStatusAction,
  ICreateNewIssueAction,
  ISetActiveIssueCommentCreateRequestAction,
  ISetActiveIssueEditRequestAction,
  ISwitchIssueModeToEditAction,
  ISwitchIssueModeToViewAction,
  IUpdateActiveIssueAction
} from "../actions/IssuesActions";
import IIssue from "../models/IIssue";
import IssueMeta from "../models/IssueMeta";
import IssueModes from "../models/IssueModes";
import IssuesService from "../services/IssuesService";

export interface IIssues {
  newIssue: IIssue;
  newMeta: IssueMeta;
  activeIssues: Data<IIssue[]>;
  activeMetas: Map<string, IssueMeta>;
  closedIssues: Data<IIssue[]>;
  closedMetas: Map<string, IssueMeta>;
  statuses: Data<IssueStatuses[]>;
  urgencies: Data<string[]>;
}

export interface IState {
  issues: IIssues;
  processing: boolean;
}

export const defaultState: IState = {
  issues: {
    newIssue: null,
    newMeta: null,
    activeIssues: new Data(),
    activeMetas: Map(),
    closedIssues: new Data(),
    closedMetas: Map(),
    statuses: new Data(),
    urgencies: new Data()
  },
  processing: false
};

const issuesReducer: Reducer<IState> = (
  state = defaultState,
  action:
    | IAddActiveIssueCommentAction
    | IAjaxAction<string[]>
    | IAjaxAction<IIssue>
    | IAjaxAction<IIssue[]>
    | ICreateNewIssueAction
    | ISwitchIssueModeToEditAction
    | ISetActiveIssueCommentCreateRequestAction
    | ISetActiveIssueEditRequestAction
    | IUpdateActiveIssueAction
) => {
  switch (action.type) {
    case Actions.DetailPage_Issues_AddActiveIssueComment:
      state = IssuesService.addActiveIssueComment(
        state,
        (action as IAddActiveIssueCommentAction).issueId,
        (action as IAddActiveIssueCommentAction).request,
        (action as IAddActiveIssueCommentAction).data
      );
      return state;
    case Actions.DetailPage_Issues_CreateNewIssue:
      state = IssuesService.createNewIssue(
        state,
        (action as ICreateNewIssueAction).assetId,
        (action as ICreateNewIssueAction).issueId,
        (action as ICreateNewIssueAction).username
      );
      return state;
    case Actions.DetailPage_Issues_RemoveNewIssue:
      state = IssuesService.removeNewIssue(state);
      return state;
    case Actions.DetailPage_Issues_SaveIssue:
      state = IssuesService.saveIssue(
        state,
        (action as IAjaxAction<IIssue>).request,
        (action as IAjaxAction<IIssue>).data
      );
      return state;
    case Actions.DetailPage_Issues_SetActiveIssues:
      state = IssuesService.setActiveIssues(
        state,
        (action as IAjaxAction<IIssue[]>).request,
        (action as IAjaxAction<IIssue[]>).data
      );
      state = IssuesService.createActiveIssueMetas(state);
      return state;
    case Actions.DetailPage_Issues_SetActiveIssueCommentCreateRequest:
      state = IssuesService.setActiveIssueCommentCreateRequest(
        state,
        (action as ISetActiveIssueCommentCreateRequestAction).issueId,
        (action as ISetActiveIssueCommentCreateRequestAction).request,
        (action as ISetActiveIssueCommentCreateRequestAction).data
      );
      return state;
    case Actions.DetailPage_Issues_SetClosedIssues:
      state = IssuesService.setClosedIssues(
        state,
        (action as IAjaxAction<IIssue[]>).request,
        (action as IAjaxAction<IIssue[]>).data
      );
      state = IssuesService.createClosedIssueMetas(state);
      return state;
    case Actions.DetailPage_Issues_SetNewIssueCreateRequest:
      state = IssuesService.setNewIssueCreateRequest(
        state,
        (action as IAjaxAction<IIssue>).request,
        (action as IAjaxAction<IIssue>).data
      );
      return state;
    case Actions.DetailPage_Issues_SetActiveIssueEditRequest:
      state = IssuesService.setActiveIssueEditRequest(
        state,
        (action as ISetActiveIssueEditRequestAction).issueId,
        (action as ISetActiveIssueEditRequestAction).request,
        (action as ISetActiveIssueEditRequestAction).data
      );
      return state;
    case Actions.DetailPage_Issues_SetActiveIssueEditStatusRequest:
      state = IssuesService.setActiveIssueEditStatusRequest(
        state,
        (action as ISetActiveIssueEditRequestAction).issueId,
        (action as ISetActiveIssueEditRequestAction).request
      );
      return state;
    case Actions.DetailPage_Issues_ChangeActiveIssueStatus:
      state = IssuesService.changeActiveIssueStatus(
        state,
        (action as IChangeActiveIssueStatusAction).issueId,
        (action as IChangeActiveIssueStatusAction).status
      );
      return state;
    case Actions.DetailPage_Issues_SetStatuses:
      state = IssuesService.setStatuses(
        state,
        (action as IAjaxAction<IssueStatuses[]>).request,
        (action as IAjaxAction<IssueStatuses[]>).data
      );
      return state;
    case Actions.DetailPage_Issues_SetUrgencies:
      state = IssuesService.setUrgencies(
        state,
        (action as IAjaxAction<string[]>).request,
        (action as IAjaxAction<string[]>).data
      );
      return state;
    case Actions.DetailPage_Issues_SwitchIssueModeToEdit:
      state = IssuesService.switchIssueMode(
        state,
        (action as ISwitchIssueModeToEditAction).issueId,
        IssueModes.Edit
      );
      return state;
    case Actions.DetailPage_Issues_SwitchIssueModeToView:
      state = IssuesService.switchIssueMode(
        state,
        (action as ISwitchIssueModeToViewAction).issueId,
        IssueModes.View
      );
      return state;
    case Actions.DetailPage_Issues_UpdateIssue:
      state = IssuesService.setActiveIssue(
        state,
        (action as IUpdateActiveIssueAction).issue
      );
      return state;

    case Actions.DetailPage_Issues_Start_Processing: {
      return {
        ...state,
        processing: true
      };
    }

    case Actions.DetailPage_Issues_End_Processing: {
      return {
        ...state,
        processing: false
      };
    }
    default:
      return state;
  }
};

export default issuesReducer;
