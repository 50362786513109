// Copyright 2016-2023 Hitachi Energy. All rights reserved.

enum EFluidType {
  ANY_SILICONE = "any_silicone",
  MIDEL_7131 = "midel_7131",
  OTHER_SYNTHETIC_ESTER = "other_synthetic_ester",
  FR3 = "fr3",
  MIDEL_EN_1215 = "midel_en_1215",
  OTHER_NATURAL_ESTER_SOY = "other_natural_ester_soy",
  BIOTEMP = "biotemp",
  MIDEL_EN_1204 = "midel_en_1204",
  OTHER_NATURAL_ESTER_SEED = "other_natural_ester_seed"
}

export default EFluidType;
