// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { spacingXXLarge } from "styles/StyleVariables";

interface IItemsCountProps {
  className?: string;
  value: number;
  onClick: (e: React.MouseEvent) => void;
}

const ItemsCount = ({ className, value, onClick }: IItemsCountProps) => (
  <a className={`${className} link-label`} href="#/" onClick={onClick}>
    {value}
  </a>
);

const defaultGridPadding = "10px";

export default styled(ItemsCount)`
  padding: ${defaultGridPadding};
  display: block;
  line-height: ${spacingXXLarge};
`;
