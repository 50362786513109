// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { Button, Typography } from "antd";
import { Checkbox } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colorStatusRed, colorStatusYellow } from "styles/ColorVariables";
import { spacingLarge } from "styles/StyleVariables";

const { Title } = Typography;

interface IGridsHeaderProps {
  parametersCount: number;
  errorsCount: number;
  warningsCount: number;
  className?: string;
  showOnlyInvalid: boolean;
  onShowOnlyInvalid: (showOnlyInvalid: boolean) => void;
  onVerifyData: () => void;
}

const GridsHeader = ({
  parametersCount,
  errorsCount,
  warningsCount,
  showOnlyInvalid,
  onShowOnlyInvalid,
  onVerifyData,
  className
}: IGridsHeaderProps) => {
  return (
    <div className={className}>
      <div className="messages-row">
        <div className="messages-box">
          {errorsCount > 0 && (
            <div className="invalid-parameters-info header-message">
              <Icon name="report_problem" />
              <FormattedMessage
                id="bulk_inspection.invalid_fields_in_the_template"
                defaultMessage="There are invalid fields in the template. Data cannot be submitted."
              />
            </div>
          )}
          {warningsCount > 0 && (
            <div className="parameters-warning-info header-message">
              <Icon name="info" />
              <FormattedMessage
                id="bulk_inspection.parameters_warning"
                defaultMessage="Some input names do not match available APM parameters."
              />
            </div>
          )}
        </div>
        <div className="verify-data-box">
          <Button type="default" className="float-right" onClick={onVerifyData}>
            <Icon name="replay" />
            <FormattedMessage
              defaultMessage="Verify Data"
              id="bulk_inspection.verify_data"
            />
          </Button>
        </div>
      </div>
      <div className="grid-header">
        <Title level={4} className="section-name">
          {warningsCount ? (
            <FormattedMessage
              id="bulk_inspection.rows_count_with_warnings"
              defaultMessage="Rows / {parametersCount} imported ({errorsCount} errors, {warningsCount} warnings) "
              values={{
                parametersCount,
                errorsCount,
                warningsCount
              }}
            />
          ) : (
            <FormattedMessage
              id="bulk_inspection.rows_count"
              defaultMessage="Rows / {parametersCount} imported ({errorsCount} errors) "
              values={{
                parametersCount,
                errorsCount
              }}
            />
          )}
        </Title>
        <div>
          <Checkbox
            checked={showOnlyInvalid}
            onChange={(e) => {
              onShowOnlyInvalid(e.target.checked);
            }}
            data-qa="show-errors-only"
          >
            <FormattedMessage
              defaultMessage="Show errors only"
              id="bulk_inspection.show_errors_only"
            />
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

const StyledGridsHeader = styled(GridsHeader)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .messages-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${spacingLarge};

    .header-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: ${spacingLarge};
      > * + * {
        margin-left: 1ch;
      }
    }

    .invalid-parameters-info {
      color: ${colorStatusRed};
    }

    .parameters-warning-info {
      color: ${colorStatusYellow};
    }
  }

  .grid-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default StyledGridsHeader;
