import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import { Link } from "react-router-dom";

export default function FamilyContentLink(props: {
  value: string;
  navigateTo: { pathname: string; search: string };
}) {
  const { value, navigateTo } = props;
  return (
    <div className="grid-cell-content">
      <TooltipWrapper Tooltip={() => <div>{value}</div>} theme={Themes.Dark}>
        <Link data-qa="link" className="link-label" to={navigateTo}>
          {value}
        </Link>
      </TooltipWrapper>
    </div>
  );
}
