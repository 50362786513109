// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  IDataGridProps,
  IOnCellChangedConfig
} from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import IEditor from "@apm/widgets/build/widgets/DataGrid/models/IEditor";
import { hexToRgb } from "@pg/common";
import Icon from "@pg/common/build/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import { Button, Form, Tabs, Tooltip, Typography, Upload } from "antd";
import AssetModalContext from "features/ConfigurationTool/contexts/AssetModalContext";
import FormContext from "features/ConfigurationTool/contexts/FormContext";
import { useConnectedDevices } from "features/ConfigurationTool/hooks/connectedDevices/useConnectedDevices";
import { IFormItem } from "features/ConfigurationTool/hooks/useForms";
import { IConfigurableRegisterConfiguration } from "features/ConfigurationTool/models/connectedDevices/IConfigurableConnectedDevice";
import IConnectedDevice from "features/ConfigurationTool/models/connectedDevices/IConnectedDevice";
import IRegisterConfiguration from "features/ConfigurationTool/models/connectedDevices/IRegisterConfiguration";
import IFormValues from "features/ConfigurationTool/models/IFormValues";
import isSelectOption from "features/ConfigurationTool/utils/isSelectOption";
import { isNil } from "lodash";
import IField from "models/IField";
import dayjs from "dayjs";
import { Tab } from "rc-tabs/lib/interface";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorGray0,
  colorGray20,
  colorGray80,
  colorStatusRed,
  colorTeal100,
  colorTeal90,
  colorTextPrimaryOnLight
} from "styles/ColorVariables";
import {
  fontSizeMedium,
  fontSizeSmall,
  spacingLarge,
  spacingMedium,
  spacingSmall,
  spacingXLarge,
  spacingXSmall,
  spacingXXLarge,
  spacingXXXLarge
} from "styles/StyleVariables";
import IFieldData from "../../models/IFieldData";
import CustomSections, { ICustomSectionsProps } from "../CustomSections";
import AddNewParameter from "./AddNewParameter";
import ImportConnectedDevices from "./ImportConnectedDevices";
import ImportParametersMapping from "./ImportParametersMapping";

const { Text } = Typography;

interface IConnectedDevicesProps {
  formInfo: IFormItem;
  className?: string;
}

type GeneralInformationConfigType = keyof Omit<
  IConnectedDevice,
  "RegisterConfiguration"
>;

const MainWrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 0 ${spacingXXXLarge};
  display: flex;
  flex-direction: column;
  row-gap: ${spacingXXLarge};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${spacingXLarge};
`;

const HeaderInfo = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: ${spacingXLarge};

  .header-info-label {
    font-size: ${fontSizeMedium};
  }
`;

const SubItemsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  column-gap: ${spacingLarge};
`;

const ManageDeviceIconsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: ${spacingLarge};
  display: flex;
  align-items: center;
  column-gap: ${spacingMedium};
`;

const AddDeviceButton = styled(Button)`
  display: flex;
  align-items: center;
  column-gap: ${spacingSmall};
  background-color: ${colorGray0};
  border: 1px solid ${colorTeal90};
  padding: ${spacingSmall} ${spacingLarge};
  border-radius: ${spacingXSmall};
  cursor: pointer;
  & span {
    color: ${colorTeal90};
  }

  &:hover:not(&:disabled) {
    background-color: ${hexToRgb(colorTeal90, 0.1)};
  }
`;

const DeleteDeviceButton = styled(Button)`
  display: flex;
  align-items: center;
  border: 1px solid ${colorStatusRed};
  background-color: ${colorGray0};
  padding: ${spacingSmall};
  border-radius: ${spacingXSmall};
  cursor: pointer;

  &:hover:not(&:disabled) {
    background-color: ${hexToRgb(colorStatusRed, 0.1)};
  }
`;

const JsonManageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${spacingSmall};
  margin-left: auto;

  .export-as-json-btn,
  .import-json-btn {
    &:focus {
      border-color: ${colorGray20};
    }
    .export-as-json-text {
      height: 100%;
    }
    .ant-btn-disabled span {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const TabNameHeader = styled.span`
  display: flex;
  align-items: center;
  column-gap: ${spacingLarge};
  color: ${colorGray80};
  .tab-touched-icon {
    color: ${colorTeal100};
  }

  .tab-invalid-icon {
    color: ${colorStatusRed};
  }
`;

const EditableCellsInfoMessageWrapper = styled.div`
  display: flex;
  column-gap: ${spacingSmall};
  align-items: center;
  font-size: ${fontSizeSmall};
  color: ${colorGray80};
`;

const ConnectedDevices: FC<IConnectedDevicesProps> = ({
  formInfo,
  className
}) => {
  const { formatMessage } = useIntl();

  const form = Form.useForm<IFormValues>(formInfo?.form)[0];

  const {
    validateMessages,
    registerForm,
    connectedDevices,
    changeConnectedDeviceGeneralInformationField,
    changeTabsInfo,
    connectedDeviceLastUpdatedDate,
    setConnectedDevices,
    modelMappedParameterNames,
    connectedDeviceAction,
    setConnectedDeviceAction,
    updateFormItemValidationInfo,
    mapToConnectedDevicesPayload,
    assetId,
    isReadOnlyMode
  } = useContext(AssetModalContext);

  const {
    activeTabKey,
    handleTabChange,
    activeConnectedDeviceData,
    activeSectionConfig,
    connectedDevicesTabsInfo,
    addNewParameterModalVisible,
    setAddNewParameterModalVisible,
    highlightAfterUpdatedRows,
    setHighlightAfterUpdatedRows,
    setValidationResultTab,
    connectedDevicesMaxLimit,
    exportAsJsonDisabledMessage,
    onJsonFileUpload,
    importConnectedDevicesModalOpen,
    setImportConnectedDevicesModalOpen,
    uploadedData,
    parameterMappingGridConfiguration,
    importedParametersMappingModalOpen,
    setImportedParametersMappingModalOpen,
    handleParametersFileUpload,
    importedParametersFromCsv,
    mapRegisterFunctionCodeToLabel,
    mapRegisterFunctionCodeLabelToCode
  } = useConnectedDevices({
    formInfo,
    connectedDevices,
    setConnectedDevices,
    changeTabsInfo,
    connectedDeviceAction,
    setConnectedDeviceAction,
    modelMappedParameterNames,
    registerForm,
    form
  });

  const getInitialValue = useCallback(
    (fieldName: GeneralInformationConfigType) => {
      return activeConnectedDeviceData[fieldName];
    },
    [activeConnectedDeviceData]
  );

  const setValidationTabWhenRegisterConfigChanged = useCallback(
    ({
      modifiedCellValid,
      modifiedCellName,
      rowId
    }: {
      modifiedCellName: string;
      modifiedCellValid: boolean;
      rowId: number;
    }) => {
      const allRowsWithoutModifiedCell =
        activeConnectedDeviceData?.RegisterConfiguration?.map((row) => {
          if (row.id === rowId) {
            return {
              ...row,
              cells: row.cells.filter(
                ({ cellName }) => cellName !== modifiedCellName
              )
            };
          }

          return row;
        });

      const isRestValid = allRowsWithoutModifiedCell.every(({ cells }) =>
        cells.every(({ valid }) => valid)
      );

      formInfo?.form?.validateFields().then(() => {
        setValidationResultTab({
          valid: isRestValid && modifiedCellValid,
          modified: true
        });
      });
    },
    [
      activeConnectedDeviceData?.RegisterConfiguration,
      formInfo?.form,
      setValidationResultTab
    ]
  );

  const handleGeneralInformationFieldsChange = useCallback(
    (changedFields: IFieldData[], allFields: IFieldData[]) => {
      changedFields.forEach((field) => {
        const { name, value } = field;

        if (!isNil(value)) {
          changeConnectedDeviceGeneralInformationField({
            fieldName: name[0],
            fieldValue: value
          });
        }
      });

      const isTouched =
        allFields.some((field) => field.touched) &&
        activeConnectedDeviceData?.RegisterConfiguration.every(({ cells }) =>
          cells.every(({ valid }) => valid)
        );

      if (isTouched) {
        const isError = allFields.some((field) => field.errors?.length > 0);

        setValidationResultTab({
          valid: !isError,
          modified: isTouched
        });
      }
    },
    [
      activeConnectedDeviceData?.RegisterConfiguration,
      changeConnectedDeviceGeneralInformationField,
      setValidationResultTab
    ]
  );

  const configurableGridButtons = useMemo(() => {
    return (
      <>
        <EditableCellsInfoMessageWrapper>
          <Icon name="info" variant="outlined" />
          {formatMessage({
            id: "configuration_tool.tab.connected_devices_edit_cell_info",
            defaultMessage: "Double-Click Cell To Edit"
          })}
        </EditableCellsInfoMessageWrapper>

        <Upload
          data-qa="import-csv-button"
          accept=".csv"
          showUploadList={false}
          beforeUpload={handleParametersFileUpload}
        >
          <Button className="import-csv-button" disabled={isReadOnlyMode}>
            <Icon name="add" />
            <Text strong={true}>
              {formatMessage({
                id: "configuration_tool.tab.connected_devices_import_csv",
                defaultMessage: "Import CSV"
              })}
            </Text>
          </Button>
        </Upload>

        <Button
          className="add-parameter-button"
          onClick={() => {
            setAddNewParameterModalVisible(true);
          }}
          disabled={isReadOnlyMode}
        >
          <Icon name="add" />
          <Text strong={true}>
            {formatMessage({
              id: "configuration_tool.tab.connected_devices_add_parameter",
              defaultMessage: "Add Parameter"
            })}
          </Text>
        </Button>
      </>
    );
  }, [
    formatMessage,
    handleParametersFileUpload,
    isReadOnlyMode,
    setAddNewParameterModalVisible
  ]);

  const setHighlightAfterAddedParameter = useCallback(() => {
    setHighlightAfterUpdatedRows(true);
    setValidationResultTab({
      valid: !formInfo?.tabs?.find(({ tabId }) => tabId === activeTabKey)
        ?.invalid,
      modified: true
    });

    setTimeout(() => {
      setHighlightAfterUpdatedRows(false);
    }, 1000);
  }, [
    activeTabKey,
    formInfo?.tabs,
    setHighlightAfterUpdatedRows,
    setValidationResultTab
  ]);

  const addToRegisterConfiguration = useCallback(
    (values: IRegisterConfiguration) => {
      setConnectedDevices((connectedDevices) =>
        [
          ...connectedDevices.filter(
            ({ Guid }) => Guid !== activeConnectedDeviceData.Guid
          ),
          {
            ...activeConnectedDeviceData,
            RegisterConfiguration: [
              ...activeConnectedDeviceData.RegisterConfiguration.map(
                (config) => ({
                  ...config,
                  added: false,
                  isNew: false
                })
              ),
              {
                id: activeConnectedDeviceData.RegisterConfiguration.length,
                added: true,
                isNew: true,
                cells: Object.entries(values).map(([key, val]) => {
                  return {
                    cellName: key as keyof IRegisterConfiguration,
                    cellValue: val,
                    modified: false,
                    valid: true
                  };
                }, {})
              }
            ]
          }
        ].sort((a, b) => a.Order - b.Order)
      );

      setHighlightAfterAddedParameter();
    },
    [
      activeConnectedDeviceData,
      setHighlightAfterAddedParameter,
      setConnectedDevices
    ]
  );

  const deleteRowFromRegisterConfiguration = useCallback(
    (id: number) => {
      setConnectedDevices((connectedDevices) =>
        [
          ...connectedDevices.filter(
            ({ Guid }) => Guid !== activeConnectedDeviceData.Guid
          ),
          {
            ...activeConnectedDeviceData,
            RegisterConfiguration: [
              ...activeConnectedDeviceData.RegisterConfiguration.filter(
                (field) => field.id !== id
              ).map((field, idx) => ({
                ...field,
                id: idx
              }))
            ]
          }
        ].sort((a, b) => a.Order - b.Order)
      );
    },
    [activeConnectedDeviceData, setConnectedDevices]
  );

  const deleteParameterConfirm = useCallback(
    (id: number) => {
      notifications.confirm({
        title: formatMessage({
          id: "configuration_tool.tab.connected_devices_parameter_mapping_delete_parameter_warn",
          defaultMessage: "Are you sure you want to delete the parameter?"
        }),
        okText: formatMessage({
          id: "configuration_tool.tab.connected_devices_parameter_mapping_delete_parameter_submit",
          defaultMessage: "Delete"
        }),
        cancelText: formatMessage({
          id: "configuration_tool.tab.connected_devices_parameter_mapping_delete_parameter_cancel",
          defaultMessage: "Cancel"
        }),
        onOk: () => {
          deleteRowFromRegisterConfiguration(id);
          setValidationResultTab({
            valid: !formInfo?.tabs?.find(({ tabId }) => tabId === activeTabKey)
              ?.invalid,
            modified: true
          });
        },
        className: "delete-parameter-confirmation-modal"
      });
    },
    [
      activeTabKey,
      deleteRowFromRegisterConfiguration,
      formInfo?.tabs,
      formatMessage,
      setValidationResultTab
    ]
  );

  const onCellChanged = useCallback(
    ({
      cellName,
      newValue,
      rowId,
      valid,
      modified,
      validationMessage
    }: IOnCellChangedConfig) => {
      setConnectedDevices((connectedDevices) =>
        [
          ...connectedDevices.filter(
            ({ Guid }) => Guid !== activeConnectedDeviceData.Guid
          ),
          {
            ...activeConnectedDeviceData,
            RegisterConfiguration:
              activeConnectedDeviceData.RegisterConfiguration.map(
                (config): IConfigurableRegisterConfiguration =>
                  config.id === rowId
                    ? {
                        ...config,
                        cells: config.cells.map((cell) => {
                          if (cell.cellName === cellName) {
                            return {
                              ...cell,
                              cellValue:
                                cellName === "RegisterFunctionCode" &&
                                typeof newValue !== "number"
                                  ? mapRegisterFunctionCodeLabelToCode(
                                      newValue as string
                                    )
                                  : newValue,
                              valid,
                              modified,
                              validationMessage
                            };
                          }

                          return cell;
                        })
                      }
                    : config
              )
          }
        ].sort((a, b) => a.Order - b.Order)
      );

      setValidationTabWhenRegisterConfigChanged({
        modifiedCellName: cellName,
        modifiedCellValid: valid,
        rowId
      });
    },
    [
      activeConnectedDeviceData,
      mapRegisterFunctionCodeLabelToCode,
      setConnectedDevices,
      setValidationTabWhenRegisterConfigChanged
    ]
  );

  const mapToEditorType = useCallback(
    (inputType: IField["inputType"]): IEditor => {
      switch (inputType) {
        case "String": {
          return "Text";
        }
        case "Decimal": {
          return "Number";
        }
        case "DateTime": {
          return "Date";
        }
        case "list": {
          return "Select";
        }
        case "Bool": {
          return "Select";
        }
        case "autoComplete": {
          return "AutoComplete";
        }
      }
    },
    []
  );

  const mappedToDataGridConfig =
    useMemo((): ICustomSectionsProps<IRegisterConfiguration>["gridConfig"] => {
      return {
        gridHeaderTitle: formatMessage({
          id: parameterMappingGridConfiguration.sectionName.id,
          defaultMessage:
            parameterMappingGridConfiguration.sectionName.defaultMessage
        }),
        data: activeConnectedDeviceData?.RegisterConfiguration.map((field) => ({
          id: field.id,
          added: field?.added,
          cells: field.cells
        })),
        columns: parameterMappingGridConfiguration.fields.map(
          ({
            fieldKey,
            fieldName,
            inputType,
            autoCompleteOptions,
            listValues,
            validationRules
          }): IDataGridProps<IRegisterConfiguration>["columns"][0] => {
            return {
              field: fieldKey as keyof IRegisterConfiguration,
              headerName: formatMessage({
                id: fieldName.id,
                defaultMessage: fieldName.defaultMessage
              }),
              editable: !isReadOnlyMode,
              resizable: true,
              editType: mapToEditorType(inputType),
              options:
                (isSelectOption(listValues) && listValues) ||
                autoCompleteOptions,
              cellValidationRules: validationRules
            };
          }
        ),
        gridHeaderSection: configurableGridButtons,
        onRowDelete: deleteParameterConfirm,
        highlightAfterUpdatedRows,
        onCellChanged,
        dataQa: "parameter-mapping-section",
        disabledRowsDeletion: isReadOnlyMode
      };
    }, [
      formatMessage,
      parameterMappingGridConfiguration.sectionName.id,
      parameterMappingGridConfiguration.sectionName.defaultMessage,
      parameterMappingGridConfiguration.fields,
      activeConnectedDeviceData?.RegisterConfiguration,
      configurableGridButtons,
      deleteParameterConfirm,
      highlightAfterUpdatedRows,
      onCellChanged,
      isReadOnlyMode,
      mapToEditorType
    ]);

  const subTabs = useMemo(() => {
    return (
      connectedDevices &&
      activeConnectedDeviceData && (
        <Tabs
          activeKey={activeTabKey}
          onChange={(key: string) => handleTabChange(key)}
          items={connectedDevices
            ?.sort((a, b) => a.Order - b.Order)
            .map<Tab>(({ DeviceName, Guid }) => {
              const shouldErrorCircle = formInfo.tabs?.find(
                (tabInfo) => tabInfo.tabId === Guid
              )?.invalid;

              const shouldTouchedCircle = formInfo.tabs?.find(
                (tabInfo) => tabInfo.tabId === Guid
              )?.touched;

              return {
                key: Guid,
                label: (
                  <TabNameHeader>
                    {String(DeviceName) ||
                      formatMessage({
                        id: "configuration_tool.tab.connected_devices_unknown",
                        defaultMessage: "Unknown"
                      })}
                    {shouldErrorCircle && (
                      <Icon
                        className="tab-invalid-icon"
                        name="circle"
                        size="xs"
                      />
                    )}
                    {shouldTouchedCircle && !shouldErrorCircle && (
                      <Icon
                        className="tab-touched-icon"
                        name="circle"
                        size="xs"
                      />
                    )}
                  </TabNameHeader>
                ),
                children:
                  Guid === activeTabKey ? (
                    <CustomSections<IRegisterConfiguration>
                      key={Guid}
                      configuration={activeSectionConfig}
                      getInitialValue={getInitialValue}
                      gridConfig={mappedToDataGridConfig}
                    />
                  ) : null
              };
            })}
        />
      )
    );
  }, [
    connectedDevices,
    activeConnectedDeviceData,
    activeTabKey,
    handleTabChange,
    formInfo.tabs,
    formatMessage,
    activeSectionConfig,
    getInitialValue,
    mappedToDataGridConfig
  ]);

  const setFormItemValidationOnDeleteDevice = useCallback(() => {
    const isError = formInfo?.tabs
      .filter(({ tabId }) => tabId !== activeTabKey)
      ?.some(({ invalid }) => invalid);

    if (isError) {
      updateFormItemValidationInfo({
        formKey: formInfo.formKey,
        touched: true,
        invalid: true
      });
    } else {
      updateFormItemValidationInfo({
        formKey: formInfo.formKey,
        touched: true,
        invalid: false
      });
    }
  }, [
    activeTabKey,
    formInfo.formKey,
    formInfo?.tabs,
    updateFormItemValidationInfo
  ]);

  const handleDeleteDevice = useCallback(() => {
    setFormItemValidationOnDeleteDevice();
    setConnectedDevices((connectedDevices) => {
      return connectedDevices
        .filter(({ Guid }) => Guid !== activeTabKey)
        .map((device, idx) => ({
          ...device,
          Order: idx
        }))
        .sort((a, b) => a.Order - b.Order);
    });

    setConnectedDeviceAction("delete");
  }, [
    activeTabKey,
    setConnectedDeviceAction,
    setConnectedDevices,
    setFormItemValidationOnDeleteDevice
  ]);

  const deleteDeviceConfirm = useCallback(() => {
    notifications.confirm({
      title: formatMessage({
        id: "configuration_tool.tab.connected_devices_delete_device_warn",
        defaultMessage: "Are you sure you want to delete the device?"
      }),
      okText: formatMessage({
        id: "configuration_tool.action.delete",
        defaultMessage: "Delete"
      }),
      cancelText: formatMessage({
        id: "configuration_tool.action.cancel",
        defaultMessage: "Cancel"
      }),
      onOk: () => {
        handleDeleteDevice();
      },
      className: "delete-device-confirmation-modal"
    });
  }, [formatMessage, handleDeleteDevice]);

  const handleAddNewDevice = useCallback(() => {
    setConnectedDevices((connectedDevices) =>
      [
        ...connectedDevices,
        {
          DeviceType: "",
          DeviceName: "",
          DeviceHost: "",
          DevicePort: null,
          UnitId: null,
          RegisterAddressOffset: null,
          RegisterConfiguration: [],
          Guid: `${performance.now()}${Math.random()
            .toString()
            .slice(5)}`.replace(".", ""),
          Order: connectedDevices.length + 1
        }
      ].sort((a, b) => a.Order - b.Order)
    );

    setConnectedDeviceAction("add");
  }, [setConnectedDeviceAction, setConnectedDevices]);

  useEffect(() => {
    if (connectedDeviceAction !== "upload") {
      registerForm(
        formInfo.formConfiguration.formKey,
        form,
        connectedDevicesTabsInfo
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, connectedDevicesTabsInfo.length]);

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [formInfo.invalid, activeTabKey, form]);

  const isDevicesLimitExceed = useMemo(
    () => connectedDevices.length >= connectedDevicesMaxLimit,
    [connectedDevices.length, connectedDevicesMaxLimit]
  );

  return (
    <MainWrapper className={className}>
      <Form
        form={form}
        layout="horizontal"
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
        onFieldsChange={handleGeneralInformationFieldsChange}
        key={formInfo.formConfiguration.formKey}
      >
        <FormContext.Provider value={form}>
          <Header>
            {activeTabKey && (
              <HeaderInfo data-qa="connected-devices-header-info">
                <Text strong={true} className="header-info-label">
                  {formatMessage({
                    defaultMessage: "Connected Devices",
                    id: "configuration_tool.tab.connected_devices_header"
                  })}
                </Text>
                {connectedDeviceLastUpdatedDate && (
                  <>
                    <Text className="date-of-last-modification-header">
                      {`${formatMessage({
                        id: "configuration_tool.tab.connected_devices_last_updated_date",
                        defaultMessage: "Date Of Last Modification"
                      })}:`}
                    </Text>
                    <Text>
                      <FormattedDate
                        value={dayjs(connectedDeviceLastUpdatedDate).toDate()}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                        hour="numeric"
                        minute="numeric"
                        second="numeric"
                      />
                    </Text>
                  </>
                )}
              </HeaderInfo>
            )}

            <JsonManageWrapper>
              <Upload
                data-qa="import-json-button"
                accept="application/JSON"
                showUploadList={false}
                beforeUpload={onJsonFileUpload}
              >
                <Button className="import-json-btn" disabled={isReadOnlyMode}>
                  <Text strong={true}>
                    {formatMessage({
                      id: "configuration_tool.tab.connected_devices_import_json",
                      defaultMessage: "Import JSON"
                    })}
                  </Text>
                </Button>
              </Upload>
              <Tooltip title={exportAsJsonDisabledMessage ?? undefined}>
                <Button
                  disabled={!!exportAsJsonDisabledMessage || isReadOnlyMode}
                  data-qa="export-as-json-button"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(
                      mapToConnectedDevicesPayload(connectedDevices)
                    )
                  )}`}
                  download={`${assetId}-ConnectedDevices.json`}
                  className="export-as-json-btn"
                >
                  <Text strong={true} className="export-as-json-text">
                    {formatMessage({
                      id: "configuration_tool.tab.connected_devices_export_as_json",
                      defaultMessage: "Export as JSON"
                    })}
                  </Text>
                </Button>
              </Tooltip>
            </JsonManageWrapper>
          </Header>
          <SubItemsWrapper>
            {subTabs}
            <ManageDeviceIconsWrapper>
              <Tooltip
                title={
                  isDevicesLimitExceed
                    ? formatMessage({
                        id: "configuration_tool.tab.connected_devices_add_new_device_max_limit",
                        defaultMessage:
                          "The maximum number of Connected Devices has been reached"
                      })
                    : undefined
                }
              >
                <AddDeviceButton
                  data-qa="add-new-device-button"
                  onClick={handleAddNewDevice}
                  disabled={isDevicesLimitExceed || isReadOnlyMode}
                >
                  <Icon name="pg-drivers-add" />
                  <Text>
                    {formatMessage({
                      id: "configuration_tool.tab.connected_devices_add_new_device",
                      defaultMessage: "Add new device"
                    })}
                  </Text>
                </AddDeviceButton>
              </Tooltip>

              {activeConnectedDeviceData && (
                <Tooltip
                  title={`${formatMessage({
                    id: "configuration_tool.action.delete",
                    defaultMessage: "delete"
                  })} ${activeConnectedDeviceData?.DeviceName}`}
                >
                  <DeleteDeviceButton
                    data-qa="delete-device-button"
                    onClick={deleteDeviceConfirm}
                    disabled={isReadOnlyMode}
                  >
                    <Icon
                      name="delete"
                      variant="outlined"
                      style={{ color: colorStatusRed }}
                    />
                  </DeleteDeviceButton>
                </Tooltip>
              )}
            </ManageDeviceIconsWrapper>
            <AddNewParameter
              visible={addNewParameterModalVisible}
              onCancel={() => {
                setAddNewParameterModalVisible(false);
              }}
              onSave={addToRegisterConfiguration}
              activeConnectedDeviceData={activeConnectedDeviceData}
              fieldsConfig={parameterMappingGridConfiguration.fields}
            />
            <ImportConnectedDevices
              visible={importConnectedDevicesModalOpen}
              onCancel={() => {
                setImportConnectedDevicesModalOpen(false);
              }}
              uploadedData={uploadedData}
            />
            <ImportParametersMapping
              visible={importedParametersMappingModalOpen}
              onCancel={() => {
                setImportedParametersMappingModalOpen(false);
              }}
              activeConnectedDeviceData={activeConnectedDeviceData}
              parameterMappingGridConfiguration={
                parameterMappingGridConfiguration
              }
              setValidationResultTab={setValidationResultTab}
              importedParameters={importedParametersFromCsv}
              mapRegisterFunctionCodeToLabel={mapRegisterFunctionCodeToLabel}
              setHighlightAfterUpdatedRows={setHighlightAfterUpdatedRows}
            />
          </SubItemsWrapper>
        </FormContext.Provider>
      </Form>
    </MainWrapper>
  );
};

export default styled(ConnectedDevices)`
  .tab-header-wrapper {
    display: flex;
    align-items: center;
    column-gap: ${spacingSmall};
  }

  .date-of-last-modification-header {
    text-transform: uppercase;
  }

  .import-csv-button,
  .add-parameter-button {
    display: flex;
    align-items: center;
    column-gap: ${spacingMedium};

    &:focus {
      border-color: ${colorGray20};
      span {
        color: ${colorTextPrimaryOnLight};
      }
    }
  }
`;
