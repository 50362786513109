// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export enum Error {
  "COMMENT_IS_MANDATORY" = "COMMENT_IS_MANDATORY",
  "ISSUE_NOT_FOUND" = "NOT_FOUND_ISSUE_TO_EDIT",
  "ISSUE_MODIFIED" = "UNABLE_TO_CHANGE_STATUS_WHEN_MODIFIED",
  "ISSUE_HAS_THE_SAME_STATUS" = "UNABLE_TO_CHANGE_STATUS_TO_THE_SAME_ONE",
  "ASSET_STATUS_IS_REMOVED" = "UNABLE_TO_CHANGE_ISSUE_STATUS_WHEN_ASSET_IS_REMOVED",
  "ISSUE_STATUS_IS_CLOSED" = "UNABLE_TO_CHANGE_STATUS_WHEN_ISSUE_STATUS_IS_CLOSED"
}

type IErrors = {
  [key in Error]: string[];
};

export type IUpdateIssueStatusObjectResponse = {
  Errors: IErrors;
  SuccessCount: 0;
};

export type IUpdateIssueStatusSuccessResponse = string;

export type IUpdateIssueStatusWarningResponse =
  IUpdateIssueStatusObjectResponse;

type IUpdateIssueStatusErrorResponse =
  | IUpdateIssueStatusObjectResponse
  | Error.COMMENT_IS_MANDATORY;

type IUpdateIssueStatusResponse =
  | IUpdateIssueStatusSuccessResponse
  | IUpdateIssueStatusErrorResponse
  | IUpdateIssueStatusWarningResponse;

export default IUpdateIssueStatusResponse;
