// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";

function getAxisXLine(
  firstDate: Date,
  lastDate: Date,
  installDate?: string
): string | undefined {
  const dateValue = new Date(installDate);
  const isDateSameOrBeforeLastDate =
    dayjs(dateValue).isBefore(lastDate) ||
    dateValue.toLocaleDateString() === lastDate.toLocaleDateString();
  const isDateSameOrAfterFirstDate =
    dayjs(dateValue).isAfter(firstDate) ||
    dateValue.toLocaleDateString() === firstDate.toLocaleDateString();

  if (installDate && isDateSameOrBeforeLastDate && isDateSameOrAfterFirstDate) {
    return installDate;
  }
}

export default getAxisXLine;
