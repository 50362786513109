// Copyright 2016-2023 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import useNavToAsset from "hooks/useNavToAsset";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { fontSizeSmall, spacingSmall } from "styles/StyleVariables";

interface IAssetWithLocationColumnProps {
  assetId: string;
  location?: string;
  className?: string;
}

const AssetWithLocationColumn = ({
  assetId,
  location,
  className
}: IAssetWithLocationColumnProps) => {
  const { navigateTo } = useNavToAsset();

  return (
    <div className={className}>
      <TooltipWrapper Tooltip={() => <div>{assetId}</div>} theme={Themes.Dark}>
        <Link
          data-qa="link"
          className="anchor id link-label"
          to={navigateTo(assetId)}
        >
          {assetId}
        </Link>
      </TooltipWrapper>
      {location && <div className="details">{location}</div>}
    </div>
  );
};

const gridPadding = "10px";

export default styled(AssetWithLocationColumn)`
  padding: ${gridPadding};

  .id {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .details {
    margin-top: ${spacingSmall};
    font-size: ${fontSizeSmall};
  }
`;
