// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import { Button, Layout, Spin, Typography } from "antd";
import { isNil } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorGray0,
  colorGray40,
  colorWidgetBorder
} from "styles/ColorVariables";
import {
  fontSizeMedium,
  spacingMedium,
  spacingXLarge,
  spacingXSmall,
  spacingXXLarge
} from "styles/StyleVariables";
import AssetNameplateContext from "../contexts/AssetModalContext";
import { IFormItem } from "../hooks/useForms";
import DataTypes from "../models/DataTypes";
import FormTypes from "../models/FormTypes";
import AssetModalForms from "./AssetModalForms";
import AssetModalMenu from "./AssetModalMenu";
import CustomParameters from "./CustomParameters";
import Inspection from "./Inspection";
import Nameplate from "./Nameplate";
import TabbedInspection from "./TabbedInspection";
import ConnectedDevices from "./connectedDevices/ConnectedDevices";

const { Header, Footer, Content, Sider } = Layout;
const { Title } = Typography;

interface IAssetModalContentProps {
  className?: string;
  onCancel?: () => void;
}

const AssetModalContent = ({
  className,
  onCancel
}: IAssetModalContentProps) => {
  const intl = useIntl();
  const [isSubmittingInProgress, setIsSubmittingInProgress] = useState(false);

  const {
    assetId,
    isEditMode,
    menuItems,
    selectedMenu,
    blockActions,
    saveInspection,
    changeSelectedMenu,
    resetData,
    getFormInfo,
    validForms,
    saveAssetNameplate,
    isDataTypeTouched,
    saveConnectedDevices
  } = useContext(AssetNameplateContext);

  const isJson = useCallback((str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }, []);

  const getErrorMessagesToTranslate = useCallback(
    (error: string): string[] => {
      if (isJson(error)) {
        const errorObj = JSON.parse(error);
        if (typeof errorObj === "string" && errorObj) {
          return [errorObj];
        } else if (typeof errorObj === "object" && errorObj !== null) {
          if (
            errorObj.errors &&
            errorObj.errors[assetId] &&
            errorObj.errors[assetId][0] &&
            typeof errorObj.errors[assetId][0] === "string"
          ) {
            return [errorObj.errors[assetId][0]];
          } else if (errorObj.title && typeof errorObj.title === "string") {
            return [errorObj.title];
          } else return [];
        }
      } else if (typeof error === "object" && error !== null) {
        return Object.values(error)
          .filter((err: string[]) => err[0] && typeof err[0] === "string")
          .map((err: string[]) => err[0].toString());
      } else if (typeof error === "string" && error) {
        return [error];
      }
      return [];
    },
    [assetId, isJson]
  );

  const displayErrorMessage = useCallback(
    (error: string) => {
      const errorMessages = getErrorMessagesToTranslate(error) || [];
      if (errorMessages.length === 0) {
        notifications.error({
          message: intl.formatMessage({
            id: `configuration_tool.messages.REQUEST_CANT_BE_PROCESSED`,
            defaultMessage: "The request cannot be processed"
          })
        });
      } else {
        errorMessages.forEach((error) =>
          notifications.error({
            message: intl.formatMessage({
              id: `configuration_tool.messages.${error}`,
              defaultMessage: error
            })
          })
        );
      }
    },
    [getErrorMessagesToTranslate, intl]
  );

  const handleSaveInspection = useCallback(async () => {
    if (isDataTypeTouched(DataTypes.Inspection)) {
      saveInspection().then(
        (result) => {
          notifications.success({
            message: intl.formatMessage({
              id: "configuration_tool.messages.inspection_submitted",
              defaultMessage: "Inspection submitted"
            })
          });

          setIsSubmittingInProgress(false);
        },
        (error) => {
          displayErrorMessage(error);
          setIsSubmittingInProgress(false);
        }
      );
    } else {
      setIsSubmittingInProgress(false);
    }
  }, [isDataTypeTouched, saveInspection, intl, displayErrorMessage]);

  const handleSubmit = useCallback(async () => {
    setIsSubmittingInProgress(true);

    if (!(await validForms())) {
      notifications.error({
        message: intl.formatMessage({
          id: "configuration_tool.messages.validation_errors",
          defaultMessage: "Validation errors"
        })
      });

      setIsSubmittingInProgress(false);
      return;
    }

    if (isDataTypeTouched(DataTypes.ConnectedDevices)) {
      await saveConnectedDevices()
        .then(() => {
          notifications.success({
            message: intl.formatMessage({
              id: "configuration_tool.tab.connected_devices_submitted",
              defaultMessage: "Connected Devices have been saved."
            })
          });
        })
        .catch((err) => {
          displayErrorMessage(err);
          setIsSubmittingInProgress(false);
        });
    }

    if (isDataTypeTouched(DataTypes.Nameplate)) {
      await saveAssetNameplate().then(
        () => {
          notifications.success({
            message: intl.formatMessage({
              id: "configuration_tool.messages.nameplate_submitted",
              defaultMessage: "Nameplate submitted"
            })
          });

          handleSaveInspection();
        },
        (error) => {
          displayErrorMessage(error);
          setIsSubmittingInProgress(false);
        }
      );
    } else {
      if (isNil(assetId)) {
        notifications.error({
          message: intl.formatMessage({
            id: "configuration_tool.messages.provide_asset_id",
            defaultMessage: "Please provide Asset ID"
          })
        });

        setIsSubmittingInProgress(false);
        return;
      }
      handleSaveInspection();
    }
  }, [
    validForms,
    isDataTypeTouched,
    intl,
    saveConnectedDevices,
    displayErrorMessage,
    saveAssetNameplate,
    handleSaveInspection,
    assetId
  ]);

  const selectedFormInfo: IFormItem = useMemo(() => {
    return getFormInfo(selectedMenu);
  }, [selectedMenu, getFormInfo]);

  return (
    <Layout className={className}>
      <Header>
        <Title level={5}>
          {isEditMode ? (
            <FormattedMessage
              defaultMessage="{assetId} - Edit mode"
              id="configuration_tool.mode.edit_mode"
              values={{ assetId }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Add mode"
              id="configuration_tool.mode.add_mode"
            />
          )}
        </Title>
      </Header>
      <Layout>
        <Sider theme="light">
          <AssetModalMenu
            menuItems={menuItems}
            onMenuChange={changeSelectedMenu}
            selectedMenu={selectedMenu}
          />
        </Sider>
        <Content>
          <AssetModalForms>
            <>
              {selectedFormInfo?.formType === FormTypes.Nameplate && (
                <Nameplate formInfo={selectedFormInfo} />
              )}
              {selectedFormInfo?.formType === FormTypes.Inspection && (
                <Inspection formInfo={selectedFormInfo} />
              )}
              {selectedFormInfo?.formType === FormTypes.CustomParameters && (
                <CustomParameters formInfo={selectedFormInfo} />
              )}
              {selectedFormInfo?.formType === FormTypes.ConnectedDevices && (
                <ConnectedDevices formInfo={selectedFormInfo} />
              )}
              {selectedFormInfo?.formType === FormTypes.TabbedInspection && (
                <TabbedInspection formInfo={selectedFormInfo} />
              )}
            </>
          </AssetModalForms>
        </Content>
      </Layout>

      <Footer>
        <Button
          type="default"
          className="float-left"
          disabled={blockActions || isSubmittingInProgress}
          onClick={resetData}
          data-qa="button-reset"
        >
          <Icon name="refresh" size="sm" />
          <FormattedMessage
            defaultMessage="Reset"
            id="configuration_tool.action.reset"
          />
        </Button>
        <Button
          type="primary"
          className="float-right"
          disabled={blockActions || isSubmittingInProgress}
          onClick={handleSubmit}
          data-qa="button-submit"
        >
          {isSubmittingInProgress ? <Spin /> : <Icon name="done" size="sm" />}
          <FormattedMessage
            defaultMessage="Submit"
            id="configuration_tool.action.submit"
          />
        </Button>
        <Button
          type="default"
          className="float-right"
          onClick={onCancel}
          data-qa="button-cancel"
        >
          <Icon name="close" size="sm" />
          <FormattedMessage
            defaultMessage="Cancel"
            id="configuration_tool.action.cancel"
          />
        </Button>
      </Footer>
    </Layout>
  );
};

const StyledAssetModalContent = styled(AssetModalContent)`
  .ant-layout-header,
  .ant-layout-content,
  .ant-layout-footer {
    background-color: ${colorGray0};
  }

  .ant-layout-header {
    padding: 0px 0px;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }

  .ant-layout-footer {
    padding: ${spacingXXLarge} ${spacingXXLarge} 0px ${spacingXXLarge};
    border-top: 1px solid;
    border-color: ${colorWidgetBorder};

    .ant-btn-primary[disabled] {
      .ant-spin-dot-item {
        background-color: ${colorGray40};
      }
    }

    button {
      .icon {
        margin-right: ${spacingMedium};
      }

      .ant-spin {
        margin-right: ${spacingMedium};
        width: ${fontSizeMedium};

        .ant-spin-dot-item {
          background-color: ${colorGray0};
        }

        .ant-spin-dot {
          font-size: ${fontSizeMedium};
          margin-bottom: -${spacingXSmall};
        }
      }
    }

    button + button {
      margin-left: ${spacingXLarge};
    }
  }

  .ant-layout-sider-children {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export default StyledAssetModalContent;
