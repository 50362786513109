// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isString } from "lodash";
import IUpdateIssueStatusResponse, {
  IUpdateIssueStatusSuccessResponse,
  IUpdateIssueStatusWarningResponse
} from "../models/IUpdateIssueStatusResponse";

const isSuccess = (
  response: IUpdateIssueStatusResponse
): response is IUpdateIssueStatusSuccessResponse => response === "";

const isWarning = (
  response: IUpdateIssueStatusResponse
): response is IUpdateIssueStatusWarningResponse => {
  if (isString(response)) return false;

  return response.SuccessCount > 0 && !!response.Errors;
};

export { isSuccess, isWarning };
