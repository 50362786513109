// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import BBChart from "common/chart/components/BBChart";
import LTCOperationsBarChartLegend from "features/detailpage/features/ltc/components/LTCOperationsBarChartLegend";
import useLTCOperationsChartConfiguration from "features/detailpage/features/ltc/hooks/useLTCOperationsChartConfiguration";
import "./LTCOperationsBarChart.less";
interface ILTCOperationsBarChartProps {
  percentageUsed: number;
  warningThreshold: number;
  alarmThreshold: number;
}

const LTCOperationsBarChart = ({
  percentageUsed,
  warningThreshold,
  alarmThreshold
}: ILTCOperationsBarChartProps) => {
  const { configuration, legendData } = useLTCOperationsChartConfiguration({
    percentageUsed,
    warningThreshold,
    alarmThreshold
  });

  return (
    <>
      <div className="ltc-operations-chart">
        <BBChart configuration={configuration} />
      </div>
      <LTCOperationsBarChartLegend
        plots={legendData}
        series={"LTC Operations"}
        className="ltc-operations-chart-legend"
      />
    </>
  );
};

export default LTCOperationsBarChart;
