// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useEffect } from "react";

const usePopoverHideOnScroll = (
  isPopoverVisible: boolean,
  onScroll: () => void
) => {
  useEffect(() => {
    if (isPopoverVisible) {
      document.addEventListener("scroll", onScroll, true);
    } else {
      document.removeEventListener("scroll", onScroll, true);
    }

    return () => {
      document.removeEventListener("scroll", onScroll, true);
    };
  }, [isPopoverVisible, onScroll]);
};

export default usePopoverHideOnScroll;
