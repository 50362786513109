// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import PhaseAngleChart, {
  IConfiguration,
  IPoint
} from "common/chart/components/PhaseAngleChart";
import Processing from "components/common/Processing";
import { WidgetNoDataMessage } from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import IOnlineBushingData from "../models/IOnlineBushingData";
import "./BushingVisualization.less";
import BushingWeeklyChart from "./BushingWeeklyChart";

export const highVoltageSeriesColor = "#7C71A7";
export const lowVoltageSeriesColor = "#47A092";

interface IBushingVisualizationProps {
  onlineBushingData: Data<IOnlineBushingData>;
}

const BushingVisualization: React.FC<IBushingVisualizationProps> = ({
  onlineBushingData
}) => {
  switch (onlineBushingData.status) {
    case Statuses.Loading:
      return <BushingVisualizationProcessing />;
    case Statuses.Succeeded:
      return (
        <BushingVisualizationSucceeded
          onlineBushingData={onlineBushingData.data}
        />
      );
    case Statuses.Failed:
      return <BushingVisualizationFailed />;
    case Statuses.NotFound:
      return <BushingVisualizationNoData />;
    default:
      return null;
  }
};

const BushingVisualizationProcessing = () => (
  <div className="bushing-visualization container">
    <Processing />
  </div>
);

interface IBushingVisualizationSucceededProps {
  onlineBushingData: IOnlineBushingData;
}

const BushingVisualizationSucceeded = ({
  onlineBushingData
}: IBushingVisualizationSucceededProps) => {
  const configuration: IConfiguration = onlineBushingData && {
    series: [
      {
        id: "LowVoltage",
        points: onlineBushingData.LowVoltageSumCurrentReadings?.map<IPoint>(
          (r) => ({
            degrees: r.PhaseAngle,
            value: r.Magnitude,
            date: r.Date
          })
        ),
        color: lowVoltageSeriesColor
      },
      {
        id: "HighVoltage",
        points: onlineBushingData.HighVoltageSumCurrentReadings?.map<IPoint>(
          (r) => ({
            degrees: r.PhaseAngle,
            value: r.Magnitude,
            date: r.Date
          })
        ),
        color: highVoltageSeriesColor
      }
    ],
    alertValue: onlineBushingData.SumCurrentAlertLevel,
    warningValue: onlineBushingData.SumCurrentWarningLevel
  };

  return (
    <div className="bushing-visualization container">
      <div className="bootstrap-row">
        <div className="col-10 chart" data-qa="sum-current-phase-chart">
          <div className="chart-name">
            <span className="bold">
              <FormattedMessage
                defaultMessage="Sum Current & Phase Angle"
                id="detail_page.parameters.bushing_visualization.phase_angle_chart_title"
              />
            </span>
          </div>
          <PhaseAngleChart configuration={configuration} />
        </div>
        <div className="col-14 chart" data-qa="sum-current-weekly-chart">
          <div className="chart-name">
            <FormattedMessage
              defaultMessage="{sumCurrent} - {weekly}"
              id="detail_page.parameters.bushing_visualization.sum_current_chart_title"
              values={{
                sumCurrent: (
                  <span className="bold">
                    <FormattedMessage
                      defaultMessage="Sum Current"
                      id="detail_page.parameters.bushing_visualization.sum_current"
                    />
                  </span>
                ),
                weekly: (
                  <FormattedMessage
                    defaultMessage="weekly"
                    id="detail_page.parameters.bushing_visualization.weekly"
                  />
                )
              }}
            />
          </div>
          <BushingWeeklyChart onlineBushingData={onlineBushingData} />
        </div>
      </div>
    </div>
  );
};

const BushingVisualizationFailed = () => (
  <div className="bushing-visualization container">
    <div className="failed">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="Data loading failed"
        id="detail_page.parameters.bushing_visualization.data_loading_failed"
      />
    </div>
  </div>
);

const BushingVisualizationNoData = () => (
  <div className="bushing-visualization container">
    <WidgetNoDataMessage />
  </div>
);

export default BushingVisualization;
