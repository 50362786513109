// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import CenteredWrapper from "common/CenteredWrapper";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import IDGAOperations from "features/detailpage/features/ltc/models/IDGAOperations";
import { isEmpty } from "lodash";
import DGAOperationsGrid from "./DGAOperationsGrid";

interface IDGAOperationsStateProps {
  dgaOperations: Data<IDGAOperations>;
}

interface IDGAOperationsProps extends IDGAOperationsStateProps {
  modelId: string;
  implementationId: string;
}

export default ({
  dgaOperations,
  modelId,
  implementationId
}: IDGAOperationsProps) => {
  switch (dgaOperations.status) {
    case Statuses.Loading:
      return (
        <CenteredWrapper>
          <Processing className="spinner small dark" />
        </CenteredWrapper>
      );
    case Statuses.Succeeded:
      return isEmpty(dgaOperations.data) ? (
        <CenteredWrapper>
          <WidgetNoDataMessage />
        </CenteredWrapper>
      ) : (
        <DGAOperationsGrid
          dgaOperations={dgaOperations.data}
          modelId={modelId}
          implementationId={implementationId}
        />
      );
    case Statuses.NotFound:
      return (
        <CenteredWrapper>
          <WidgetNoDataMessage />
        </CenteredWrapper>
      );
    case Statuses.Failed:
      return (
        <CenteredWrapper>
          <WidgetErrorMessage />
        </CenteredWrapper>
      );
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
