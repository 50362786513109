// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRegion } from "@apm/widgets/build/widgets/RogersRatio2D/models/IRegion";
import {
  colorBlue20,
  colorBlue30,
  colorBlue40,
  colorBlue50,
  colorBlue60,
  colorBlue70,
  colorBlue80
} from "@pg/common/build/styles/ColorVariables";

const regionsChart2: IRegion[] = [
  {
    name: "T1",
    color: colorBlue30,
    vertices: [
      { x: 0.01, y: 0.001 },
      { x: 1, y: 0.001 },
      { x: 1, y: 0.01 },
      { x: 0.01, y: 0.01 }
    ]
  },
  {
    name: "T2",
    color: colorBlue80,
    vertices: [
      { x: 1, y: 0.001 },
      { x: 4, y: 0.001 },
      { x: 4, y: 0.01 },
      { x: 1, y: 0.01 }
    ]
  },
  {
    name: "T3",
    color: colorBlue40,
    vertices: [
      { x: 4, y: 0.001 },
      { x: 1000, y: 0.001 },
      { x: 1000, y: 0.01 },
      { x: 4, y: 0.01 }
    ]
  },
  {
    name: "PD",
    color: colorBlue50,
    vertices: [
      { x: 0.01, y: 0.01 },
      { x: 1, y: 0.01 },
      { x: 1, y: 1 },
      { x: 0.01, y: 1 }
    ]
  },
  {
    name: "DT",
    color: colorBlue60,
    vertices: [
      { x: 1, y: 0.01 },
      { x: 1000, y: 0.01 },
      { x: 1000, y: 1 },
      { x: 1, y: 1 }
    ]
  },
  {
    name: "D1",
    color: colorBlue20,
    vertices: [
      { x: 0.01, y: 1 },
      { x: 10, y: 1 },
      { x: 10, y: 10 },
      { x: 0.01, y: 10 }
    ]
  },
  {
    name: "D2",
    color: colorBlue70,
    vertices: [
      { x: 0.01, y: 10 },
      { x: 10, y: 10 },
      { x: 10, y: 1 },
      { x: 1000, y: 1 },
      { x: 1000, y: 1000 },
      { x: 0.01, y: 1000 }
    ]
  }
];

export default regionsChart2;
