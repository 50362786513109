// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Guid from "core/guid/Guid";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { createInput, setInputValue } from "../actions/FormActions";
import SelectInput, {
  ISelectInputActions,
  ISelectInputData,
  ISelectInputOwnProps
} from "../components/SelectInput";
import IValidator from "../validators/IValidator";
import RequiredValidator from "../validators/RequiredValidator";

export function mapStateToProps(
  state: IState,
  ownProps: ISelectInputOwnProps
): ISelectInputData {
  const form = state.common.forms && state.common.forms.get(ownProps.formName);
  const input = form && form.inputs && form.inputs.get(ownProps.inputName);
  const errorMessages = input && input.errorMessages;
  const guid = input ? input.guid : Guid.getUniqGuid();

  return {
    validationMessages: errorMessages && errorMessages.toArray(),
    guid
  };
}

export function mapDispatchToProps(
  dispatch: AppDispatch,
  ownProps: ISelectInputOwnProps
): ISelectInputActions {
  return {
    createInput: () =>
      createInput(
        dispatch,
        ownProps.formName,
        ownProps.inputName,
        ownProps.defaultValue,
        ownProps.defaultValue
      ),
    setInputValue: (value: any) => {
      if (!ownProps.preventValueChange) {
        const requiredValidator: IValidator<string> = new RequiredValidator();
        const valueIsRequired = ownProps.required === true;
        const computedValidators = valueIsRequired
          ? [requiredValidator].concat(ownProps.validators || [])
          : ownProps.validators;

        setInputValue(
          dispatch,
          ownProps.formName,
          ownProps.inputName,
          value,
          computedValidators
        );
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput);
