// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { DuvalPoint } from "../../../components/analytics/DuvalTriangle/DuvalTriangle";
import IDuvalRegion from "../../../components/analytics/DuvalTriangle/models/IDuvalRegion";

const type1_SoyDefault: IDuvalRegion[] = [
  {
    Name: "D1",
    Color: "#CCEEFC",
    LabelCenter: new DuvalPoint(5, 10, 85),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(87, 0, 13),
      new DuvalPoint(0, 0, 100),
      new DuvalPoint(0, 25, 75),
      new DuvalPoint(62, 25, 13)
    ]
  },
  {
    Name: "D2",
    Color: "#00A7F1",
    LabelCenter: new DuvalPoint(5, 45, 55),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(62, 25, 13),
      new DuvalPoint(0, 25, 75),
      new DuvalPoint(0, 71, 29),
      new DuvalPoint(31, 40, 29),
      new DuvalPoint(47, 40, 13)
    ]
  },
  {
    Name: "PD",
    Color: "#4386FB",
    LabelCenter: new DuvalPoint(99, 1, 0),
    LabelXOffset: 5,
    LabelYOffset: 3,
    Points: [
      new DuvalPoint(100, 0, 0),
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(98, 2, 0)
    ]
  },
  {
    Name: "T1",
    Color: "#87D4EA",
    LabelCenter: new DuvalPoint(80, 12, -1),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(96, 0, 4),
      new DuvalPoint(53, 43, 4),
      new DuvalPoint(57, 43, 0),
      new DuvalPoint(98, 2, 0)
    ]
  },
  {
    Name: "T2",
    Color: "#8EB7FD",
    LabelCenter: new DuvalPoint(32, 35, -1),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(57, 43, 0),
      new DuvalPoint(53, 43, 4),
      new DuvalPoint(33, 63, 4),
      new DuvalPoint(37, 63, 0)
    ]
  },
  {
    Name: "T3",
    Color: "#DAE7FE",
    LabelCenter: new DuvalPoint(5, 90, 11),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(37, 63, 0),
      new DuvalPoint(33, 63, 4),
      new DuvalPoint(22, 53, 15),
      new DuvalPoint(0, 85, 15),
      new DuvalPoint(0, 100, 0)
    ]
  },
  {
    Name: "DT",
    Color: "#2E98B7",
    LabelCenter: new DuvalPoint(5, 72, 26),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(96, 0, 4),
      new DuvalPoint(87, 0, 13),
      new DuvalPoint(47, 40, 13),
      new DuvalPoint(31, 40, 29),
      new DuvalPoint(0, 71, 29),
      new DuvalPoint(0, 85, 15),
      new DuvalPoint(22, 63, 15),
      new DuvalPoint(33, 63, 4)
    ]
  }
];

export default type1_SoyDefault;
