// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Card, Col, Row } from "antd";
import Container from "common/Container";
import DGAOperationsContainer from "features/detailpage/features/ltc/containers/DGAOperationsContainer";
import HistorySwitchingContainer from "features/detailpage/features/ltc/containers/HistorySwitchingContainer";
import LTCOperationsContainer from "features/detailpage/features/ltc/containers/LTCOperationsContainer";
import { PureComponent, ReactNode } from "react";
import styled from "styled-components";
import { spacingXLarge } from "styles/StyleVariables";

const LTCWidget = ({ children }: { children: ReactNode }) => (
  <Card>{children}</Card>
);

interface ILTCTabProps {
  assetId: string;
  modelId: string;
  implementationId: string;
  className?: string;
}

class LTCTab extends PureComponent<ILTCTabProps> {
  render() {
    const { assetId, modelId, implementationId, className } = this.props;
    return (
      <div className={className}>
        <Container className="ltc-container" size="lg">
          <Row>
            <Col span={24}>
              <LTCWidget>
                <LTCOperationsContainer assetId={assetId} />
              </LTCWidget>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <LTCWidget>
                <HistorySwitchingContainer assetId={assetId} />
              </LTCWidget>
            </Col>
            <Col span={12}>
              <LTCWidget>
                <DGAOperationsContainer
                  assetId={assetId}
                  modelId={modelId}
                  implementationId={implementationId}
                />
              </LTCWidget>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default styled(LTCTab)`
  height: calc(100vh - 230px);

  > .ltc-container {
    height: 100%;

    > .container-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;

      > .ant-row {
        &:first-child {
          margin-bottom: ${spacingXLarge};
          min-height: 210px;
        }

        &:last-child {
          flex: 1;
        }
      }

      .ant-card,
      .ant-card-body {
        height: 100%;
        overflow: hidden;
      }
    }
  }
`;
