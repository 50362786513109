// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { RouterLayout } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import VoltageStress from "./VoltageStress";

interface IVoltageStressTabsProps {
  assetId: string;
  implementationId: string;
  className?: string;
}

const VoltageStressTabs = ({ assetId, className }: IVoltageStressTabsProps) => {
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <RouterLayout
      className={className}
      routes={[
        {
          id: "Assets-DetailPage-Parameters-VoltageStress-Percentage",
          route: "Percentages",
          displayName: intl.formatMessage({
            id: "detail_page.widgets.analytics.transformers.voltage_stress.tab.Percentages",
            defaultMessage: "Stress Percentage"
          }),
          to: {
            pathname: "/Assets/DetailPage/Parameters/VoltageStress/Percentages",
            search: searchParams.toString()
          },
          element: (
            <VoltageStress assetId={assetId} chartGroup={"Percentages"} />
          )
        },
        {
          id: "Assets-DetailPage-Parameters-VoltageStress-Count",
          route: "Counts",
          displayName: intl.formatMessage({
            id: "detail_page.widgets.analytics.transformers.voltage_stress.tab.Counts",
            defaultMessage: "Stress Count"
          }),
          to: {
            pathname: "/Assets/DetailPage/Parameters/VoltageStress/Counts",
            search: searchParams.toString()
          },
          element: <VoltageStress assetId={assetId} chartGroup={"Counts"} />
        },
        {
          id: "Assets-DetailPage-Parameters-VoltageStress-Durations",
          route: "Durations",
          displayName: intl.formatMessage({
            id: "detail_page.widgets.analytics.transformers.voltage_stress.tab.Durations",
            defaultMessage: "Stress Duration"
          }),
          to: {
            pathname: "/Assets/DetailPage/Parameters/VoltageStress/Durations",
            search: searchParams.toString()
          },
          element: <VoltageStress assetId={assetId} chartGroup={"Durations"} />
        }
      ]}
    />
  );
};

export default styled(VoltageStressTabs)`
  .parameters-chart {
    margin-top: -60px;
  }
`;
