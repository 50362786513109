// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IDataSourceConfig } from "@apm/widgets/build/widgets/RogersRatio2D";

const dataSourceConfig: IDataSourceConfig = {
  dataSources: ["online", "offline"],
  defaultDataSource: "offline",
  showLabel: true
};

export default dataSourceConfig;
