// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col, Row, Typography } from "antd";
import LTCLatestUpdateInfo from "features/detailpage/features/ltc/components/LTCLatestUpdateInfo";
import LTCOperationsBarChart from "features/detailpage/features/ltc/components/LTCOperationsBarChart";
import LTCPercentageUsedInfo from "features/detailpage/features/ltc/components/LTCPercentageUsedInfo";
import ILTCOperations from "features/detailpage/features/ltc/models/ILTCOperations";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  fontSizeMedium,
  spacingMedium,
  spacingXLarge
} from "styles/StyleVariables";

const { Text } = Typography;

interface ILTCOperationsContent {
  ltcOperations: ILTCOperations;
  percentageUsed: number;
  className?: string;
}

const LTCOperationsContent = ({
  ltcOperations: {
    AlarmThreshold,
    CumulativeOperationCount,
    LastUpdate,
    MaximumOperationCount,
    WarningThreshold
  },
  percentageUsed,
  className
}: ILTCOperationsContent) => (
  <Row
    gutter={30}
    align="middle"
    className={className}
    data-qa="ltc-operations"
  >
    <Col span={12}>
      <Text strong className="ltc-operations-chart-title">
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.ltc.ltc_operations_chart_title"
          defaultMessage="LTC Operations"
        />
      </Text>
      <LTCOperationsBarChart
        percentageUsed={percentageUsed}
        warningThreshold={WarningThreshold}
        alarmThreshold={AlarmThreshold}
      />
    </Col>
    <Col span={7}>
      <LTCLatestUpdateInfo
        cumulativeOperationCount={CumulativeOperationCount}
        maximumOperationCount={MaximumOperationCount}
        lastUpdate={LastUpdate}
      />
    </Col>
    <Col span={5}>
      <LTCPercentageUsedInfo
        value={percentageUsed}
        warningThreshold={WarningThreshold}
        alarmThreshold={AlarmThreshold}
      />
    </Col>
  </Row>
);

export default styled(LTCOperationsContent)`
  height: 100%;

  .ltc-operations-chart-title {
    display: block;
    margin: ${spacingXLarge} 0 -${spacingMedium} ${spacingMedium};
    font-size: ${fontSizeMedium};
  }
`;
