// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { DataItem } from "billboard.js";
import IAssetPoint from "common/AssetRiskMatrix/models/IAssetPoint";
import ICluster from "common/AssetRiskMatrix/models/ICluster";
import { useCallback } from "react";

interface IUseAssetRiskMatrixChartDataClickHandleOptions {
  getAssetData: (seriesName: string, idx: number) => IAssetPoint;
  getClusterData: (seriesName: string, idx: number) => ICluster;
  onAssetClick?: (assetId: string) => void;
  onClusterClick?: (cluster: ICluster) => void;
}

const useAssetRiskMatrixChartDataClickHandle = ({
  getAssetData,
  getClusterData,
  onAssetClick,
  onClusterClick
}: IUseAssetRiskMatrixChartDataClickHandleOptions) => {
  const handleOnOtherAssetsClick = useCallback(
    (index: number) => {
      if (onAssetClick) {
        const asset = getAssetData("other_assets", index);
        if (asset) onAssetClick(asset.AssetId);
      }
    },
    [onAssetClick, getAssetData]
  );

  const handleOnClusterClick = useCallback(
    (seriesName: string, index: number) => {
      if (onClusterClick) {
        const cluster = getClusterData(seriesName, index);
        if (cluster) onClusterClick(cluster);
      }
    },
    [onClusterClick, getClusterData]
  );

  const handleChartDataClick = useCallback(
    ({ id, index }: DataItem) => {
      if (id === "other_assets") {
        handleOnOtherAssetsClick(index);
      } else if (id.startsWith("cluster")) {
        handleOnClusterClick(id, index);
      }
    },
    [handleOnClusterClick, handleOnOtherAssetsClick]
  );

  return { handleChartDataClick };
};

export default useAssetRiskMatrixChartDataClickHandle;
