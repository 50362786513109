// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { isNil } from "lodash";

import UrlService from "core/data/services/UrlService";
import LifecycleStatus from "models/LifecycleStatus";
import { config } from "utils/AppConfig";
import RiskMatrixScope from "../models/RiskMatrixScope";

const getLoadClustersUrl = (
  scope: RiskMatrixScope,
  modelId: string,
  lifecycleStatus: string,
  organization: string,
  region: string,
  location: string,
  numberOfClusters: number
) => {
  const { clustersLocationUrl, clustersFleetUrl } =
    config.api.detailPage.widgets.RiskMatrix.RiskMatrixChart;

  if (lifecycleStatus === LifecycleStatus.InReserve)
    lifecycleStatus = LifecycleStatus.InService;

  const searchParams = new SearchParams({
    modelId,
    lifecycleStatus,
    numberOfClusters: numberOfClusters.toString()
  });

  if (scope === "Location") {
    const url = UrlService.getApiUrl(clustersLocationUrl);
    if (!isNil(organization)) searchParams.append("organization", organization);
    if (!isNil(region)) searchParams.append("region", region);
    if (!isNil(location)) searchParams.append("location", location);
    return `${url}?${searchParams.toString()}`;
  } else {
    const url = UrlService.getApiUrl(clustersFleetUrl);
    return `${url}?${searchParams.toString()}`;
  }
};

export default getLoadClustersUrl;
