// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { DuvalPoint } from "../../../components/analytics/DuvalTriangle/DuvalTriangle";
import IDuvalRegion from "../../../components/analytics/DuvalTriangle/models/IDuvalRegion";

const type1_SiliconeDefault: IDuvalRegion[] = [
  {
    Name: "D1",
    Color: "#CCEEFC",
    LabelCenter: new DuvalPoint(5, 3, 90),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(87, 0, 13),
      new DuvalPoint(0, 0, 100),
      new DuvalPoint(0, 9, 91),
      new DuvalPoint(78, 9, 13)
    ]
  },
  {
    Name: "D2",
    Color: "#00A7F1",
    LabelCenter: new DuvalPoint(5, 35, 60),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(78, 9, 13),
      new DuvalPoint(0, 9, 91),
      new DuvalPoint(0, 71, 29),
      new DuvalPoint(31, 40, 29),
      new DuvalPoint(47, 40, 13)
    ]
  },
  {
    Name: "PD",
    Color: "#4386FB",
    LabelCenter: new DuvalPoint(99, 1, 0),
    LabelXOffset: 5,
    LabelYOffset: 3,
    Points: [
      new DuvalPoint(100, 0, 0),
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(98, 2, 0)
    ]
  },
  {
    Name: "T1",
    Color: "#87D4EA",
    LabelCenter: new DuvalPoint(80, 12, -1),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(96, 0, 4),
      new DuvalPoint(80, 16, 4),
      new DuvalPoint(84, 16, 0),
      new DuvalPoint(98, 2, 0)
    ]
  },
  {
    Name: "T2",
    Color: "#8EB7FD",
    LabelCenter: new DuvalPoint(42, 20, -1),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(84, 16, 0),
      new DuvalPoint(80, 16, 4),
      new DuvalPoint(50, 46, 4),
      new DuvalPoint(54, 46, 0)
    ]
  },
  {
    Name: "T3",
    Color: "#DAE7FE",
    LabelCenter: new DuvalPoint(5, 90, 11),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(54, 46, 0),
      new DuvalPoint(50, 46, 4),
      new DuvalPoint(39, 46, 15),
      new DuvalPoint(0, 85, 15),
      new DuvalPoint(0, 100, 0)
    ]
  },
  {
    Name: "DT",
    Color: "#2E98B7",
    LabelCenter: new DuvalPoint(5, 72, 26),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(96, 0, 4),
      new DuvalPoint(87, 0, 13),
      new DuvalPoint(47, 40, 13),
      new DuvalPoint(31, 40, 29),
      new DuvalPoint(0, 71, 29),
      new DuvalPoint(0, 85, 15),
      new DuvalPoint(39, 46, 15),
      new DuvalPoint(50, 46, 4)
    ]
  }
];

export default type1_SiliconeDefault;
