// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useEffect, useState } from "react";
import {
  RogerRatioCoords,
  RogersRatio3DPoint,
  ZonesLimits
} from "../models/RogersRatio3DData";
import { RogersRatioData } from "../models/RogersRatioData";
export type LastDataSource = null | string;

export type RogerRatioZone = {
  limits: RogerRatioCoords;
};

export type RogersRatioDataProcessed = {
  rogersRatioZones: RogerRatioZone[];
  rogersRatioPoints: RogersRatio3DPoint[];
};

export type RogersRatio3DPointsData = {
  OfflinePoints: RogersRatio3DPoint[];
  OnlinePoints: RogersRatio3DPoint[];
};

const useRogersRatio3DData = (rogersRatioData: RogersRatioData) => {
  const [rogersRatioZones, setRogersRatioZones] = useState<
    ZonesLimits[] & RogerRatioZone[]
  >([]);
  const [lastDataSource, setLastDataSource] = useState<LastDataSource>(
    rogersRatioData.LastDataSource
  );
  const [tracePoints] = useState<RogersRatio3DPointsData>({
    OfflinePoints: rogersRatioData.RogersRatio3D.OfflinePoints,
    OnlinePoints: rogersRatioData.RogersRatio3D.OnlinePoints
  });

  const processRogersRatioData = (): void => {
    const rogersRatioZonesEntries = Object.entries(
      rogersRatioData.RogersRatio3D.ZonesLimits
    );
    const rogersRatioZonesArray: RogerRatioZone[] & ZonesLimits[] = [];

    for (const [, values] of rogersRatioZonesEntries) {
      rogersRatioZonesArray.push({
        limits: values
      });
    }
    setRogersRatioZones(rogersRatioZonesArray);
  };

  useEffect(() => {
    processRogersRatioData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    rogersRatioZones,
    tracePoints,
    lastDataSource,
    setLastDataSource
  };
};

export default useRogersRatio3DData;
