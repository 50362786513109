// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Container from "common/Container";
import DataGrid from "common/datagrid/DataGrid";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { isNil } from "lodash";
import styled from "styled-components";
import { spacingXLarge } from "styles/StyleVariables";
import gridColumnsConfig from "../constants/gridColumnsConfig";
import useGridData from "../hooks/useGridData";
import { useCallback } from "react";

interface IComponentRiskSummaryGridProps {
  className?: string;
  filters: SelectedFilters;
}

const ComponentRiskSummaryGrid = ({
  className,
  filters
}: IComponentRiskSummaryGridProps) => {
  const { dataEndpoint, rowsTotal } = useGridData({
    filters
  });
  
  const updatedGridColumnsConfig = useCallback (() => {

    enum ConfigItemNames {
      AssetId = "AssetId",
      ComponentsCount = "ComponentsCount"
    }

    const getIndex = (columnId: string) => gridColumnsConfig.findIndex((column) => column?.id === columnId)

    const newGridColumnsConfig = [...gridColumnsConfig];
    const unusedTdSpace = 0.9;
    
    newGridColumnsConfig[getIndex(ConfigItemNames.AssetId)] = {
      ...newGridColumnsConfig[getIndex(ConfigItemNames.AssetId)], weight: newGridColumnsConfig[getIndex(ConfigItemNames.AssetId)].weight + unusedTdSpace
    }
    
    newGridColumnsConfig[getIndex(ConfigItemNames.ComponentsCount)] = {
      ...newGridColumnsConfig[getIndex(ConfigItemNames.ComponentsCount)], weight: newGridColumnsConfig[getIndex(ConfigItemNames.ComponentsCount)].weight - unusedTdSpace
    }

    return newGridColumnsConfig
  }, []);

  return (
    <div className={`${className} data-grid__parent`}>
      <div className="data-grid__scroll">
        <Container>
          <div className="header">
            <div className="title">
              {!isNil(rowsTotal) ? (
                <SectionName
                  messageDefault="Assets by Components / { numberOfAssets, plural, zero {no items} one {# item} other {# items} }"
                  messageId="component_risk_summary_page.header.assets_by_components_total"
                  messageValues={{
                    numberOfAssets: rowsTotal
                  }}
                />
              ) : (
                <SectionName
                  messageDefault="Assets by Components"
                  messageId="component_risk_summary_page.header.assets_by_components"
                />
              )}
              <StatusBar />
            </div>
          </div>
          <DataGrid columns={updatedGridColumnsConfig()} dataEndpoint={dataEndpoint}  />
        </Container>
      </div>
    </div>
  );
};

export default styled(ComponentRiskSummaryGrid)`
  overflow: hidden;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: ${spacingXLarge};
      }
    }
  }

  .data-grid__scroll {
    height: 100%;
  }
`;
