// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRow } from "common/datagrid/DataGrid";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";

interface ILicensesColumnProps {
  value: any;
  row: IRow;
}

const LicensesColumn = ({ value, row }: ILicensesColumnProps) => {
  return (
    <div className="grid-cell-content">
      {value && row.data["fileName"] && (
        <TooltipWrapper
          Tooltip={() => (
            <>
              {`#/Licenses/${row.data["licenseType"]}/${encodeURIComponent(
                row.data["fileName"]
              )}`}
            </>
          )}
          theme={Themes.Dark}
        >
          <a
            href={`#/Licenses/${row.data["licenseType"]}/${encodeURIComponent(
              row.data["fileName"]
            )}`}
            rel="noopener noreferrer"
            target="_blank"
            data-qa="link"
            className="link-label"
          >
            {value}
          </a>
        </TooltipWrapper>
      )}
      {value && !row.data["fileName"] && row.data["licensesUrl"] && (
        <TooltipWrapper
          Tooltip={() => <div>{row.data["licensesUrl"]}</div>}
          theme={Themes.Dark}
        >
          <a
            href={row.data["licensesUrl"]}
            rel="noopener noreferrer"
            target="_blank"
            data-qa="link"
            className="link-label"
          >
            {value}
          </a>
        </TooltipWrapper>
      )}
      {!value && null}
    </div>
  );
};

export default LicensesColumn;
