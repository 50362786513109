// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartOptions } from "billboard.js";
import IHistorySwitchingOperations from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import { IntlShape } from "react-intl";
import getDefaultConfiguration from "./DefaultConfiguration";

export default class HistorySwitchingChartService {
  public static getChartConfiguration(
    historySwitching: IHistorySwitchingOperations,
    intl: IntlShape
  ): ChartOptions {
    return getDefaultConfiguration(historySwitching, intl);
  }
}
