// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";
import EGasStandard from "../components/analytics/DuvalTriangle/models/EGasStandard";
import ERevision from "../components/analytics/DuvalTriangle/models/ERevision";

const getIsTransformerStandardModelSilicone = (
  implementationId: string,
  gasStandard: EGasStandard,
  revision: ERevision
) =>
  implementationId?.toLowerCase() ===
    AssetImplementationId.AbbTransformerStandardModel &&
  gasStandard === EGasStandard.IEEE_SILICONE &&
  revision === ERevision.REV_2005;

export default getIsTransformerStandardModelSilicone;
