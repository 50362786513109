// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { formatDateTime } from "common/DateTime/utils/dateFormatters";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import { renderToStaticMarkup } from "react-dom/server";
import { IntlShape } from "react-intl";
import isOnlineDataSource from "../utils/isOnlineDataSource";

interface ITooltipGas {
  label: string;
  ppm: number;
  percent: number;
}

interface IDuvalAnalysisTooltipProps {
  intl: IntlShape;
  date: Date;
  dataSource: DataSource;
  gases: ITooltipGas[];
}

const DuvalAnalysisTooltip = ({
  intl,
  date,
  dataSource,
  gases
}: IDuvalAnalysisTooltipProps) => {
  return (
    <>
      <div className="header">
        {isOnlineDataSource(dataSource)
          ? formatDateTime(intl, date)
          : intl.formatDate(date)}
      </div>
      <table>
        {gases.map((gas) => {
          return (
            <tr key={gas.label}>
              <td className="left">{gas.label}</td>
              <td className="right">
                {intl.formatMessage(
                  {
                    id: "detail_page.widgets.analytics.transformers.duval_analysis.ppm",
                    defaultMessage: "{value} ppm"
                  },
                  {
                    value: gas.ppm
                  }
                )}
              </td>
              <td className="right">
                {intl.formatMessage(
                  {
                    id: "detail_page.widgets.analytics.transformers.duval_analysis.percent",
                    defaultMessage: "{value} %"
                  },
                  {
                    value: gas.percent
                  }
                )}
              </td>
            </tr>
          );
        })}
      </table>
    </>
  );
};

export const getStaticMarkup = (props: IDuvalAnalysisTooltipProps) =>
  renderToStaticMarkup(<DuvalAnalysisTooltip {...props} />);
