// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Button, Form, Input, Layout, Typography } from "antd";
import Modal from "common/Modal";
import { useCallback, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { colorGray0, colorWidgetBorder } from "styles/ColorVariables";
import {
  spacingLarge,
  spacingMedium,
  spacingXLarge,
  spacingXXLarge
} from "styles/StyleVariables";
import AssetModalContext from "../contexts/AssetModalContext";

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;

export interface IFormValues {
  [key: string]: string;
}

export interface INameplateAttributeProps {
  visible: boolean;
  className?: string;
  attributeName?: string;
  attributeValue?: string;
  onCancel: () => void;
  onSave: (
    previousAttributeName: string,
    attributeName: string,
    attributeValue: string
  ) => void;
  onDelete?: (attributeName: string) => void;
  forbiddenAttributeNames?: string[];
  canEditAttributeName: boolean;
}

const NameplateAttribute = ({
  visible,
  attributeName,
  attributeValue,
  onCancel,
  onSave,
  onDelete,
  className,
  forbiddenAttributeNames,
  canEditAttributeName
}: INameplateAttributeProps) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const { validateMessages } = useContext(AssetModalContext);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeName, attributeValue, forbiddenAttributeNames]);

  const handleFinish = useCallback(
    (values: IFormValues) => {
      onSave(attributeName, values["Label"], values["Value"]);
      if (attributeName) onCancel();
    },
    [attributeName, onSave, onCancel]
  );

  return (
    <Modal
      centered={true}
      width="50%"
      open={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
      className={className}
      data-qa="NameplateAttributeModal"
    >
      <Form<IFormValues>
        form={form}
        layout="vertical"
        colon={false}
        onFinish={handleFinish}
        validateMessages={validateMessages}
      >
        <Layout>
          <Header>
            <Title level={5}>
              {attributeName ? (
                <FormattedMessage
                  defaultMessage="Edit custom attribute"
                  id="configuration_tool.action.edit_custom_attribute"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Add custom attribute"
                  id="configuration_tool.action.add_custom_attribute"
                />
              )}
            </Title>
          </Header>
          <Content>
            <Form.Item
              label={
                <Text strong={true}>
                  <FormattedMessage
                    id="configuration_tool.label.label"
                    defaultMessage="Label"
                  />
                </Text>
              }
              className="float-left"
              name="Label"
              initialValue={attributeName}
              rules={[
                {
                  required: true,
                  whitespace: true
                },
                {
                  validator(_, value) {
                    if (
                      !value ||
                      !forbiddenAttributeNames.some((name) => {
                        if (
                          name?.toLowerCase() === attributeName?.toLowerCase()
                        )
                          return false;
                        else
                          return name?.toLowerCase() === value?.toLowerCase();
                      })
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      intl.formatMessage({
                        id: "configuration_tool.validation.attribute_name_should_be_different_from_nameplate_fields",
                        defaultMessage:
                          "The attribute name must be different from the nameplate fields."
                      })
                    );
                  }
                }
              ]}
            >
              <Input
                disabled={attributeName !== null && !canEditAttributeName}
              />
            </Form.Item>
            <Form.Item
              label={
                <Text strong={true}>
                  <FormattedMessage
                    id="configuration_tool.label.value"
                    defaultMessage="Value"
                  />
                </Text>
              }
              className="float-right"
              name="Value"
              initialValue={attributeValue}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Content>
          <Footer>
            {attributeName && onDelete && (
              <Button
                type="text"
                className="float-left"
                danger
                onClick={() => {
                  onDelete(attributeName);
                  onCancel();
                }}
              >
                <FormattedMessage
                  defaultMessage="Delete"
                  id="configuration_tool.action.delete"
                />
              </Button>
            )}
            <Button type="primary" className="float-right" htmlType="submit">
              <Icon name="done" size="sm" />
              <FormattedMessage
                defaultMessage="Save"
                id="configuration_tool.action.save"
              />
            </Button>
            <Button type="default" className="float-right" onClick={onCancel}>
              <Icon name="close" size="sm" />
              <FormattedMessage
                defaultMessage="Cancel"
                id="configuration_tool.action.cancel"
              />
            </Button>
          </Footer>
        </Layout>
      </Form>
    </Modal>
  );
};

const StyledNameplateAttribute = styled(NameplateAttribute)`
  padding-bottom: 0px;

  .ant-layout,
  .ant-layout-header,
  .ant-layout-footer {
    background-color: ${colorGray0};
  }

  .ant-layout-header {
    padding: 0px 0px ${spacingXXLarge} 0px;
  }

  .ant-layout-content {
    padding-bottom: ${spacingXLarge};

    .ant-form-item {
      width: 50%;
    }

    .ant-form-item + .ant-form-item {
      padding-left: ${spacingLarge};
    }

    .float-left {
      float: left;
    }

    .float-right {
      float: right;
    }
  }

  .ant-layout-footer {
    padding: ${spacingXXLarge} 0px 0px 0px;
    border-top: 1px solid;
    border-color: ${colorWidgetBorder};

    button {
      .icon {
        margin-right: ${spacingMedium};
      }
    }

    button + button {
      margin-right: ${spacingLarge};
    }
  }
`;

export default StyledNameplateAttribute;
