// Copyright 2016-2023 Hitachi Energy. All rights reserved.
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { useCallback, useState } from "react";

const useStatusChangeModal = (status: IssueStatuses) => {
  const [statusChangeModalData, setStatusChangeModalData] = useState({
    visible: false,
    status: status
  });

  const hideStatusChangeModal = useCallback(() => {
    setStatusChangeModalData({ visible: false, status: null });
  }, []);

  const showStatusChangeModal = useCallback(
    (status: IssueStatuses) =>
      Promise.resolve(
        setStatusChangeModalData({ visible: true, status: status })
      ),
    []
  );

  return {
    statusChangeModalData,
    hideStatusChangeModal,
    showStatusChangeModal
  };
};

export default useStatusChangeModal;
