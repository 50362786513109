// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import { IRow } from "common/datagrid/DataGrid";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import { Link } from "react-router-dom";

interface IProjectWebsiteColumnProps {
  value: any;
  row: IRow;
}

const ProjectWebsiteColumn = ({ value, row }: IProjectWebsiteColumnProps) => {
  return (
    <div className="grid-cell-content">
      {value && (
        <TooltipWrapper Tooltip={() => <div>{value}</div>} theme={Themes.Dark}>
          <Link
            data-qa="link"
            className="link-label"
            target="_blank"
            rel="noopener noreferrer"
            to={row.data["projectWebsite"]}
          >
            {value}
          </Link>
        </TooltipWrapper>
      )}
      {!value && null}
    </div>
  );
};

export default ProjectWebsiteColumn;
