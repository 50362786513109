// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ConditionTrend from "@apm/widgets/build/widgets/ConditionTrend";
import ISubscore from "@apm/widgets/build/widgets/ConditionTrend/models/ISubscore";
import SubscoresListModal from "@apm/widgets/build/widgets/SubscoresListModal";
import Processing from "components/common/Processing";
import { memo } from "react";
import styled from "styled-components";
import IDegradationScore from "../../../models/IDegradationScore";
import IScoreTrend from "../../../models/IScoreTrend";
import useConditionTrendWidget from "../hooks/useConditionTrendWidget";
import ListActionComponent from "./ListActionComponent";
import WidgetHeaderTitle from "./WidgetHeaderTitle";

interface IConditionTrendWidget {
  className?: string;
  expanded?: boolean;
  series?: IScoreTrend[];
  latestDegradationScores?: IDegradationScore;
  isInFamily?: boolean;
  assetId?: string;
  modelId?: string;
  implementationId?: string;
  isFailed?: boolean;
  lifecycleStatus: string;
}

const ConditionTrendWidget = memo(
  ({
    className,
    expanded,
    latestDegradationScores,
    series,
    isInFamily,
    assetId,
    modelId,
    implementationId,
    isFailed,
    lifecycleStatus
  }: IConditionTrendWidget) => {
    const {
      OverlayComponent,
      conditionTrendState,
      formatTooltipTitle,
      formatValue,
      formatXTick,
      handleCancel,
      handleListItemClick,
      modalState,
      conditionTrendTranslations,
      subscoresListModalTranslations
    } = useConditionTrendWidget({
      implementationId,
      isFailed,
      latestDegradationScores,
      modelId,
      series
    });
    return conditionTrendState?.isSeriesReady ? (
      <div className={className}>
        <div className="condition-trend-wrapper">
          <ConditionTrend
            expanded={expanded}
            series={conditionTrendState?.series}
            subscores={conditionTrendState?.subscores}
            height="100%"
            onListItemClick={handleListItemClick}
            SubscoreListItemActionComponent={(props: ISubscore) => (
              <ListActionComponent
                {...props}
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
                isInFamily={isInFamily}
                lifecycleStatus={lifecycleStatus}
              />
            )}
            header={<WidgetHeaderTitle />}
            formatValue={formatValue}
            formatXTick={formatXTick}
            formatTooltipTitle={formatTooltipTitle}
            translations={conditionTrendTranslations}
            greyScale={isFailed}
            overlay={isFailed}
            OverlayComponent={OverlayComponent}
            sampling="none"
          />
        </div>
        <SubscoresListModal
          className="subscores-list-modal"
          visible={modalState?.visible}
          title={modalState?.title}
          subscores={modalState?.subscores}
          onCancel={handleCancel}
          width="60vw"
          formatValue={formatValue}
          translations={subscoresListModalTranslations}
        />
      </div>
    ) : (
      <Processing />
    );
  }
);

const StyledConditionTrendWidget = styled(ConditionTrendWidget)`
  .condition-trend-wrapper {
    height: 100%;
  }
`;

export default StyledConditionTrendWidget;
