// Copyright 2016-2023 Hitachi Energy. All rights reserved.

declare global {
  interface Object {
    deepClone<T>(obj: T): T;
  }
  interface String {
    linkify(): JSX.Element[];
  }
}

import "billboard.js/dist/billboard.css";
import RouterProvider from "core/app/components/RouterProvider";
import AppContainer from "core/app/containers/AppContainer";
import SettingsProviderContainer from "core/app/containers/SettingsProviderContainer";
import TranslationsProviderContainer from "core/app/containers/TranslationsProviderContainer";
import VersionProviderContainer from "core/app/containers/VersionProviderContainer";
import AuthorizationService from "core/app/services/AuthorizationService";
import CsrfService from "core/app/services/CsrfService";
import "extensions/Console";
import "extensions/JQuery";
import "extensions/Object";
import "extensions/String";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import store from "store";
import "./styles/less/index.less";
require("billboard.js/dist/billboard.css");

AuthorizationService.setupAuthorization();

CsrfService.setupCsrfProtection().then(() => {
  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <ReduxProvider store={store}>
      <RouterProvider>
        <SettingsProviderContainer>
          <TranslationsProviderContainer>
            <VersionProviderContainer>
              <AppContainer />
            </VersionProviderContainer>
          </TranslationsProviderContainer>
        </SettingsProviderContainer>
      </RouterProvider>
    </ReduxProvider>
  );
});
