// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import IssueCompactContainer from "features/detailpage/features/issues/containers/IssueCompactContainer";
import IssueDetailsContainer from "features/detailpage/features/issues/containers/IssueDetailsContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";

import "./Issue.less";

interface IIssueProps {
  currentIssueId: string;
  disabled?: boolean;
  issue: IIssue;
  meta: IIssueMeta;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onSaved?: (issueId: string) => void;
  onStatusSaved?: (issueId: string) => void;
  readonly?: boolean;
  urgencies: string[];
  isComponentLevel?: boolean;
}

class Issue extends React.Component<IIssueProps> {
  render() {
    const { handleIssueClick, handleIssueSaved, handleIssueStatusSaved } = this;
    const {
      currentIssueId,
      disabled,
      issue,
      meta,
      readonly,
      urgencies,
      isComponentLevel
    } = this.props;

    const isCurrent = issue.Id === currentIssueId;

    return (
      <div
        className={`
          issue
          ${disabled ? "disabled" : ""}
          ${isCurrent ? "active" : ""}
        `}
        onClick={handleIssueClick}
      >
        {!isCurrent && (
          <IssueCompactContainer
            issue={issue}
            meta={meta}
            readonly={readonly}
          />
        )}
        {isCurrent && (
          <IssueDetailsContainer
            issue={issue}
            meta={meta}
            onSaved={handleIssueSaved}
            onStatusSaved={handleIssueStatusSaved}
            readonly={readonly}
            urgencies={urgencies}
            isComponentLevel={isComponentLevel}
          />
        )}
        {disabled && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="overlay"
          />
        )}
      </div>
    );
  }

  private handleIssueClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  private handleIssueSaved = () => {
    const { issue, onSaved } = this.props;
    if (onSaved) onSaved(issue.Id);
  };

  private handleIssueStatusSaved = () => {
    const { issue, onStatusSaved } = this.props;
    if (onStatusSaved) onStatusSaved(issue.Id);
  };
}

export default Issue;
