// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Actions as TranslationsActions } from "core/app/actions/TranslationsActions";
import settingsReducer, {
  IState as ISettingsState
} from "core/app/reducers/settings/SettingsReducer";
import translationsReducer, {
  IState as ITranslationsState
} from "core/app/reducers/TranslationsReducer";
import versionReducer, {
  IState as IVersionState
} from "core/app/reducers/VersionReducer";
import AppService from "core/app/services/AppService";
import { IState as IRootState } from "reducers/Index";
import { Action, Reducer } from "redux/index";

export interface IState
  extends ISettingsState,
    ITranslationsState,
    IVersionState {
  locale: string;
}

const appReducer: Reducer<IState> = (
  state = {
    customer: null,
    customerVisualizations: null,
    customerVisualizationsAssetGrid: null,
    customerVisualizationsIssueGrid: null,
    customerVisualizationsCommandIntegration: null,
    customerIssueSettings: null,
    environment: null,
    locale: null,
    mapProvider: null,
    tenants: null,
    translations: null,
    user: null,
    version: {
      ui: null
    }
  },
  action: Action
) => {
  state = settingsReducer(state, action) as IState;
  state = translationsReducer(state, action) as IState;
  state = versionReducer(state, action) as IState;

  switch (action.type) {
    case TranslationsActions.Translations_SetTranslation:
      return AppService.updateLocale(state);
    default:
      return state;
  }
};

export default appReducer;

// #region Selectors

export const getUserSelector = (state: IRootState) => state.app.user;

// #endregion
