// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IColumn } from "common/datagrid/DataGrid";
import HeaderCell from "common/datagrid/HeaderCell";

export interface IHeaderRowProps {
  columns: IColumn[];
  onColumnClick?: (columnId: string, modifierIsPressed: boolean) => void;
  showCheckboxColumn?: boolean;
  onCheckAll?: (e: CheckboxChangeEvent, isIndeterminate: boolean) => void;
  checkedAll?: boolean;
  isCheckAllPossible?: boolean;
}

const HeaderRow = (props: IHeaderRowProps) => {
  const {
    columns,
    onColumnClick,
    showCheckboxColumn,
    onCheckAll,
    checkedAll = false,
    isCheckAllPossible
  } = props;
  const checkboxHeader = (
    <div
      className={`
      grid-cell default-grey-label checkbox-column-header
    `}
    >
      {isCheckAllPossible && (
        <Checkbox
          indeterminate={checkedAll === null}
          onChange={(e) => onCheckAll?.(e, checkedAll === null)}
          checked={checkedAll}
        />
      )}
    </div>
  );

  const cells = columns.map((c) => (
    <HeaderCell
      key={c.config.id}
      column={c}
      columns={columns}
      onColumnClick={onColumnClick}
    />
  ));

  return (
    <div className="grid-row">
      {showCheckboxColumn && checkboxHeader}
      {cells}
    </div>
  );
};

export default HeaderRow;
