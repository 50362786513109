// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { DegradationScoreService } from "common/formatters/DegradationScore";
import React from "react";
import { IntlShape } from "react-intl";
import IAssetPoint from "../../../models/IAssetPoint";
import useAssetRiskMatrixTooltip from "../hooks/useAssetRiskMatrixTooltip";
import "./AssetRiskMatrixTooltip.less";

interface IAssetRiskMatrixTooltipProps {
  assetPoint: IAssetPoint;
  intl: IntlShape;
}

const AssetRiskMatrixTooltip = ({
  assetPoint,
  intl
}: IAssetRiskMatrixTooltipProps) => {
  const { AssetId, Importance, Score, Risk } = assetPoint;
  const { getRiskCssClass } = useAssetRiskMatrixTooltip();
  const riskCssClass = `bb-tooltip-name-risk bb-tooltip-name-risk-${getRiskCssClass(
    assetPoint
  )}`;

  return (
    <table className="bb-tooltip asset">
      <tr>
        <th colSpan={2}>{AssetId}</th>
      </tr>
      <tr className="bb-tooltip-name-importance">
        <td className="name">
          <span />
          {intl.formatMessage({
            id: "asset_risk_chart.y_axis.label",
            defaultMessage: "Importance"
          })}
        </td>
        <td className="value">{intl.formatNumber(Importance)}</td>
      </tr>
      <tr className="bb-tooltip-name-score">
        <td className="name">
          <span />
          {intl.formatMessage({
            id: "asset_risk_chart.x_axis.label",
            defaultMessage: "Condition"
          })}
        </td>
        <td className="value">{DegradationScoreService.format(Score, intl)}</td>
      </tr>
      <tr className={riskCssClass}>
        <td className="name">
          <span />
          {intl.formatMessage({
            id: "asset_risk_chart.tooltip.risk_label",
            defaultMessage: "Risk"
          })}
        </td>
        <td className="value">
          {intl.formatMessage({
            id: "risk." + Risk,
            defaultMessage: Risk
          })}
        </td>
      </tr>
    </table>
  );
};

export default AssetRiskMatrixTooltip;
