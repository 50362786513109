// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  IDataGridCellProps,
  IDataGridRowProps
} from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { inspectionConfiguration } from "features/ConfigurationTool/constants/inspectionConfiguration";
import { isBoolean, isObject } from "lodash";
import IField from "models/IField";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { config } from "utils/AppConfig";
import IAssetParameter from "../models/IAssetParameter";
import IInspection from "../models/IInspection";
import IInspectionParameter from "../models/IInspectionParameter";
import useParametersDataValidation from "./useParametersDataValidation";

const useBulkInspection = (assetParameters: IAssetParameter[]) => {
  const [initialData, setInitialData] = useState<
    IDataGridRowProps<IInspectionParameter>[]
  >([]);
  const [parametersData, setParametersData] = useState<
    IDataGridRowProps<IInspectionParameter>[]
  >([]);
  const [showOnlyInvalid, setShowOnlyInvalid] = useState<boolean>(false);

  const getFields = useCallback((object: any, keysList: IField[]) => {
    if (object.hasOwnProperty("fieldKey")) {
      keysList.push(object);
    }

    for (let i = 0; i < Object.keys(object).length; i++) {
      if (typeof object[Object.keys(object)[i]] == "object") {
        getFields(object[Object.keys(object)[i]], keysList);
      }
    }
  }, []);

  const getConfiguredFields = useCallback(() => {
    const parametersKeys: IField[] = [];

    const forms = inspectionConfiguration.forms;
    for (let i = 0; i < Object.keys(forms).length; i++) {
      getFields(forms[i], parametersKeys);
    }

    return parametersKeys;
  }, [getFields]);

  const configuredFields: IField[] = useMemo(() => {
    return getConfiguredFields();
  }, [getConfiguredFields]);

  const configuredParameterNames: string[] = useMemo(
    () => configuredFields.map((field) => field.fieldKey),
    [configuredFields]
  );

  const intl = useIntl();
  const {
    validateAssetId,
    validateParameterName,
    validateDate,
    validateValueType,
    validateValue
  } = useParametersDataValidation(configuredParameterNames);

  useEffect(() => {
    const data = assetParameters.map((assetParameter, index) => {
      let cells: IDataGridCellProps<IInspectionParameter>[] = [];
      const fieldConfig = configuredFields.find(
        (field) => field.fieldKey === assetParameter.ModelParameter.InputName
      );

      const getFieldValue = () => {
        const value = assetParameter.ModelParameter.Value;
        const valueType = assetParameter.ModelParameter.ValueType;
        if (isBoolean(value)) {
          return assetParameter.ModelParameter.Value;
        } else {
          if (valueType === "DateTime") {
            return dayjs(value);
          } else {
            return assetParameter.ModelParameter.Value;
          }
        }
      };

      const timeStamp = dayjs(assetParameter.ModelParameter.Timestamp);

      cells.push({
        cellName: "assetId",
        cellValue: assetParameter.AssetId,
        ...validateAssetId(assetParameter.AssetId)
      });
      cells.push({
        cellName: "inputName",
        cellValue: assetParameter.ModelParameter.InputName,
        ...validateParameterName(assetParameter.ModelParameter.InputName)
      });
      cells.push({
        cellName: "value",
        cellValue: isBoolean(getFieldValue())
          ? String(getFieldValue())
          : getFieldValue(),
        ...validateValue(
          isBoolean(getFieldValue())
            ? String(getFieldValue())
            : getFieldValue(),
          assetParameter.ModelParameter.ValueType,
          fieldConfig,
          assetParameter.ModelParameter.AssetType
        )
      });
      cells.push({
        cellName: "valueType",
        cellValue: assetParameter.ModelParameter.ValueType,
        ...validateValueType(
          assetParameter.ModelParameter.ValueType,
          fieldConfig,
          assetParameter.ModelParameter.AssetType
        )
      });
      cells.push({
        cellName: "timestamp",
        cellValue: timeStamp,
        ...validateDate(timeStamp)
      });
      cells.push({
        cellName: "assetType",
        cellValue: assetParameter.ModelParameter.AssetType,
        valid: true
      });
      return {
        id: index,
        cells: cells
      };
    });

    setParametersData(data);
    setInitialData(data);
  }, [
    assetParameters,
    configuredFields,
    intl,
    validateAssetId,
    validateDate,
    validateParameterName,
    validateValue,
    validateValueType
  ]);

  const updateParametersData = useCallback(
    (newData: IDataGridRowProps<IInspectionParameter>[]) => {
      setParametersData(newData);
    },
    []
  );

  const filterDataWithInvalidInfo = useCallback(
    (showInvalid: boolean) =>
      setInitialData(
        showInvalid
          ? parametersData.filter(
              (param) => param.cells.filter((cell) => !cell.valid).length > 0
            )
          : parametersData
      ),
    [parametersData]
  );

  const handleShowOnlyInvalid = useCallback(
    (showInvalid: boolean) => {
      setShowOnlyInvalid(showInvalid);
      filterDataWithInvalidInfo(showInvalid);
    },
    [filterDataWithInvalidInfo]
  );

  const handleVerifyData = useCallback(() => {
    filterDataWithInvalidInfo(showOnlyInvalid);
  }, [filterDataWithInvalidInfo, showOnlyInvalid]);

  const getInspectionData = useCallback(() => {
    const inspectionParameters = parametersData.map(({ cells }) => {
      return cells.reduce((acc, val) => {
        return {
          ...acc,
          [val.cellName]: val.cellValue
        };
      }, {}) as IInspectionParameter;
    });

    const uniqueAssetIds = [
      ...Array.from(new Set(inspectionParameters.map((p) => p.assetId)))
    ];

    const getDisplayValue = (
      value: number | boolean | string | dayjs.Dayjs,
      valueType: "Decimal" | "String" | "DateTime" | "Bool"
    ) => {
      if (isObject(value)) {
        return value.toISOString();
      }

      if (valueType === "Bool") {
        return value === "true";
      }

      return value;
    };

    const parametersToSend = uniqueAssetIds.map((assetId): IInspection => {
      return {
        AssetId: assetId,
        Parameters: inspectionParameters
          .filter((params) => params.assetId === assetId)
          .map((parameter) => {
            return {
              InputName: parameter.inputName,
              Timestamp: parameter.timestamp.toISOString(),
              Value: getDisplayValue(parameter.value, parameter.valueType),
              ValueType: parameter.valueType
            };
          })
      };
    });

    return parametersToSend;
  }, [parametersData]);

  const handleSubmit = useCallback(() => {
    const url = UrlService.getApiUrl(config.api.settings.data.inspectionUrl);

    return new Promise<string>(function (resolve, reject) {
      const inspectionData = getInspectionData();

      EndpointService.post<IInspection[]>(
        url,
        (request, data) => {
          resolve(request);
        },
        (request) => {
          reject(request.responseJSON);
        },
        inspectionData,
        "application/json; charset=utf-8"
      );
    });
  }, [getInspectionData]);

  return {
    initialData,
    parametersData,
    configuredFields,
    configuredParameterNames,
    getConfiguredFields,
    updateParametersData,
    showOnlyInvalid,
    handleShowOnlyInvalid,
    handleVerifyData,
    handleSubmit
  };
};

export default useBulkInspection;
