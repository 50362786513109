// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";

const getIsKinectricsTransformerSiliconeModel = (implementationId: string) => {
  return (
    implementationId?.toLowerCase() ===
    AssetImplementationId.KinectricsTransformerSiliconeIEEE
  );
};

export default getIsKinectricsTransformerSiliconeModel;
