// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";
import * as React from "react";
import { FormattedDate } from "react-intl";
import styled from "styled-components";
import { spacingMedium, spacingSmall } from "styles/StyleVariables";

interface ILatestParameterDateProps {
  date: string;
  className?: string;
}

const LatestParameterDate = (props: ILatestParameterDateProps) => {
  const { date, className } = props;

  return (
    <div className={`${className} column latest-parameter-date`}>
      {date === undefined || date == null ? (
        <span>-</span>
      ) : (
        <FormattedDate
          value={dayjs(date).toDate()}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      )}
    </div>
  );
};

const StyledLatestParameterDate = styled(LatestParameterDate)`
  &.column.latest-parameter-date {
    padding: ${spacingMedium} ${spacingSmall};
  }
`;

export default StyledLatestParameterDate;
