// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { NavLink } from "react-router-dom";

import ISingleImportantIsses from "../models/ISingleImportantIssue";

import { routes } from "core/app/components/AppRoutes";
import useOpenIssuesGrid from "../hooks/useOpenIssuesGrid";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";

interface IOpenIssuesGridProps {
  issues: ISingleImportantIsses[];
}

const OpenIssuesGrid = ({ issues }: IOpenIssuesGridProps) => {
  const { gridData } = useOpenIssuesGrid({ issues });
  return (
    <div className="grid">
      <div className="name" data-qa="most-important-title">
        <FormattedMessage
          id="homepage.opened_issues.grid.name"
          defaultMessage="Most important issues"
        />
      </div>
      {isEmpty(gridData) ? (
        <FormattedMessage
          id="homepage.opened_issues.grid.no_data"
          defaultMessage="No data available"
        />
      ) : (
        gridData.map(
          (
            { description, assetIdWithName, scheduledDate, searchParams },
            index
          ) => (
            <div key={index} className="c-row" data-qa="issue">
              <div
                className="description"
                data-qa="description"
                title={description}
              >
                {description && description.length > 50
                  ? description.substr(0, 50) + "..."
                  : description}
              </div>
              <div className="details">
                <div
                  className="asset"
                  data-qa="asset-on-issues-tile"
                  title={assetIdWithName}
                >
                  <TooltipWrapper
                    Tooltip={() => <>{assetIdWithName}</>}
                    theme={Themes.Dark}
                  >
                    <NavLink
                      className="anchor link-label"
                      to={{
                        pathname: routes.detailPage.pathname,
                        search: searchParams.toString()
                      }}
                    >
                      {assetIdWithName}
                    </NavLink>
                  </TooltipWrapper>
                </div>
              </div>
              <div className="time" data-qa="date">
                {scheduledDate ? (
                  <FormattedRelativeTime
                    value={dayjs(scheduledDate).local().toDate().getTime()}
                  />
                ) : null}
              </div>
            </div>
          )
        )
      )}
    </div>
  );
};

export default OpenIssuesGrid;
