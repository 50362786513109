// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";

const getIsKinectricsTransformerMineralOilModel = (
  implementationId: string
) => {
  return (
    implementationId?.toLowerCase() ===
    AssetImplementationId.KinectricsTransformerMineralOilIEC
  );
};

export default getIsKinectricsTransformerMineralOilModel;
