// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IUser } from "core/app/reducers/settings/UserReducer";
import Data from "core/data/models/Data";
import getCustomerVisualizationsCommandIntegrationSelector from "core/selectors/getCustomerVisualizationsCommandIntegrationSelector";
import { useMemo } from "react";
import { useAppSelector } from "store";
import LifecycleStatus from "../../../models/LifecycleStatus";
import useIsReadOnlyData from "./useIsReadOnlyData";

const useIsCommandIntegrationEnabledData = (
  lifecycleStatus: LifecycleStatus,
  user: Data<IUser>
) => {
  const customerVisualizationsCommandIntegration = useAppSelector(
    getCustomerVisualizationsCommandIntegrationSelector
  );
  const isReadOnlyData = useIsReadOnlyData(lifecycleStatus, user);
  return useMemo(
    () =>
      (!customerVisualizationsCommandIntegration.data?.IsIntegrationEnabled ??
        true) ||
      isReadOnlyData,
    [customerVisualizationsCommandIntegration, isReadOnlyData]
  );
};
export default useIsCommandIntegrationEnabledData;
