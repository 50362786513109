// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { RogersRatio2DPoint } from "../../../models/RogersRatio2DData";

const mapDataToChart2 = (data: RogersRatio2DPoint[]) =>
  data.map((d) => ({
    date: new Date(d.date),
    x: d.ethyleneC2H4 / d.ethaneC2H6,
    y: d.acetyleneC2H2 / d.ethaneC2H6
  }));

export default mapDataToChart2;
