// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  bar,
  ChartOptions,
  DataItem,
  GridLineOptions,
  PrimitiveArray,
  RegionOptions
} from "billboard.js";
import { useState } from "react";
import { useIntl } from "react-intl";
import IVoltageData from "../models/IVoltageData";

const dataColor = "#ADADAD";
const limitDataColor = "rgba(255, 115, 0, 0.75)";

const useChartConfiguration = (
  voltageData: IVoltageData,
  maxArrowSize: number
) => {
  const intl = useIntl();
  const xs = voltageData.Cells.map((i) => i.Index.toString());
  const values = voltageData.Cells.map((c) => c.Voltage);
  const columns: PrimitiveArray[] = [
    ["x", ...xs],
    ["voltage", ...values]
  ];

  const lowLevel = voltageData.LowerLevel;
  const highLevel = voltageData.UpperLevel;
  const nominalLevel = voltageData.ReferenceLevel;

  const noEmptyValues = values.filter((v) => v !== undefined && v !== null);
  const minValue = Math.min(...noEmptyValues, lowLevel);
  const maxValue = Math.max(...noEmptyValues, highLevel);
  const axisYMin = Math.max(minValue - 0.1 * (maxValue - minValue), 0);
  const axisYMax = maxValue + 0.1 * (maxValue - minValue);

  let lines: GridLineOptions[];
  let regions: RegionOptions[];
  if (
    (lowLevel !== undefined && lowLevel != null) ||
    (highLevel !== undefined && highLevel != null) ||
    (nominalLevel !== undefined && nominalLevel != null)
  ) {
    lines = [
      {
        value: lowLevel,
        class: "low-level",
        text: intl.formatNumber(lowLevel),
        position: "start"
      },
      {
        value: highLevel,
        class: "high-level",
        text: intl.formatNumber(highLevel),
        position: "start"
      },
      {
        value: nominalLevel,
        class: "nominal-level",
        text: intl.formatNumber(nominalLevel),
        position: "start"
      }
    ];

    regions = [
      {
        axis: "y",
        class: "upper-limit",
        start: highLevel
      },
      {
        axis: "y",
        class: "lower-limit",
        end: lowLevel
      }
    ];
  }

  const configuration: ChartOptions = {
    data: {
      x: "x",
      columns,
      type: bar(),
      colors: {
        voltage: function (d: DataItem) {
          if (highLevel !== null && lowLevel === null) {
            return d.value > highLevel ? limitDataColor : dataColor;
          } else if (highLevel === null && lowLevel !== null) {
            return d.value < lowLevel ? limitDataColor : dataColor;
          } else if (highLevel !== null && lowLevel !== null) {
            return d.value > highLevel || d.value < lowLevel
              ? limitDataColor
              : dataColor;
          } else return dataColor;
        }
      }
    },
    line: {
      connectNull: true
    },
    bar: {
      width: {
        ratio: 0.5
      }
    },
    grid: {
      y: {
        show: true,
        lines
      },
      x: {
        show: false
      }
    },
    axis: {
      x: {
        type: "category",
        show: true,
        label: {
          text: intl.formatMessage({
            defaultMessage: "Cell #",
            id: "detail_page.parameters.battery_voltage.x_axis_label"
          }),
          position: "outer-right"
        },
        tick: {
          rotate: values.length > 32 ? 75 : 0,
          multiline: false
        }
      },
      y: {
        show: true,
        label: {
          text: intl.formatMessage({
            defaultMessage: "V",
            id: "detail_page.parameters.battery_voltage.y_axis_label"
          }),
          position: "outer-top"
        },
        tick: {
          format: function (v: number) {
            return intl.formatNumber(v);
          }
        },
        padding: {
          top: 1.2 * maxArrowSize,
          bottom: 0
        },
        min: axisYMin,
        max: axisYMax
      }
    },
    legend: {
      show: false
    },
    padding: {
      top: 20,
      right: 50
    },
    tooltip: {
      format: {
        name: (name) =>
          intl.formatMessage({
            defaultMessage: name,
            id: `detail_page.parameters.battery_voltage.${name}_tooltip`
          })
      }
    }
  };

  if (regions) configuration.regions = regions;
  if (lines) configuration.grid.y.lines = lines;

  return useState(configuration);
};

export default useChartConfiguration;
