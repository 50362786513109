// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export enum AssetRiskEnum {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  UNKNOWN = "Unknown"
}

type AssetRisks = "High" | "Medium" | "Low" | "Unknown";
export default AssetRisks;
