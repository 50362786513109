import { SearchParams } from "@pg/common";
import { SearchParamsNames } from "@pg/common/build/components/FilterPanel/hooks/useFilterPanel";
import LifecycleStatus from "models/LifecycleStatus";
import DataService from "common/datagrid/services/DataService";
import SortOrders from "common/datagrid/models/SortOrders";
import { routes } from "core/app/components/AppRoutes";
import { AssetRiskEnum } from "models/AssetRisks";

const getAssetRiskTrendLinks = () => {
  const baseUrl = `${routes.assets.pathname}?`;
  const assetMonitoredParams = new SearchParams();
  const assetNotMonitoredParams = new SearchParams();

  for (const param of [assetMonitoredParams, assetNotMonitoredParams]) {
    param.append(
      SearchParamsNames.AssetStatus,
      JSON.stringify([LifecycleStatus.InService])
    );
    param.append(
      "c_MaintenancePriority",
      DataService.sortOrderToString(SortOrders.Desc)
    );
  }

  assetMonitoredParams.appendAll(SearchParamsNames.AssetRisk, [
    JSON.stringify([AssetRiskEnum.HIGH]),
    JSON.stringify([AssetRiskEnum.MEDIUM]),
    JSON.stringify([AssetRiskEnum.LOW])
  ]);

  assetNotMonitoredParams.append(
    SearchParamsNames.AssetRisk,
    JSON.stringify([AssetRiskEnum.UNKNOWN])
  );

  return {
    assetsMonitored: `${baseUrl}${decodeURIComponent(
      assetMonitoredParams.toString()
    )}`,
    assetsNotMonitored: `${baseUrl}${decodeURIComponent(
      assetNotMonitoredParams.toString()
    )}`
  };
};

export default getAssetRiskTrendLinks;
