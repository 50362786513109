// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ConfigurationGrid, {
  IDataGridCellProps,
  IDataGridProps
} from "@apm/widgets/build/widgets/DataGrid";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "store";
import loadConfigurationGridData from "./actions/loadConfigurationGridData";
import removeConfigurationGridData from "./actions/removeConfigurationGridData";
import "./ModelConfigurationGrid.less";
import IConfigurationGridRow from "./models/IConfigurationGridRow";
import getConfigurationDataSelector from "./selectors/getConfigurationDataSelector";

export interface IModelConfigurationGridProps {
  modelId: string;
  modelImplementationId: string;
  configurationName: string;
}

type ModelConfigurationGridProps = IModelConfigurationGridProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ModelConfigurationGrid = ({
  modelId,
  modelImplementationId,
  configurationName,
  configurationData,
  loadConfigurationGridData,
  removeConfigurationGridData
}: ModelConfigurationGridProps) => {
  const intl = useIntl();

  const configurationGridColumns: IDataGridProps<IConfigurationGridRow>["columns"] =
    useMemo(
      () => [
        {
          field: "Subsystem",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.subsystem",
            defaultMessage: "Subsystem"
          }),
          minWidth: 126,
          width: 126,
          resizable: true,
          pinned: "left",
          filterType: "textFilter"
        },
        {
          field: "Condition",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.condition",
            defaultMessage: "Parameter"
          }),
          minWidth: 320,
          width: 320,
          resizable: true,
          pinned: "left",
          filterType: "textFilter"
        },
        {
          field: "Unit",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.unit",
            defaultMessage: "Unit of Measurement"
          }),
          minWidth: 176,
          flex: 1.3,
          resizable: true,
          filterType: "textFilter"
        },
        {
          field: "Importance",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.importance",
            defaultMessage: "Parameter Importance"
          }),
          minWidth: 114,
          flex: 1.1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "CalculationType",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.calculation_type",
            defaultMessage: "Calculation Type"
          }),
          minWidth: 114,
          flex: 1.1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "LowLimit",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.low_limit",
            defaultMessage: "Low Limit"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "HighLimit",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.high_limit",
            defaultMessage: "High Limit"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "RelativeValue",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.relative_value",
            defaultMessage: "Relative Value"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "BreakPoint0",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.break_point0",
            defaultMessage: "Break Point 0"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "BreakPoint1",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.break_point1",
            defaultMessage: "Break Point 1"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "BreakPoint2",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.break_point2",
            defaultMessage: "Break Point 2"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "BreakPoint3",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.break_point3",
            defaultMessage: "Break Point 3"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter"
        },
        {
          field: "BreakPoint4",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.break_point4",
            defaultMessage: "Break Point 4"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "AgeScale",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.age_scale",
            defaultMessage: "Age Scale"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "AgeStandard",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.age_standard",
            defaultMessage: "Age Standard"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        },
        {
          field: "AgeMax",
          headerName: intl.formatMessage({
            id: "detail_page.model_configuration.grid.columns.age_max",
            defaultMessage: "Age Max"
          }),
          minWidth: 96,
          flex: 1,
          resizable: true,
          filterType: "numberFilter",
          valueFormatter: (value: any) => intl.formatNumber(value),
          textAlign: "rightAligned"
        }
      ],
      [intl]
    );

  const data = useMemo(() => {
    if (configurationData?.data) {
      const data = configurationData?.data?.RowData.map(
        (item, index: number) => {
          const cells: IDataGridCellProps<IConfigurationGridRow>[] = [];
          cells.push(
            {
              cellName: "AgeMax",
              cellValue: item.AgeMax
            },
            {
              cellName: "HighLimit",
              cellValue: item.HighLimit
            },
            {
              cellName: "BreakerType",
              cellValue: item.BreakerType
            },
            {
              cellName: "Subsystem",
              cellValue: intl.formatMessage({
                id: `${modelId}.subscore.${item.Subsystem}`,
                defaultMessage: intl.formatMessage({
                  id: `${modelImplementationId}.subscore.${item.Subsystem}`,
                  defaultMessage: item.Subsystem
                })
              })
            },
            {
              cellName: "Condition",
              cellValue: intl.formatMessage({
                id: `${modelId}.parameter.${item.Condition}`,
                defaultMessage: intl.formatMessage({
                  id: `${modelImplementationId}.parameter.${item.Condition}`,
                  defaultMessage: item.Condition
                })
              })
            },
            {
              cellName: "Importance",
              cellValue: item.Importance
            },
            {
              cellName: "CalculationType",
              cellValue: item.CalculationType
            },
            {
              cellName: "LowLimit",
              cellValue: item.LowLimit
            },
            {
              cellName: "RelativeValue",
              cellValue: item.RelativeValue
            },
            {
              cellName: "BreakPoint0",
              cellValue: item.BreakPoint0
            },
            {
              cellName: "BreakPoint1",
              cellValue: item.BreakPoint1
            },
            {
              cellName: "BreakPoint2",
              cellValue: item.BreakPoint2
            },
            {
              cellName: "BreakPoint3",
              cellValue: item.BreakPoint3
            },
            {
              cellName: "BreakPoint4",
              cellValue: item.BreakPoint4
            },
            {
              cellName: "Unit",
              cellValue: item.Unit
            },
            {
              cellName: "AgeScale",
              cellValue: item.AgeScale
            },
            {
              cellName: "AgeStandard",
              cellValue: item.AgeStandard
            },
            {
              cellName: "ParameterCheck",
              cellValue: item.ParameterCheck
            }
          );
          return {
            id: index,
            cells: cells
          };
        }
      );
      return data;
    }
    return [];
  }, [configurationData?.data, intl, modelId, modelImplementationId]);

  useEffect(() => {
    loadConfigurationGridData(modelId, configurationName);
    return () => {
      removeConfigurationGridData(modelId, configurationName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  return (
    <div className="model-configuration-grid-container data-grid__parent grid-container ">
      <div className="model-configuration-grid-scroll data-grid__scroll">
        {configurationData?.status === Statuses.Loading && <Processing />}
        {configurationData?.status === Statuses.Succeeded && (
          <ConfigurationGrid
            columns={configurationGridColumns}
            data={data}
            sizeColumnsToFit={false}
            noRowsMessage={intl.formatMessage({
              id: "detail_page.model_configuration.grid.no_rows_to_show",
              defaultMessage: "No rows to show"
            })}
          />
        )}
        {configurationData?.status === Statuses.Failed && (
          <WidgetErrorMessage />
        )}
        {configurationData?.status === Statuses.NotFound && (
          <WidgetNoDataMessage />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IModelConfigurationGridProps
) => ({
  configurationData: getConfigurationDataSelector(state)(
    ownProps.modelId,
    ownProps.configurationName
  )
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadConfigurationGridData: (modelId: string, configurationName: string) =>
    dispatch(loadConfigurationGridData(modelId, configurationName)),
  removeConfigurationGridData: (modelId: string, configurationName: string) =>
    dispatch(removeConfigurationGridData(modelId, configurationName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelConfigurationGrid);
