// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getNotificationsFormSelector, {
  NotificationsFormFields
} from "./getNotificationsFormSelector";

const hasValidDataSelector = createSelector(
  getNotificationsFormSelector,
  (form) => {
    if (form.inputs.size) {
      const degradedToRed = form.getIn([
        "inputs",
        NotificationsFormFields.degradesRed,
        "value"
      ]) as boolean;
      const degradedToYellow = form.getIn([
        "inputs",
        NotificationsFormFields.degradesYellow,
        "value"
      ]) as boolean;
      const improvedToGreen = form.getIn([
        "inputs",
        NotificationsFormFields.improvesGreen,
        "value"
      ]) as boolean;
      const improvedToYellow = form.getIn([
        "inputs",
        NotificationsFormFields.improvesYellow,
        "value"
      ]) as boolean;

      const organizations = form.getIn([
        "inputs",
        NotificationsFormFields.organizations,
        "value"
      ]) as string[];

      const assetTypes = form.getIn([
        "inputs",
        NotificationsFormFields.assetTypes,
        "value"
      ]) as string[];

      return (
        (degradedToRed ||
          degradedToYellow ||
          improvedToGreen ||
          improvedToYellow) &&
        organizations.length > 0 &&
        assetTypes.length > 0
      );
    }

    return false;
  }
);

export default hasValidDataSelector;
