// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAction } from "features/issues/reducers/IssuesReducer";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Issues_SetActions = "Issues_SetActions"
}

export const setActionsAction = (
  request: JQueryXHR,
  data?: IAction[]
): IAjaxAction<IAction[]> => {
  const action: IAjaxAction<IAction[]> = {
    type: Actions.Issues_SetActions,
    request,
    data
  };
  return action;
};

export const loadActions = (dispatch: AppDispatch, issueId: string) => {
  const url = UrlService.getApiUrl(config.api.watchlist.actionsUrl, {
    issueId
  });

  const request = EndpointService.getJson<IAction[]>(
    url,
    (request, data) => {
      dispatch(setActionsAction(request, data));
    },
    (request) => {
      dispatch(setActionsAction(request));
    }
  );

  dispatch(setActionsAction(request));
};
