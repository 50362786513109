// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import "components/common/CustomLegend.less";
import * as React from "react";
import { FormattedMessage } from "react-intl";

enum LegendState {
  Normal,
  Clicked,
  Focused
}

export interface ILegendItem {
  id: string;
  background: string;
  messageId: string;
  defaultMessage: string;
}

interface ILegendItemState extends ILegendItem {
  state: LegendState;
}

interface ICustomLegendProps {
  items: ILegendItem[];
  onMouseOver?: (id: string) => void;
  onMouseOut?: (id: string) => void;
  onClick?: (id: string) => void;
}

class CustomLegend extends React.Component<ICustomLegendProps, {}> {
  private itemState: ILegendItemState[] = null;

  constructor(props: ICustomLegendProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    this.itemState = (this.props.items || []).map<ILegendItemState>((i) => {
      return {
        id: i.id,
        background: i.background,
        messageId: i.messageId,
        defaultMessage: i.defaultMessage,
        state: LegendState.Normal
      };
    });

    this.setState(this.state);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ICustomLegendProps) {
    const newItems = (nextProps.items || []).map<ILegendItemState>((i) => {
      return {
        id: i.id,
        background: i.background,
        messageId: i.messageId,
        defaultMessage: i.defaultMessage,
        state: LegendState.Normal
      };
    });

    for (const item of this.itemState) {
      const foundItems = newItems.filter((i) => i.id === item.id);
      if (foundItems && foundItems.length > 0) foundItems[0].state = item.state;
    }

    this.itemState = newItems;
    this.setState(this.state);
  }

  public render() {
    return (
      <div className="bb-custom-legend bb-legend">
        <div>
          {(this.itemState || []).map((x) => {
            return (
              <span
                key={x.id}
                ref={x.id}
                data-id={x.id}
                className="bb-legendItem"
                onMouseOver={() => {
                  if (this.props.onMouseOver) this.props.onMouseOver(x.id);

                  if (x.state === LegendState.Normal) {
                    x.state = LegendState.Focused;
                  }

                  for (const item of this.itemState) {
                    if (item.id !== x.id && item.state === LegendState.Normal) {
                      (this.refs as any)[item.id].style.opacity = 0.4;
                    }
                  }
                }}
                onMouseOut={() => {
                  if (this.props.onMouseOut) this.props.onMouseOut(x.id);

                  if (x.state === LegendState.Focused) {
                    x.state = LegendState.Normal;
                  }

                  for (const item of this.itemState) {
                    if (item.id !== x.id && item.state === LegendState.Normal) {
                      (this.refs as any)[item.id].style.opacity = 1;
                    }
                  }
                }}
                onClick={() => {
                  if (this.props.onClick) this.props.onClick(x.id);

                  if (
                    x.state === LegendState.Normal ||
                    x.state === LegendState.Focused
                  ) {
                    x.state = LegendState.Clicked;
                    (this.refs as any)[x.id].style.opacity = 0.2;
                  } else {
                    x.state = LegendState.Focused;
                    (this.refs as any)[x.id].style.opacity = 1;
                  }
                }}
              >
                <span
                  className="bb-legendBox"
                  style={{
                    background: x.background
                  }}
                />
                <span className="bb-legendText">
                  <FormattedMessage
                    id={x.messageId}
                    defaultMessage={x.defaultMessage}
                  />
                </span>
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CustomLegend;
