// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

import getNotificationsFormSelector, {
  NotificationsFormFields
} from "./getNotificationsFormSelector";

import { INotificationsModelOutput } from "../models/INotificationsModelOutput";

import Form from "common/form/models/Form";
import NotificationsService from "../services/NotificationsService";

const getNotificationsModelOutputSelector = createSelector(
  getNotificationsFormSelector,
  (state: IState) => state.app.locale,
  (form, locale) => (isEmptyPayload?: boolean) => {
    if (!isEmptyPayload && form.inputs.size) {
      const modelOutput = createModelOutput(form, locale);
      return modelOutput;
    } else {
      return {
        DegradedToRed: false,
        DegradedToYellow: false,
        ImprovedToGreen: false,
        ImprovedToYellow: false,
        Locale: locale,
        Organizations: null,
        AssetTypes: []
      };
    }
  }
);

export default getNotificationsModelOutputSelector;

// #region Functions

function createModelOutput(
  form: Form,
  locale: string
): INotificationsModelOutput {
  return {
    DegradedToRed: form.getIn([
      "inputs",
      NotificationsFormFields.degradesRed,
      "value"
    ]) as boolean,
    DegradedToYellow: form.getIn([
      "inputs",
      NotificationsFormFields.degradesYellow,
      "value"
    ]) as boolean,
    ImprovedToGreen: form.getIn([
      "inputs",
      NotificationsFormFields.improvesGreen,
      "value"
    ]) as boolean,
    ImprovedToYellow: form.getIn([
      "inputs",
      NotificationsFormFields.improvesYellow,
      "value"
    ]) as boolean,
    Organizations: NotificationsService.createOrganizationsModelOutput(
      form.getIn([
        "inputs",
        NotificationsFormFields.organizations,
        "value"
      ]) as string[]
    ),
    AssetTypes: form.getIn([
      "inputs",
      NotificationsFormFields.assetTypes,
      "value"
    ]) as string[],
    Locale: locale
  };
}

// #endregion
